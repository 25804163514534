<template>
  <!-- 积分文案弹窗 -->
  <s-popover 
    placemen="bottom-end" 
    trigger="hover"
    :prop-style="{width: '266px', boxShadow: '0px 2px 10px 2px rgba(0,0,0,0.1)', padding: '10px', lineHeight: '18px'}"
  >
    <div v-html="pointsTip"></div>
    <template v-if="pointsTotal && caculateInfo.prime_points">
      <br />
      <div
        class="points-reward__pop-total"
        v-html="pointsTotal"
      ></div>
    </template>
    <template #reference>
      <Icon
        name="sui_icon_doubt_16px_1"
        size="16px"
        color="#959595"
        class="tips-icon"
      />
    </template>
  </s-popover>
</template>
<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { template } from '@shein/common-function'

const store = useStore()

const language = computed(() => store.state.language)
const caculateInfo = computed(() => store.state.checkout.mall_caculate_info)

/* 积分提示文案1 */
const pointsTip = computed(() => {
  return `${language.value.SHEIN_KEY_PC_15761}${language.value.SHEIN_KEY_PC_22344}.`
})

/* 积分提示文案2 */
const pointsTotal = computed(() => {
  if (!caculateInfo.value?.point_grant_amount_info?.cycle_grant_amount) return ''
  const info = caculateInfo.value?.point_grant_amount_info
  const oldParams = [info?.cycle_used_grant_amount, info?.cycle_grant_amount]
  const maxPoints = info?.single_order_reward_points_limit || info?.cycle_grant_amount
  const result = template(...oldParams, maxPoints, caculateInfo.value?.prime_points, language.value.SHEIN_KEY_PC_31537)
  return result
})
</script>
<style lang="less" scoped>
.tips-icon{
  cursor: pointer;
}
</style>
