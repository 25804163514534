<template>
  <s-dialog
    v-model:visible="visible"
    :append-to-body="true"
    :show-close="true"
    :close-on-click-modal="true"
    type="W720"
    @close-from-icon="closeEvent"
    @close-from-mask="closeEvent"
  >
    <autoDwHeader
      :one-order-text="oneOrderText"
      :normal-show="normalShow"
      :discount-price="discountPrice"
      :auto-type="autoType"
    />
      
    <div :class="['dw-container', { 'noNormalMargin': !normalShow }]">
      <tipsContent
        v-if="['xtra', 'common'].includes(autoType)"
        :payment-info="paymentInfo"
        :current-product="currentProduct"
        :auto-type="autoType"
      />
      <autoDwContent
        :current-product="currentProduct"
        :current-price-discount="currentPriceDiscount"
        :current-product-cycle-days="currentProductCycleDays"
        :current-days-price-discount="currentDaysPriceDiscount"
        :auto-type="autoType"
      />
      <tipsContent
        v-if="autoType=='prime'"
        :payment-info="paymentInfo"
        :current-product="currentProduct"
        :auto-type="autoType"
      />
      <s-button
        :type="['primary', 'H72PX']"
        width="100%"
        class="btn"
        @click="confirm"
      >
        <span v-if="!lockLoading">{{ autoType == 'prime' ? language.SHEIN_KEY_PC_29652 : language.SHEIN_KEY_PC_31090 }}</span>
        <div
          v-else
          class="la-ball-pulse la-ball-pulse-white"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </s-button>
      <p
        class="dontSelect"
        @click="emitChange('autoSubmit', { isdont: true })"
      >
        {{ autoType == 'prime' ? language.SHEIN_KEY_PC_29818 : language.SHEIN_KEY_PC_31091 }}
      </p>
    </div>
  </s-dialog>
</template>
<script>
import { SDialog } from '@shein-aidc/sui-dialog'
import { reactive, toRefs, computed, provide } from 'vue'
import { useStore } from 'vuex'
import { template as templateText } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import autoDwHeader from './autoDwHeader.vue'
import autoDwContent from './autoDwContent'
import tipsContent from './tipsContent.vue'
  
daEventCenter.addSubscriber({ modulecode: '1-11-1' })
  
export default {
  components: { SDialog, autoDwHeader, autoDwContent, tipsContent },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    autoType: {
      type: String,
      default: 'prime'
    }
  },
  setup(props, { emit }) {

    const store = useStore()
    provide('autoDwFatherProp', { language: props.language }) // 使用 provide 注入数据给子组件
  
    const state = reactive({
      visible: false,
      normalShow: true, // 判断是否普通单还是一单回本
      currentProduct: {},
      currentProductCycleDays: {},
      paymentInfo: {},
      discountPrice: {},
      lockLoading: false,
    })

    const clubScribePriceByAbt = computed(() => {
      return store.getters?.clubScribePriceByAbt
    })
    const lastSelectePayment = computed(() => {
      return store.getters?.lastSelectePayment
    })
  
    const currentPriceDiscount = computed(() => {
      return +state?.currentProduct?.product_price_info?.price_local !== +state?.currentProduct?.product_price_info?.special_price && !clubScribePriceByAbt.value
    })
    const currentDaysPriceDiscount = computed(() => {
      return +state?.currentProductCycleDays?.product_price_info?.price_local !== +state?.currentProductCycleDays?.product_price_info?.special_price && !clubScribePriceByAbt.value
    })
    const oneOrderText = computed(() => {
      return templateText('<span class="color1" style="color: #FF5656 !important;font-size: 32px;margin: 0 3px">1</span>', props.language.SHEIN_KEY_PC_29810)
    })
    const emitChange = (type, { isdont } = { isdont: false }) => {
      if(isdont){
        daEventCenter.triggerNotice({
          daId: '1-11-1-194',
          extraData: {
            click_type: 'notselected',
            autorenew_switch_scene: state?.normalShow ? 0 : 1,
            renew_type: props.autoType == 'prime' ? '2' : '1'
          }
        })
      }
      const map = {
        autoSubmit: () => {
          if(state.lockLoading) return
          state.visible = false
          emit('autoSubmit', { productCode: state?.currentProductCycleDays?.product_code, isdont })
        }
      }
      return map[type]()
    }
  
    const visibleEvent = (val) => {
      state.lockLoading = false
      state.currentProduct = val?.currentProduct || state.currentProduct || {}
      state.currentProductCycleDays = val?.currentProductCycleDays || state.currentProductCycleDays || {},
      state.paymentInfo = val?.paymentInfo || state.paymentInfo || {}
      state.discountPrice = val?.discountPrice
      state.normalShow = val?.normalShow
      state.visible = val.visible
  
      daEventCenter.triggerNotice({
        daId: '1-11-1-193',
        extraData: {
          autorenew_switch_scene: state?.normalShow ? 0 : 1,
          renew_type: props.autoType == 'prime' ? '2' : '1'
        }
      })
    }
  
    const confirm = () => {
      daEventCenter.triggerNotice({
        daId: '1-11-1-194',
        extraData: {
          click_type: 'confirm',
          autorenew_switch_scene: state?.normalShow ? 0 : 1,
          renew_type: props.autoType == 'prime' ? '2' : '1'
        }
      })
      emitChange('autoSubmit')
    }
  
    const closeEvent = () => {
      daEventCenter.triggerNotice({
        daId: '1-11-1-194',
        extraData: {
          click_type: 'close',
          autorenew_switch_scene: state?.normalShow ? 0 : 1,
          renew_type: props.autoType == 'prime' ? '2' : '1'
        }
      })
      if(!state.normalShow && !state.lockLoading) { 
        emitChange('autoSubmit') 
      } else { 
        // 无一单回本
        state.lockLoading = false
        state.visible = false
        let { id, code } = lastSelectePayment?.value || {}
        window.checkoutEventCenter.emit('click-payment', {
          id,
          code,
          selectItem: lastSelectePayment?.value,
          isDoNeedMallCalculateTotal: true
        })
      }
    }
  
    return {
      ...toRefs(state),
  
      currentPriceDiscount,
      currentDaysPriceDiscount,
      oneOrderText,
      lastSelectePayment,
      emitChange,
      visibleEvent,
      templateText,
      closeEvent,
      confirm
    }
  },
  emits: ['autoSubmit']
}
</script>
<style lang="less">

.payBack-container{
  display: flex;
  justify-content: center;
  align-items: center;

}

.container-color{
  display: flex;
  align-items: center;
  .color1{
    color: #FF5656 !important;
    font-size: 32px;
    margin: 0 3px;
  }
}

.paymentContainer{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  img{
    margin-right: 4px;
    width: 27px;
  }
}
.opacityStyle{
  opacity: 0.4;
  border: 1px solid #c96e3f !important; /* stylelint-disable-line declaration-no-important */
  background-image: none !important; /* stylelint-disable-line declaration-no-important */
}
.auto-label{
  background: linear-gradient(90deg, #F17E53 5.49%, #FF5656 81.87%);
  position: absolute;
  left: -1px;
  top: -10px;
  border-radius: 6px 0;
  color: #FFF;
  font-size: 12px;
  font-weight: 590;
  text-transform: capitalize;
  padding: 3px;
}
.auto__dw-containerHer{
  background: linear-gradient(279deg,#ffdcb2 12.71%,#fff4d7 32.38%,#ffecd6 52.47%,#ffe1be 100.42%,#ffe7cb);
  height: 75px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  .bg-img{
    position: absolute;
    left: 0;
    top: 16px; 
    left: -11px;
  }
}
.headerHeight{
  height: 113px;
}
.noNormalMargin{
  margin-top: 120px !important;
}
.dw-header{
    color: #000;
    position: relative;
    font-size: 18px;
    font-style: normal;
    font-weight: 860;
    line-height: normal;
    // text-transform: uppercase;
    .normal{
      margin-top: 30px;
      text-align: center;
    }
    .one-bank{
      min-height: 96px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #000;
      text-align: center;
      font-size: 18px;
      font-weight: 860;
      // text-transform: uppercase;
      margin-top: 30px;
    }
    .right-one{
      margin-top: 0;
    }
  }
.dw-container{
  background: #ffffff;
  margin-top: 65px;
  position: relative;
  z-index: 1;
    .derection{
      display: flex !important; /* stylelint-disable-line declaration-no-important */
      align-items: center;
      height: 24px;
      width: 24px;
      align-self: center;
    }
    .tips-container{
      position: relative;
      margin-top: 3px;
    }
    .btn{
      margin: 15px 0;
    }
    .dontSelect{
      text-align: center;
      color: #2D68A8;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
}
.product-container{
  min-height: 117px;
  display: flex;
  justify-content: space-between;
  .item-containerPro{
    .product-item{
      // overflow: hidden;
      background-image: linear-gradient(90deg,#fff,#fff),linear-gradient(90deg,#c96e3f,#c96e3f);
      border: 2px solid transparent;
      background-clip: padding-box,border-box;
      border-radius: 3px;
      flex-shrink: 0;
      width: 292px;
      position: relative;
      .item-header{
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFC3A5;
        color: #873C00;
        font-size: 12px;
        font-weight: 590;
        border-radius: 3px 3px 0 0;
      }
      .content{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 57px;
        font-size: 16px;
        font-weight: 700;
        color: #C96E3F;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        border-radius: 0 0 3px 3px;
      }

      .del{
          color: #959595;
          font-size: 14px;
          font-weight: 400;
        }
    }
    .tips{
      color: #BBB;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      margin-top: 6px;
    }
    .select{
      color: #873C00;
    }
  }
}
.select-contentPro{
  background: url('/she_dist/images/checkout/primce_rectangle-e1fac4a531.png') no-repeat;
  background-size: 100% auto;
  min-height: 72px; 
  position: relative;  
  margin-top: 12px;   
}
.pay-container{
  padding: 10px 15px;
  border-left: 3px solid #008900;
  padding: 10px 15px;
  position: absolute;
  bottom: 0;
  height: 88%;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .warn-tips{
    display: flex;
    color: #BF4123;
    align-items: center;
    font-size: 14px;
    .warn-icon{
      margin-right: 3px;
    }
  }
}  
.dw-headerTips{
    position: relative;
    // margin-top: 5px;
    background: url('/she_dist/images/checkout/primce_rectangle_union-0bf97dcb14.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    // background-size: 100%;
    background-size: 100% 100%;
    .triangle{
      width: 0;
      height: 0;
      border-left: 8px solid transparent; 
      border-right: 8px solid transparent; 
      border-bottom: 9px solid #DA7E50;
      position: absolute;
      top: -8px;
      right: 45%;
    }
    .triangle-content{
      // border-radius: 50px;
      // background: #DA7E50;
      // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      color: #FFF;
      text-align: center;
      font-size: 12px;
      font-weight: 510;
      position: relative;
      bottom: 0;
      min-width: 170px;
      min-height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 8px 0 8px;
    }
  }
</style>

