<template>
  <div
    class="col-sm-4 c-check c-check-right j-check-right"
    :class="!IS_RW && 'checkout__layout_right'"
  >
    <div 
      v-cloak 
      v-if="caculateInfo" 
      class="c-order-summary"
    >
      <!-- 价格明细 -->
      <OrderSummary
        :is-discount-total-color="isDiscountTotalColor"
        :pre-pay-un-pay-params="prePayUnPayParams"
      />

      <!-- 积分 -->
      <pointsContainer v-if="caculateInfo.totalPointMultiple && caculateInfo.totalPoints" />

      <template v-if="isChangePrimeSaverLocation">
        <!-- 付费会员 -->
        <prime-options-wrap
          :paypal-ga-vault="paypalGaVault"
          :banks-config="banksConfig"
          :payment-methods-props="paymentMethodsProps"
        />
        <!-- 超省卡 -->
        <xtraOptions
          class="chekout-summary-xtra"
          :paypal-ga-vault="paypalGaVault"
          :banks-config="banksConfig"
          :payment-methods-props="paymentMethodsProps"
        />
      </template>
      <template v-else>
        <!-- 超省卡 -->
        <xtraOptions
          class="chekout-summary-xtra"
          :paypal-ga-vault="paypalGaVault"
          :banks-config="banksConfig"
          :payment-methods-props="paymentMethodsProps"
        />
        <!-- 付费会员 -->
        <prime-options-wrap
          :paypal-ga-vault="paypalGaVault"
          :banks-config="banksConfig"
          :payment-methods-props="paymentMethodsProps"
        />
      </template>

      <ClientOnly>
        <!-- 买券 -->
        <CheckoutBuyCoupon v-if="IS_RW" />
      </ClientOnly>
      
      <AssetsListEffiency
        :giftcard="giftcard"
        :is-all-store="isAllStore"
        :currency-code="currencyCode"
      />

      <!-- 违禁品提示，比如烟草类商品 -->
      <ContrabandTips v-if="isShowContrabandTips" />

      <!-- 营销裂变 -->
      <div
        v-if="extraPromotionTip && !isShowContrabandTips"
        class="chekout-summary-fission"
      >
        <s-alert
          class="marking-fission-content"
          type="warning"
        >
          <div v-html="extraPromotionTip"></div>
        </s-alert>
      </div>

      <!-- 下单按钮 & 支付方式logo -->
      <CheckoutPlaceOrder
        :paypal-btn-show="paypalBtnShow"
        :paypal-venmo-btn-show="paypalVenmoBtnShow"
        :paypal-ga-vault="paypalGaVault"
        :installment-info="installmentInfo"
        @create-order="createOrder"
      />
      
      <!-- 全场订单返券包 -->
      <return-coupon 
        :language="language" 
        scene="multiCoupon" 
      />

      <PrimeBenefit
        v-if="isShowPrimeBenefit"
        v-expose="{
          id: '1-11-1-148',
          data: {
            next_roi: caculateInfo.next_roi,
            roi: caculateInfo.roi,
          },
        }"
        :language="language"
        :discount-info="caculateInfo.prime_total_price"
        :roi-info="caculateInfo.next_roi"
      />

      <!-- 全场订单返券包 -->
      <return-coupon 
        :language="language" 
        scene="singleCoupon" 
      />

      <div class="checkout-summary-top">
        <SupportInfoModule
          v-expose="{ id: '1-11-1-202' }"
          class="checkout-summary-support"
          scene="Checkout"
          :language="language"
          :support-abt-info="supportAbtInfo"
          :locals="locals"
          :security-logo-list="securityLogoList"
        />
      </div>
    </div>

    <ClientOnly>
      <bin-discount-dialog 
        :language="language" 
        @create-order="createOrder"
      />
    </ClientOnly>
  </div>
</template>

<script>
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'
import { mapState, mapGetters, mapMutations, useStore } from 'vuex'

import CheckoutPlaceOrder from 'public/src/pages/checkout/components/summary/checkoutBtn/CheckoutPlaceOrder.vue'
import CheckoutBuyCoupon from '../components/coupon/CheckoutBuyCoupon.vue'
import PrimeOptionsWrap from 'public/src/pages/checkout/components/summary/prime_options_wrap.vue'
import ReturnCoupon from 'public/src/pages/checkout/components/summary/returnCoupon.vue'
import AssetsListEffiency from './assets_list_effiency.vue'
import OrderSummary from '@/public/src/pages/checkout/components/summary/OrderSummary.vue'
import SupportInfoModule from 'public/src/pages/components/SupportInfoModule.vue'
import PrimeBenefit from 'public/src/pages/checkout/components/summary/prime_benefit.vue'
import xtraOptions from 'public/src/pages/checkout/components/xtra_options/index.vue'
import ContrabandTips from 'public/src/pages/checkout/components/summary/ContrabandTips.vue'
import BinDiscountDialog from './token_pay/bin_discount_dialog.vue'
import pointsContainer from 'public/src/pages/checkout/vue_tpls/points_task/points_container.vue'

import useSupportInfo from 'public/src/pages/checkout/hooks/useSupportInfo.js'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })


export default {
  directives: { tap, expose },
  setup() {
    const store = useStore()
    const { isShowSupportInfo } = useSupportInfo(
      store.state?.checkout?.checkoutBFFAbtInfo
    )
    return { isShowSupportInfo }
  },
  components: {
    CheckoutBuyCoupon,
    CheckoutPlaceOrder,
    ReturnCoupon,
    PrimeOptionsWrap,
    AssetsListEffiency,
    OrderSummary,
    SupportInfoModule,
    PrimeBenefit,
    xtraOptions,
    ContrabandTips,
    BinDiscountDialog,
    pointsContainer
  },
  props: {
    extraPromotionTip: {
      type: [Object, String],
      default: () => {
        return ''
      },
    },
    giftcard: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isAllStore: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    paypalBtnShow: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    paypalVenmoBtnShow: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    currencyCode: {
      type: String,
      default: () => {
        return ''
      },
    },
    isDiscountTotalColor: {
      type: Boolean,
      default: false,
    },
    paypalGaVault: {
      type: Object,
      default: () => {},
    },
    afterpayVault: {
      type: Object,
      default: () => {},
    },
    banksConfig: {
      type: Object,
      default: () => {},
    },
    paymentOptionsLanguage: {
      type: Object,
      default: () => {},
    },
    status: {
      type: Object,
      default: () => {},
    },
    paymentList: {
      type: Object,
      default: function () {
        return {}
      },
    },
    paymentOption: {
      type: Object,
      default: function () {
        return {}
      },
    },
    installmentInfo: {
      type: Object,
      default: function () {
        return {}
      },
    },
    ppgvAccountInfo: Object,
    afterpayAccountInfo: Object,
    paymentDescriptionAbt: {
      type: Boolean,
      default: false,
    },
    googleValid: {
      type: Boolean,
      default: false,
    },
    shake: {
      type: Boolean,
      default: false,
    },
    showWidgetForm: {
      type: Number,
      default: 0,
    },
    needPayInlinePaymentMethods: {
      type: Array,
      default: function () {
        return []
      },
    },
    handleStartSelectBank: {
      type: Function,
      default: function () {
        return () => {}
      },
    },
    showPaypalGaType: {
      type: Function,
      default: function () {
        return () => {}
      },
    },
    openDelayChangeBanks: {
      type: Function,
      default: function () {
        return () => {}
      },
    },
    closeDelayChangeBanks: {
      type: Function,
      default: function () {
        return () => {}
      },
    },
    unfold: {
      type: Function,
      default: function () {
        return () => {}
      },
    },
    setDelayChangeBanksData: {
      type: Function,
      default: function () {
        return () => {}
      },
    },
    updateBanksConfig: {
      type: Function,
      default: function () {
        return () => {}
      },
    },
    delayChangeBanksData: {
      type: Object,
      default: function () {
        return {}
      },
    },
    payInlineSessionInfo: {
      type: Object,
      default: function () {
        return {}
      },
    },
    klarna: {
      type: Function,
      default: function () {
        return () => {}
      },
    },
    prePayUnPayParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters(['isSelectInstallmentTokenPay']),
    ...mapState([
      'primeProductList',
      'defaultShipping',
      'checkout',
      'language',
      'locals'
    ]),
    IS_RW() {
      return this.locals.IS_RW
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    securityLogoList() {
      return this.checkout.results?.securityLogoInfo?.info?.logoList || []
    },
    supportAbtInfo(){
      const { PaymentSecurity } = this.checkout.checkoutBFFAbtInfo || {}
      return {
        PaymentSecurity: PaymentSecurity?.p?.PaymentSecurity || ''
      }
    },
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    isChangePrimeSaverLocation() {
      return this.checkout?.checkoutBFFAbtInfo?.LocationPrimeSaver?.param?.LocationPrimeSaverStatus == '1'
    },
    isShowPrimeBenefit() {
      const isShowByAbt =
        this.checkout.checkoutBFFAbtInfo?.Checkoutmemberbenefit?.param
          ?.checkout_member_benefit_show == 'on'
          ? true
          : false
      const isShow = +this.caculateInfo?.next_roi > +this.caculateInfo?.roi

      return isShowByAbt && isShow
    },
    // 是否展示购买违禁品的提示，比如：烟草，计价的接口返回标识是1
    isShowContrabandTips() {
      return (this.caculateInfo?.cart_sub_infos || []).findIndex(item => +item.tobacco_goods === 1) > -1
    },
    paymentMethodsProps() {
      return {
        language: this.paymentOptionsLanguage,
        status: this.status,
        checkout: this.checkout,
        banksConfig: this.banksConfig,
        paymentOption: this.paymentOption,
        installmentInfo: this.installmentInfo,
        paymentDescriptionAbt: this.paymentDescriptionAbt,
        googleValid: this.googleValid,
        shake: this.shake,
        needPayInlinePaymentMethods: this.needPayInlinePaymentMethods,
        showWidgetForm: this.showWidgetForm,
        ppgvAccountInfo: this.ppgvAccountInfo,
        afterpayAccountInfo: this.afterpayAccountInfo,
        paypalGaVault: this.paypalGaVault,
        afterpayVault: this.afterpayVault,
        handleStartSelectBank: this.handleStartSelectBank,
        paymentList: this.paymentList,
        showPaypalGaType: data => this.$emit('show-paypalga-type', data, true),
        togglePPGVType: data => this.$emit('toggle-ppgv-type', data),
        toggleAfterpayType: data => this.$emit('toggle-afterpay-type', data),
        unfold: this.unfold,
        openDelayChangeBanks: this.openDelayChangeBanks,
        closeDelayChangeBanks: this.closeDelayChangeBanks,
        setDelayChangeBanksData: this.setDelayChangeBanksData,
        updateBanksConfig: this.updateBanksConfig,
        delayChangeBanksData: this.delayChangeBanksData,
        payInlineSessionInfo: this.payInlineSessionInfo,
        klarna: this.klarna,
      }
    }

  },

  methods: {
    ...mapMutations(['assignState']),
    template,
    // 注意！！！此处不要有任何return事件，统一收口至最尾节点处理！！！下单按钮点击事件需要上报埋点及监控！！！
    createOrder(opt = {}) {
      this.$emit('create-order', opt)
    },
  },
  emits: ['create-order'],
}
</script>

<style lang="less">
.checkout__layout_right {
  max-width: 450px;
  min-width: 320px;
}

.check-coupon {
  .indicates {
    padding: 5px 0;
    padding-top: 0;
    .assets-icon-tip {
      i {
        color: @sui_color_gray_dark3;
        .margin-l(5px);
      }
    }
    .assets-item-title {
      color: #222;
      font-weight: bold;
      text-decoration: none;
      font-size: 14px;
      > i {
        font-size: 12px;
        color: @sui_color_gray_dark3;
        padding: 0 0 0 5px;
        font-weight: normal;
      }
    }
    > em {
      position: absolute;
      top: 22px;
      .left(0);
    }
    .only-for-self {
      padding: 2px 10px;
      font-size: 12px;
      background: #f6f6f6;
      display: inline-block;
      color: #3a3a3a;
      font-weight: normal;
    }
  }
}

.place-order__effiency {
  .c-order-summary {
    .checkout-prime__wrap {
      margin-bottom: 10px;
    }

    .checkout-prime__wrap-body {
      padding: 12px;
      background: #fff;
    }

    .chekout-summary-fission {
      padding: 16px;
      background: #fff;
    }

    .marking-fission-content {
      padding: 8px;
      background: #fff1d5;
    }

    .sui-radio__check {
      margin-right: 10px;
    }

    .c-checkout-buy-coupon {
      margin: 0 0 10px 0;

      .buycoupon-tips {
        margin-top: 0;
      }
    }

    .return-coupon__title {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    .coupon-item-wrapper {
      margin-bottom: 0;
    }

    .buy-coupon-wrap {
      padding: 16px 12px;
      background: #fff;

      .buy-coupon-title {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 8px;
      }

      .title {
        margin-bottom: 4px;
      }

      .view-coupons {
        margin-top: 8px;
      }

      .sui-radio__icon {
        align-self: flex-start;
      }
    }

    .module-item-wrap {
      padding-bottom: 0;
    }

    .support-info-module {
      margin-top: 0;
    }

    .module-item {
      padding-bottom: 0;

      &:first-of-type {
        padding-top: 0;
      }
    }

    .secure-pay-logo {
      margin-top: 0;
    }

    .assets-item-title {
      font-size: 16px;
    }
  }

  .checkout-summary-top {
    margin-top: 10px;
    background: #f4f4f4;
  }
  .checkout-summary-support,
  .checkout-summary-secure {
    background: #fff;
    padding: 16px;
  }

  .checkout-return-coupon__wrap {
    background: #fff;
    padding: 16px;
    margin-top: 10px;
  }

  .chekout-summary-xtra {
    margin-bottom: 10px;
  }

  .checkout-summary-footer {
    background: #fff;
  }
  .c-check-right {
		.padding-l(10px);
		border: 0;
	}
}

.tesssss{
  // background: linear-gradient(305deg, #FFF4E5 4.25%, #FFECD4 99.14%);
  background: #ffffff;
  padding: 12px;
}
</style>
