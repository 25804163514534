<template>
  <div class="checkout-body">
    <page-header
      container1350
      :customs-click-env="true"
      :locals="locals"
      :page-header-language="language"
      @click-logo="clickHeaderLogo"
      @click-shopping="clickHeaderShopping"
    />

    <div class="checkout-container place-order__effiency">
      <nav-bar 
        :links="links" 
        :active="active" 
        :can-click-inx-list="[0]"
        :is-buy-now="!!checkout.checkout_no"
        @on-click="clickNavBar"
      >
        <template #notice>
          <more-alert-com
            :checkout="checkout"
            :language="language"
          />
        </template>
      </nav-bar>

      <div 
        class="container-fluid-1200 m-place-order"
        :class="{'container-1350': WEB_CLIENT == 'shein'}"
      >
        <div 
          class="row" 
          :class="{'checkout__layout': WEB_CLIENT == 'shein'}"
        >
          <div 
            v-expose="{
              id: '1-11-1-114',
              data: viewShippingAnalysis
            }"
            class="col-sm-8 c-check c-check-left c-place-order"
            :class="{'checkout__layout_left': WEB_CLIENT == 'shein'}"
          >
            <!-- 单mall 地址&运输方式 左右分布 -->
            <template v-if="!IS_RW && newAddressShipByAbt">
              <div class="checkout-address-shipping__wrap">
                <div 
                  class="is-left" 
                  :style="{width: checkout.default_address.addressId ? '50%' : '100%' }"
                >
                  <!-- 无地址，或者当前是宅配地址 -->
                  <div v-show="!status.hasAddress || (status.hasAddress &&!isShopTransit)">
                    <!-- 首次添加地址 -->
                    <div 
                      v-show="!status.hasAddress"
                      class="check-item check-first-address j-first-address"
                    >
                      <address-vue 
                        ref="firstAddressVue" 
                        :page-name="'firstAddress'" 
                        :area-code-flag="true" 
                        @add="firstAddressAdd" 
                        @error="firstUpdateError" 
                        @vue:mounted="initAdress"
                      /> 
                    </div>
                    <!-- 左右分布时需要与运输方式一起展示出来 -->
                    <div v-show="status.hasAddress && (mallShippingMethods.mall_list && !!mallShippingMethods.mall_list.length)">
                      <ShippingAddress 
                        :second-language="secondLanguage"
                        :source-gray="sourceGray"
                        :kr-copywrite-tip="krCopywriteTip"
                        :adr-name-verified-title-tip="adrNameVerifiedTitleTip"
                      />
                    </div>
                  </div>
                  <!-- 当前是店配地址 -->
                  <div v-show="(!IS_RW && SHOP_TRANSIT.site.includes(SiteUID)) && isShopTransit">
                    <StoreAddress 
                      :adr-name-verified-title-tip="adrNameVerifiedTitleTip"
                    />
                  </div>
                </div>
      
                <!-- shipping options -->
                <shipping-options 
                  v-if="mallShippingMethods.mall_list && mallShippingMethods.mall_list.length"
                  class="is-right"
                  :mall-code="mallShippingMethods.mall_list && mallShippingMethods.mall_list[0] && mallShippingMethods.mall_list[0].mall_code || '1'"
                  :shipping-methods="mallShippingMethods.mall_list && mallShippingMethods.mall_list[0] && mallShippingMethods.mall_list[0].shipping_methods || []"
                  :carts-data="cartsData"
                  :quick-ship-info="quickShipInfo"
                  @click-shipping="clickShipping"
                  @click-insurance="clickInsurance"
                />
              </div>

              <checkout-cart 
                v-if="shoppingBagPosition" 
                class="is-single"
                :carts-info="cartsData" 
                :carts-mall-info="cartsMallInfo" 
                :quick-ship-info="quickShipInfo"
                :mall-code="mallShippingMethods.mall_list && mallShippingMethods.mall_list[0] && mallShippingMethods.mall_list[0].mall_code || '1'"
                @click-insurance="clickInsurance"
              />
            </template>
            <template v-else>
              <!-- 无地址，或者当前是宅配地址 -->
              <div v-show="!status.hasAddress || (status.hasAddress &&!isShopTransit)">
                <!-- 首次添加地址 -->
                <div 
                  v-show="!status.hasAddress"
                  class="check-item check-first-address j-first-address"
                >
                  <address-vue 
                    ref="firstAddressVue" 
                    :page-name="'firstAddress'" 
                    :area-code-flag="true" 
                    @add="firstAddressAdd" 
                    @error="firstUpdateError" 
                    @vue:mounted="initAdress"
                  /> 
                </div>
                <div v-show="status.hasAddress">
                  <ShippingAddress 
                    :second-language="secondLanguage"
                    :source-gray="sourceGray"
                    :kr-copywrite-tip="krCopywriteTip"
                    :adr-name-verified-title-tip="adrNameVerifiedTitleTip"
                  />
                </div>
              </div>
              <!-- 当前是店配地址 -->
              <div v-show="(!IS_RW && SHOP_TRANSIT.site.includes(SiteUID)) && isShopTransit">
                <StoreAddress 
                  :adr-name-verified-title-tip="adrNameVerifiedTitleTip"
                />
              </div>

              <!-- shipping options -->
              <!-- 单mall新样式及多mall样式 -->
              <template v-if="shoppingBagPosition && (mallCartsList && mallCartsList.length)">
                <template 
                  v-for="mall in mallCartsList" 
                  :key="mall.mall_code"
                >
                  <shopping-mall 
                    :mall-code="mall[0].mall_code"
                    :carts-data="mall"
                    @click-shipping="clickShipping"
                    @click-insurance="clickInsurance"
                  />
                </template>
              </template>

              <!-- 单mall旧样式 -->
              <shipping-options 
                v-if="!shoppingBagPosition && (mallShippingMethods.mall_list && mallShippingMethods.mall_list.length)"
                class="is-single"
                :mall-code="mallShippingMethods.mall_list && mallShippingMethods.mall_list[0] && mallShippingMethods.mall_list[0].mall_code || '1'"
                :shipping-methods="mallShippingMethods.mall_list && mallShippingMethods.mall_list[0] && mallShippingMethods.mall_list[0].shipping_methods || []"
                :carts-data="cartsData"
                :quick-ship-info="quickShipInfo"
                @click-shipping="clickShipping"
                @click-insurance="clickInsurance"
              />
            </template>

            <!-- 随手购模块，位于支付方式的上方 -->
            <together-buy 
              v-if="showTogetherBuy" 
              class="not-fsp-element" 
            />
            <payment-options
              ref="paymentOptions"
              page-name="checkout"
              :language="language"
              :status="status"
              :checkout="checkout"
              :payment-list="paymentList"
              :banks-config="banksConfig"
              :installment-info="installmentInfo"
              :payment-description-abt="paymentDescriptionAbt"
              :google-valid="googleValid"
              :shake="shake"
              :need-pay-inline-payment-methods="needPayInlinePaymentMethods"
              :show-widget-form="showWidgetForm"
              :ppgv-account-info="ppgvAccountInfo"
              :afterpay-account-info="afterpayAccountInfo"
              :paypal-ga-vault="paypalGaVault"
              :afterpay-vault="afterpayVault"
              :handle-start-select-bank="handleStartSelectBank"
              :payment-display-func="paymentDisplay"
              :payment-des-func="paymentDes"
              :pay-limit-tip-show-func="payLimitTipShow"
              :locals="locals"
              :coupon="coupon"
              :coupon-bin-list="couponBinList"
              @show-paypalga-type="showPaypalGaType"
              @toggle-ppgv-type="togglePPGVType"
              @toggle-afterpay-type="toggleAfterpayType"
              @unfold="unfold"
              @select-payment="clickPayment"
              @assign-state="assignState"
            />
            <checkout-cart 
              v-if="!shoppingBagPosition" 
              class="is-single"
              :carts-info="cartsData" 
              :carts-mall-info="cartsMallInfo" 
              :quick-ship-info="quickShipInfo"
              :mall-code="mallShippingMethods.mall_list && mallShippingMethods.mall_list[0] && mallShippingMethods.mall_list[0].mall_code || '1'"
              @click-insurance="clickInsurance"
            />
          </div>

          <checkout-summary 
            ref="checkoutSummary"
            :extra-promotion-tip="extraPromotionTip"
            :giftcard="giftcard"
            :is-all-store="isAllStore"
            :currency-code="currencyCode"
            :banks-config="banksConfig"
            :paypal-btn-show="paypalBtnShow"
            :paypal-venmo-btn-show="paypalVenmoBtnShow"
            :mall-carts-list="mallCartsList"
            :is-discount-total-color="isDiscountTotalColor"
            :paypal-ga-vault="paypalGaVault"
            :afterpay-vault="afterpayVault"
            :payment-options-language="language"
            :status="status"
            :payment-list="paymentList"
            :installment-info="installmentInfo"
            :payment-description-abt="paymentDescriptionAbt"
            :google-valid="googleValid"
            :shake="shake"
            :need-pay-inline-payment-methods="needPayInlinePaymentMethods"
            :show-widget-form="showWidgetForm"
            :ppgv-account-info="ppgvAccountInfo"
            :afterpay-account-info="afterpayAccountInfo"
            :handle-start-select-bank="handleStartSelectBank"
            :unfold="unfold"
            :open-delay-change-banks="openDelayChangeBanks"
            :close-delay-change-banks="closeDelayChangeBanks"
            :set-delay-change-banks-data="setDelayChangeBanksData"
            :update-banks-config="updateBanksConfig"
            :delay-change-banks-data="delayChangeBanksData"
            :pay-inline-session-info="payInlineSessionInfo"
            :klarna="klarna"
            :pre-pay-un-pay-params="prePayUnPayParams"
            @show-paypalga-type="showPaypalGaType"
            @toggle-ppgv-type="togglePPGVType"
            @toggle-afterpay-type="toggleAfterpayType"
            @click-apply="clickApply"
            @apply-giftcard="applyGiftcard"
            @cancel-gift-card="cancelGiftCard"
            @create-order="createOrder"
          />
        </div>
      </div>
    </div>

    <ClientOnly>
      <AddressCommon
        ref="addressCom"
        :modal-tips-show="modalTipsShow"
        @address-add="addressAdd"
        @address-edit="addressEdit"
        @address-supplement-edit="addressSupplementEdit"
        @save-store-address="saveStoreAddress"
        @cancel-store-address="cancelStoreAddress"
        @address-store-supplement-edit="addressStoreSupplementEdit"
        @confirm-del-address="confirmDel"
        @click-modal-tips-ok="clickModalTipsOk"
        @click-modal-tips-cancel="clickModalTipsCancel"
      />
      <choose-address-dialog
        v-if="showAddressDialog"
        :language="language"
        :checkout="checkout"
        :source-gray="sourceGray"
        @click-add-address="clickAddAddress"
        @click-select-address="clickSelectAddress"
        @click-edit-address="clickEditAddress"
        @click-delete-address="handleDelete"
      />
      <CheckoutDialog
        v-if="isClient"
        ref="checkoutDialog"
        :mix-privacy="mixPrivacy"
        :blik-code="blikCode"
        :bank-drawer-config="bankDrawerConfig"
        :capitec-dialog="capitecDialog"
        @login-modal-suc-cb="loginModalSucCb"
        @change="changeQiwiPayPhone"
        @createorder="createOrderFn"
        @cod-confirm-use="confirmUse"
        @cod-confirm-cancel="confirmCancel"
        @limit-go-back="limitGoBack"
        @handle-mix-agreen="handleMixAgreen"
        @handle-mix-close="handleMixClose"
        @hide-blik-code-com="hideBlikCodeCom"
        @submit-blik-code-com="submitBlikCodeCom"
        @success-blik-code-com="successBlikCodeCom"
        @handle-bank-click-in-vm="handleBankClickInVm"
        @handle-bank-select-in-vm="handleBankSelectInVm"
        @cancel-change-shipping-type="cancelChangeShippingType"
        @change-shipping-type="changeShippingType"
        @handle-capitec-dialog-event-in-vm="handleCapitecDialogEventInVm"
      />
      <sold-out-vue
        v-if="soldOutDialog.loaded"
        :has-store-goods="hasStoreGoods"
        :checkout="checkout"
        :show-type="soldoutType"
        :limitlist="productLimitCarts"
        :new-out-stock-carts="newOutStockCarts"
        :allow-modify-qty="true"
        @delete="soldOutCartsDelete"
      />
      <special-goods-drawer
        v-if="specialGoodsDialog.loaded"
        :special-goods-list="productLimitCarts"
        :checkout="checkout"
        @move="handleUpdateCheckoutStatus"
        @supplement-address="handleToSupplementAddress"
      />
      <SLazyMount>
        <s-dialog 
          v-model:visible="giftCardLimit.show"
          :show-close="false"
          :append-to-body="true"
        >
          <div>{{ giftCardLimit.txt }}</div>
          <template #footer>
            <s-button-group :width="350">
              <s-button-group-item @click="giftCardRemove">
                {{ language.SHEIN_KEY_PC_18439 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['primary']"
                @click="giftCardConfirm"
              >
                {{ language.SHEIN_KEY_PC_18440 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </SLazyMount>
      <SLazyMount>
        <s-dialog 
          v-model:visible="fullLoading"
          :close-on-click-modal="false"
          :show-close="false"
          :append-to-body="true"
        >
          <div>
            <div class="la-ball-pulse la-ball-pulse-black">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </s-dialog>
      </SLazyMount>
      <!-- adyen-mbway 手机弹窗 -->
      <MbwayPhone
        v-if="mbwayInfo.dialogShow"
        :language="language"
        :mbway-info="mbwayInfo"
        @confirm-mbway-phone="confirmMbwayPhone"
        @mbway-input-focus="mbwayInputFocus"
      />
      <PaypalGaVault
        v-if="paypalGaVault.tipShow"
        :language="language"
        :paypal-ga-vault="paypalGaVault"
        :ppgv-account-info="ppgvAccountInfo"
        @click-paypal-select="clickPaypalSelect"
        @confirm-ppgv-type="confirmPPGVType"
      />
      <!-- googlepay 弹窗，不能用自闭合标签!! -->
      <!-- eslint-disable vue/html-self-closing -->
      <googlepay-dialog
        v-if="isClient"
        v-model:visible="googlePay.show"
        @manual-close="handleCloseGooglePayDialog"
      >
      </googlepay-dialog>
      <retain-dialog
        v-if="isClient"
        ref="RetainDialog"
      >
      </retain-dialog>
      <shipping-fee-info
        v-if="isClient"
        ref="shippingFeeDrawer"
        :shipping-fee-rule-info="shippingFeeRuleInfo"
        :is-suggested="isSuggested"
        @close-shipping-fee="closeShippingFee"
      />
      <fpx-tips-dialog
        v-if="fpxTipsDialogProps.loaded"
        v-model:visible="fpxTipsDialogProps.visible"
        :tips="fpxTipsDialogProps.tips"
        :confirm-button-text="fpxTipsDialogProps.confirmButtonText"
        @confirm="confirmFpxTips"
        @cancel="cancelFpxTips"
      />
      <!-- 下单限制前置 - 修改地址提示 -->
      <shipping-address-limit
        v-if="shippingLimitAddressIsLoaded"
        :second-language="secondLanguage"
        @edit-address="clickEditAddress"
        @add-address="clickAddAddress"
        @select-address="assignState && assignState({ showAddressDialog: true })"
        @edit-store-address="handleShippingLimitEditStoreAddress"
        @close="handleShippingLimitAddressClose"
      />
      <!-- 下单限制规则前置 - 修改店配地址 -->
      <StoreAddressLimit
        v-if="shippingLimitStoreIsLoaded"
        @edit-store-address="clickEditStoreAddress"
        @handle-shipping-select="clickShipping"
      />
      <!-- token前置相关组件 -->
      <token-pay-comps 
        :visible-options="challengeModalOptions"
      />

      <!-- 分期列表弹窗 -->
      <installment-list-dialog 
        :language="language" 
        @create-order="createOrder"
      />
      <!-- token前置cvv收集弹窗 -->
      <CvvDialog 
        :language="language"
        :default-select-token-pay="defaultSelectTokenPay"
        :is-show-cvv-dialog="isShowCvvDialog"
        @ChangeIsShowCvvDialog="isShowCvvDialog = false"
        @cvvResult="handleCvvResult"
      />

      <ShoppingBagTaxDialog
        v-if="showTaxHint"
        @create-order="createOrder"
      />

      <KnivesDialog
        :limit-goods-list="machedKnivesGoods"
        :language="language"
        @create-order="createOrder"
      />

      <CartNullDailog
        v-if="showCartNullDialog"
        :language="language"
      />

      <!-- 卡分期支付前置 -->
      <SLazyMount>
        <PrePayModal
          ref="prePayModalRef"
          v-model:visible="visiblePrePay"
          @actively-close="handlePrePayActiveClose"
        >
          <template #footer>
            <SButton
              :type="['primary']"
              @click="handlePrePayForCheckout"
            >
              <span v-html="placeOrderTxt" />
            </SButton>
          </template>
        </PrePayModal>
      </SLazyMount>
      <SLazyMount>
        <FailGuideModal
          v-model:visible="prePayFailGuide.visible"
          :text="prePayFailGuide.failText"
          :type="prePayFailGuide.type"
          :language="prePayLanguage"
          :close-cb="prePayFailGuide.closeCb"
          :confirm-cb="prePayFailGuide.confirmCb"
          :has-guide="prePayFailGuide.hasGuide"
        />
      </SLazyMount>
      <!-- 单后场景 - 展示了卡bin优惠但冻结失败弹窗提示 -->
      <SLazyMount>
        <BinDiscountFreezeFailTips
          v-model:visible="prePayBinFreezeFailConfig.visible"
          :language="language"
          @cancel="prePayBinFreezeFailConfig.closeCb"
          @skip-freeze-to-pay="prePayBinFreezeFailConfig.confirmCb"
        />
      </SLazyMount>

      <CheckoutLoading 
        :language="language" 
        :new-checkout-loading-type="newCheckoutLoadingType"
        :is-use-new-checkout-loading="isUseNewCheckoutLoading"
        :now-pay="status.cardPay"
      />
    </ClientOnly>
  </div>
</template>

<script>
import mitt from 'mitt'
if(typeof window != 'undefined') window.checkoutEventCenter = mitt()
if(typeof window != 'undefined') window.appEventCenter = mitt()

import GooglePay from './components/GooglePay/googlePay'


import klarnaInlineMixin from './klarnaInlineMixin.js'
import checkoutMixin from './checkoutMixin.js'
import couponMixin from './mixins/couponMixin'
import priceMixin from './mixins/priceMixin'


import {
  handleSwitchCurrency,
  transformExtraPromotionTip,
  initCybersource,
  handleShipMethodsPrice,
  autoPrimeProducfilter,
  filterPromitionGoods
} from './utils'
import { judgeActivityIsExist } from 'public/src/pages/cart_new/utils'
import {
  convertAddress,
  sortAddressCardInfo,
} from 'public/src/pages/components/address/config'
import {
  checkLocationClauseSer,
  checkMemeberCluaseSer,
} from '../components/login/service'
import { newPrivacyCache } from '../components/login/util'
import {
  newSetShippingList,
  mallMerge,
  handleLimitGoods,
  handleMallShippingAnalysisParam,
  handleMallShippingAnalysis,
  handleCalcuMallParam,
  handleMallInsuranceChange,
  updateMallInsuranceCheck,
  handleCurrShipSwitchQsFlag,
  handleNextShippingMethod,
  isShipOrderIntercept,
  initMallShipIndex,
  isShowMultiMallAddressMutex,
  handleMallShipIndex
} from './config/tools'
import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import schttp from 'public/src/services/schttp'
import { checkSchttp } from 'public/src/services/schttp/tools'
import apiReport from 'public/src/pages/common/apiReport'
import PaidyPay from 'public/src/pages/common/paidyPay/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import applePayConfig from 'public/src/pages/common/apple_pay'
import { CustomQuota } from 'public/src/js/utils/customQuota'
import { HtmlCacheClass } from 'public/src/js/utils/htmlCacheClass'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { PPGA_CHECK_TYPE, ADDRESS_ERROR_CODE } from '../common/constants'
import useBankSelect from './hooks/useBankSelect'
import useVm from './hooks/use_vm'
import usePaymentAbt from 'public/src/pages/checkout/hooks/usePaymentAbt'
import useShowFpxTips from './hooks/useShowFpxTips'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import useTokenPay from '@/public/src/pages/common/handlePay/hooks/useTokenPay.js'
import { replacePaySuccessReferer } from '@/public/src/pages/common/handlePay/tools.js'
import { checkoutMarkPoint, cartBusinessModelList } from 'public/src/pages/common/handlePay/utils.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import useCheckoutDialog from './hooks/useCheckoutDialog'
import usePrePay from '@/public/src/pages/components/prePay/hooks/usePrePay.js'
import useCardPayForCheckout from '@/public/src/pages/components/prePay/hooks/useCardPayForCheckout.js'
import useDdc from '@/public/src/pages/common/handlePay/hooks/useDdc.js'
import useChallengeModal from '@/public/src/pages/common/handlePay/hooks/useChallengeModal.js'
import usePrimePackage from 'public/src/pages/checkout/hooks/usePrimePackage.js'

import { mapState, mapMutations, mapActions, mapGetters, useStore } from 'vuex'
import { shallowReactive, toRaw, defineAsyncComponent } from 'vue'
import {
  htmlDecode,
  transformImg,
  removeCookie,
  asyncLoadFile,
  template as _commonTemplate,
  getQueryString,
  getLocalStorage,
  mergeQueryString,
} from '@shein/common-function'


import { SToast } from '@shein-aidc/sui-toast'
import { SMessage } from '@shein-aidc/sui-message'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount'
import { SButton } from '@shein-aidc/sui-button'

import PageHeader from 'public/src/pages/components/page-header/PageHeader.vue'
import NavBar from 'public/src/pages/components/nav-bar/NavBar.vue'
import moreAlertCom from './vue_tpls/moreAlertCom.vue'
import addressCreator from 'public/src/pages/components/address/index'
import ShippingAddress from 'public/src/pages/checkout/components/address/shipping/ShippingAddress.vue'
import StoreAddress from 'public/src/pages/checkout/components/address/store/StoreAddress.vue'
import shippingOptions from 'public/src/pages/checkout/components/shipping_method/ShippingOptions.vue'
import CheckoutCart from './vue_tpls/CheckoutCart.vue'
import shoppingMall from './vue_tpls/shoppingMall.vue'
import TogetherBuy from './vue_tpls/together_buy/container.vue'
import paymentOptions from './vue_tpls/paymentOptions.vue'
import CheckoutSummary from './vue_tpls/CheckoutSummary.vue'
import { 
  fetchCheckStoreAddressApi, 
  fetchCheckTwNameApi, 
  fetchUpdateExtraAddressBookApi,
  fetchDelAddressBookApi,
  fetchGetStoreAddressApi,
} from 'public/src/services/api/pay/checkout.js'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })
daEventCenter.addSubscriber({ modulecode: '2-1' })
daEventCenter.addSubscriber({ modulecode: '1-7-5' })
daEventCenter.addSubscriber({ modulecode: '1-19-1' })

const CustomQuotaInstance = CustomQuota.getInstance()
CustomQuotaInstance
  .setPathName('checkout')
  .setQuotaReg({
    reqRegAry: ['api/checkout/mallOrder/create', 'devices/v3/profile/web'],
    jsRegAry: [/assets\/checkout/],
    cssRegAry: [/checkout/, /theme/, /shein/]
  })


//cod不可用code值
const codDisabledCode = [
  2, 3, 4, 5, 8, 9, 10, 11, 12, 14, 15, 16, 21, 22, 23, 99, 28, 29, 31
]

const HtmlCacheClassInstance = HtmlCacheClass.getInstance()
const htmlClearCacheFn = () => {
  HtmlCacheClassInstance.clearCacheFn({ removeKeyName: 'pcCheckoutHtml' })
  window.addEventListener('beforeunload', () => {
    // 离开页面或者刷新时清空，防止使用者遗漏清空
    HtmlCacheClassInstance.clearCacheFn()
  })
}

function routerReplace(url) {
  if (typeof window === 'undefined' || !url) return
  if (window.history && history.replaceState) {
    history.replaceState(null, null, url)
  }
  location.href = url
}

export default {
  name: 'CheckoutContainer',
  components: {
    SButton,
    SLazyMount,
    PageHeader,
    NavBar,
    moreAlertCom,
    addressVue: addressCreator,
    ShippingAddress,
    StoreAddress,
    shippingOptions,
    CheckoutCart,
    shoppingMall,
    TogetherBuy,
    CheckoutSummary,
    AddressCommon: defineAsyncComponent(() => import('public/src/pages/checkout/components/address/AddressCommon.vue')),
    KnivesDialog: defineAsyncComponent(() => import('public/src/pages/checkout/vue_tpls/knives_age_dialog/IndexDialog.vue')),
    CartNullDailog: defineAsyncComponent(() => import('public/src/pages/checkout/vue_tpls/cart_null_dailog/cart_null_dailog.vue')),
    ChooseAddressDialog: defineAsyncComponent(() => import('./vue_tpls/ChooseAddressDialog.vue')),
    paymentOptions,
    'googlepay-dialog': defineAsyncComponent(() => import('./components/GooglePay/googlePayDialog.vue')),
    'retain-dialog': defineAsyncComponent(() => import('public/src/pages/checkout/components/retain_dialog/index.vue')),
    ShippingFeeInfo: defineAsyncComponent(() => import('public/src/pages/checkout/components/shipping_fee/index.vue')),
    FpxTipsDialog: defineAsyncComponent(() => import('./vue_tpls/FpxTipsDialog.vue')),
    ShippingAddressLimit: defineAsyncComponent(() => import('public/src/pages/checkout/components/shipping_order_intercept/ShippingAddressLimit.vue')),
    StoreAddressLimit: defineAsyncComponent(() => import('public/src/pages/checkout/components/shipping_order_intercept/StoreAddressLimit.vue')),
    TokenPayComps: defineAsyncComponent(() => import('public/src/pages/common/handlePay/components/TokenPayComps.vue')),
    SoldOutVue: defineAsyncComponent(() => import('./vue_tpls/sold_out.vue')),
    SpecialGoodsDrawer: defineAsyncComponent(() => import('./vue_tpls/SpecialGoodsDrawer.vue')),
    CheckoutDialog: defineAsyncComponent(() => import('public/src/pages/checkout/components/CheckoutDialog.vue')),
    MbwayPhone: defineAsyncComponent(() => import('./components/dialog/MbwayPhone.vue')),
    PaypalGaVault: defineAsyncComponent(() => import('./components/dialog/paypalGaVault.vue')),
    InstallmentListDialog: defineAsyncComponent(() => import('@/public/src/pages/checkout/vue_tpls/pay_installment/installment_list_dialog.vue')),
    CvvDialog: defineAsyncComponent(() => import('public/src/pages/checkout/vue_tpls/cvvDialog.vue')),
    ShoppingBagTaxDialog: defineAsyncComponent(() => import('public/src/pages/checkout/components/shopping_bag_tax/dialog.vue')),
    PrePayModal: defineAsyncComponent(() => import(/* webpackChunkName: "pre-pay-modal" */'public/src/pages/components/prePay/index.vue')),
    FailGuideModal: defineAsyncComponent(() => import('@/public/src/pages/components/prePay/components/FailGuideModal.vue')),
    BinDiscountFreezeFailTips: defineAsyncComponent(() => import('@/public/src/pages/components/prePay/components/BinDiscountFreezeFailTips.vue')),
    CheckoutLoading: defineAsyncComponent(() => import('public/src/pages/checkout/vue_tpls/checkout_loading/checkout_loading.vue')),
  },
  mixins: [klarnaInlineMixin, checkoutMixin, couponMixin, priceMixin],
  setup () {
    let store = useStore()
    let  { setShowRiskDialog } = useCheckoutDialog()
    const { vm } = useVm()
    const { 
      banksConfig, 
      bankDrawerConfig, 
      capitecDialog,
      updateBankDrawerConfig, 
      updateBanksConfig, 
      handleBankClick,
      handleBankSelect,
      handleStartSelectBank,
      openDelayChangeBanks,
      closeDelayChangeBanks,
      setDelayChangeBanksData,
      delayChangeBanksData,
      handleCapitecDialogEvent,
      updateCapitecDialog
    } = useBankSelect()
    const {
      checkShowFpxTips,
      fpxTipsDialogProps,
      showFpxTips,
      confirmFpxTips,
      cancelFpxTips,
    } = useShowFpxTips({
      checkoutBFFAbtInfo: store.state.checkout?.checkoutBFFAbtInfo || {},
      language: store.state.language,
    })
    const {
      paymentCustomizeFlow,
      codFreeShow,
      applePayCashShow
    } = usePaymentAbt()
    const handleBankSelectInVm = (bankItem)=>{
      handleBankSelect(bankItem)
      if(bankDrawerConfig.value.autoPay){
        vm.createOrder?.()
        updateBankDrawerConfig({
          autoPay: false
        })
      }
    }

    const handleCapitecDialogEventInVm = (evt)=>{
      // if(!capitecDialog?.value?.isDlocalPse) {
      //   reportBankDialog(evt)
      // }
      handleCapitecDialogEvent(evt)
      if(evt.type === 'submit' && bankDrawerConfig.value.autoPay){
        // 南非capitec信息采集弹框
        vm.createOrder()
        updateBankDrawerConfig({
          autoPay: false
        })
      }
    }
    const handleBankClickInVm = ({ item, callback })=>{
      updateCapitecDialog({
        phone: vm.checkout?.default_address?.tel,
        countryId: vm.checkout?.default_address?.countryId
      })
      handleBankClick({ bankItem: item, callback })
    }

    const { challengeModalOptions } = useChallengeModal()

    const {
      ddcInfo,
      selectedTokenPayInfo,
      initTokenPayConfig,
      setTokenPayInfo,
      handleTokenPay,
      normalTokenPayResultHandle,
      initTransformPci,
      setTokenPayCvv,
      isShowCvvDialog,
      tokenCvv
    } = useTokenPay({
      scene: 'Checkout',
      challengeModalOptions
    })

    const {
      initPayJsonWebToken,
      sendDdc,
    } = useDdc()

    const { 
      visiblePrePay,
      prePayModalRef,
      ddcFormParams,
      prePayUnPayParams,
      prePayFailGuide,
      prePayLanguage,
      prePayCheckoutInfo,
      cardBinFreezeParams,
      prePayBinFreezeFailConfig,
      pageReadyTime,
      clickContinueResultInfo,
      // virtualTokenInfo,
      formatPrePayCheckoutInfo,
      updatePrePaySetCheckoutInfo,
      prePayEventBus,
      handlePrePayForCheckout,
      getInstallmentInfo: prePayGetInstallmentInfo,
      prePayMergeState,
      prePayUpdateState,
      checkPrePayParamsChange,
      handleQueryUnPayPreRoutingInfo,
      changeCardBinDiscountInfoData,
      resetPrePayCheckoutInfo,
      reportClickContinueResult,
      // appendVirtualTokenInfo,
      // resetVirtualTokenInfo
    } = usePrePay({
      scene: 'Checkout'
    })

    const {
      initPrePayForCheckoutConfig,
      setPrePayCheckoutInfo,
      handlePayForPreCheckout,
    } = useCardPayForCheckout({
      scene: 'Checkout',
      challengeModalOptions
    })

    const { changePrimeInfo, changePrimeUpdateCalcu, initPrimeModule, autoPrimePackagefilter } = usePrimePackage()
    return {
      setShowRiskDialog,
      banksConfig,
      bankDrawerConfig,
      capitecDialog,
      updateBankDrawerConfig,
      updateBanksConfig,
      handleBankSelectInVm,
      handleStartSelectBank,
      checkShowFpxTips,
      fpxTipsDialogProps,
      showFpxTips,
      confirmFpxTips,
      cancelFpxTips,
      paymentCustomizeFlow,
      codFreeShow,
      applePayCashShow,
      openDelayChangeBanks,
      closeDelayChangeBanks,
      setDelayChangeBanksData,
      delayChangeBanksData,
      handleCapitecDialogEventInVm,
      handleBankClickInVm,
      ddcInfo,
      selectedTokenPayInfo,
      initTokenPayConfig,
      setTokenPayInfo,
      handleTokenPay,
      normalTokenPayResultHandle,
      initPayJsonWebToken,
      sendDdc,
      initTransformPci,
      setTokenPayCvv,
      isShowCvvDialog,
      tokenCvv,
      visiblePrePay,
      prePayModalRef,
      ddcFormParams,
      prePayUnPayParams,
      prePayFailGuide,
      prePayLanguage,
      prePayCheckoutInfo,
      cardBinFreezeParams,
      prePayBinFreezeFailConfig,
      pageReadyTime,
      clickContinueResultInfo,
      // virtualTokenInfo,
      formatPrePayCheckoutInfo,
      updatePrePaySetCheckoutInfo,
      prePayEventBus,
      handlePrePayForCheckout,
      prePayGetInstallmentInfo,
      initPrePayForCheckoutConfig,
      setPrePayCheckoutInfo,
      handlePayForPreCheckout,
      challengeModalOptions,
      prePayMergeState,
      prePayUpdateState,
      checkPrePayParamsChange,
      handleQueryUnPayPreRoutingInfo,
      changeCardBinDiscountInfoData,
      resetPrePayCheckoutInfo,
      reportClickContinueResult,
      changePrimeInfo, initPrimeModule, changePrimeUpdateCalcu, autoPrimePackagefilter
    }
  },
  data() {
    return {
      paypalLimit: false,
      TRANSPORT_TYPE_SHOP,
      paymentSuggestion: {},
      qiwiPayPhone: '', // qiwi phone
      orderDate: '', //生成订单信息
      paypalUrl: 'javascript:;',
      paymentId: {
        'worldpay-sofort': 7,
        'worldpay-poli': 9,
        'worldpay-ideal': 13,
        'worldpay-qiwi': 11,
      },
      isTipHide: -1,
      giftcard: {
        num: '',
        pin: '',
        loading: false,
        apply: false, //是否点击使用礼品卡
        success: false, //使用礼品卡是否成功
        err: '',
      },
      googleValid: false, //浏览器是否兼容使用谷歌支付
      paymentList: {
        showLoading: false, //是否出现加载动画（超过1s加载时间时出现）
        loading: false, //目前是否正在请求支付列表
      },
      freeShipTip: {
        show: false,
        txt: '',
      },
      payLimitTxt: {
      },
      addressId: 0,
      sourceGray: [],
      paytmupiEmail: '',
      paytmupiError: false,
      extraPromotion: '',
      afterPayInfo: {},
      installmentInfo: {}, //分期信息
      installmentInfoCache: {}, //分期信息缓存
      fullWalletNoCurr: false,
      modalTipsShow: false,
      discountLimitIds: '',
      paymentDescriptionAbt: true,
      secondLanguage: {
        state: '',
        city: '',
        district: '',
      },
      secondLanguageCache: {},
      shake: false,
      beforeChangedCurrency: '',
      productLimitCarts: [],
      soldoutType: -1, //0：无货弹窗，1：商品限制弹窗
      giftCardLimit: {
        show: false,
        txt: '',
        verify: 0,
        clickConfirm: false,
      },
      krCopywriteTip: '', // 韩国地址提示语
      mixPrivacy: { show: false, locationId: '', needPop: false, loaded: false },
      mixPrivacyAbt: { loginPrivac: '', placeOrder: '' },
      billnoList: [],
      newOutStockCarts: [],
      googlePay: { show: false },
      layerY: 0,
      placeOrderRiskId: '', // 下单风控risk_id
      interestsDataHasReport: false,
      isInitNotReoprt: true,
      isClient: false,
      currencyCode: '',
      machedKnivesGoods: [],
    }
  },
  computed: {
    ...mapState([
      'soldOutDialog',
      'specialGoodsDialog',
      'currTips',
      'emailTips',
      'tipModal',
      'shippingLimitAddressIsLoaded',
      'shippingLimitStoreIsLoaded',
      'couponData',
      'pointsData',
      'couponGift',
      'coupon',
      'riskStr',
      'walletData',
      'checkout',
      'caculateData',
      'defaultShipping',
      'initializeDefaultShipping',
      'mallInsuranceCheck',
      'language',
      'status',
      'mallShippingMethods',
      'paymentCouponList',
      'cartPromotionMap',
      'primeProductList',
      'usedPrimeIndex',
      'usedPrimeCode',
      'selectdPrimeIndex',
      'showMarkedPrice',
      'coupon_payment_code_unique',
      'commonDialogInfo',
      'shipTimeDesList',
      'isClickedQsFreight',
      'qsFreightSelected',
      'rightConfigList',
      'codConfirm',
      'codCoupon',
      'showShippingFeeDrawer',
      'shippingFeeRuleInfo',
      'usedXtraCode',
      'xtraProductInfo',
      'togetherBuy',
      'visibleChooseAddressDialog',
      'shippingTypeChangeInfo',
      'storeData',
      'shippingLimitInfo',
      'selectStoreAddressShippingInfo',
      'mallCartsList',
      'virtualCouponInfo',
      'defaultSelectTokenPay',
      'forceUpdateShippingMethod',
      'buyNowNo',
      'buyNowFromUrl',
      'integrityPromotionInfo',
      'shippingContentInfo',
      'isShowTokenPay',
      'createOrderBin',
      'createOrderBinDialogShow',
      'isNoNeedCardBin',
      'locals',
      'showAddressDialog',
      'prevCalcuMallParams',
      'handlerSelectBin',
      'isShowTokenFrontInstallment',
      'installmentSelectBin',
      'installmentSelectToken',
      'selectInstallment',
      'xtraAllProductBagInfo',
      'prePayForCheckoutReady',
      'isMAESTRO',
      'adult',
      'isMallOrderCreateFail',
      'showCartNullDialog',
      'newCheckoutLoadingType',
      'couponBinList'
    ]),
    ...mapGetters([
      'isCouponAutoUse', 
      'showXtra',
      'primeRightCouponCodeList',
      'shoppingBagPosition',
      'forcedShippingByAbt',
      'switchQsFlagByAbt',
      'orderInterceptByAbt',
      'isShopTransit',
      'newAddressShipByAbt',
      'productAutoInfo',
      'isAutoUseWallet',
      'isFreePrice',
      'showAddEntranceByAbt',
      'isSelectTokenPay',
      'isHaveInitTokenPay',
      'isSuggested',
      'isSelectInstallmentTokenPay',
      'isShowbrTaxhint',
      'touchRuleInfo',
      'taxHint',
      'xtraAutoController',
      'isUsePrePayForCheckout',
      'isCheckCardinstallmentInfoStatus',
      'isUseNewCheckoutLoading'
    ]),
    isLastGoods() {
      const carts = filterPromitionGoods(this.checkout.results?.carts?.carts)
      return carts.length == 1
    },
    showTaxHint() {
      const isBrSite = !!~this.SiteUID.indexOf('br')

      const { match_goods = [] } = this.touchRuleInfo || {}
      const touchRuleId = match_goods?.map(item => item.id) || []
      let cartInclude = false
      const carts = this.checkout.results?.carts?.carts || []
      carts.forEach(cart => {
        if (touchRuleId.includes(cart.id)) cartInclude = true
      })

      return (
        !this.IS_RW &&
        this.isShowbrTaxhint &&
        isBrSite &&
        !!this.taxHint &&
        cartInclude &&
        !this.isLastGoods
      )
    },
    lang() {
      return this.locals.lang
    },
    currency() {
      return this.locals.currency
    },
    host() {
      return this.locals.host
    },
    IMG_LINK() {
      return this.locals.IMG_LINK
    },
    WEB_CLIENT() {
      return this.locals.WEB_CLIENT
    },
    IS_RW() {
      return this.locals.IS_RW
    },
    NODE_SERVER_ENV() {
      return this.locals.NODE_SERVER_ENV
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    SECOND_LANGUAGE_COUNTRIES() {
      return this.locals.SECOND_LANGUAGE_COUNTRIES
    },
    EZ_WAY_APP() {
      return this.locals.EZ_WAY_APP
    },
    PUBLIC_CDN() {
      return this.locals.PUBLIC_CDN
    },
    SHOP_TRANSIT() {
      return this.locals.SHOP_TRANSIT
    },
    currencySupport() {
      return this.checkout.results.currencySupport || []
    },
    applePayMethod() {
      return this.checkout.applePayConfig?.applePayChannel || applePayConfig.applePayChannel || []
    },
    replaceTxt() {
      return {
        couponMin: this.language.SHEIN_KEY_PC_14702,
        haveUse: this.language.SHEIN_KEY_PC_14763,
        avaPoint: this.language.SHEIN_KEY_PC_14700,
        pay_redirect1: this.language.SHEIN_KEY_PC_14513,
        pay_redirect2: this.language.SHEIN_KEY_PC_14514,
        gaLabelError: '',
      }
    },
    cancelText() {
      return this.language.SHEIN_KEY_PC_15737
    },
    okText() {
      return this.language.SHEIN_KEY_PC_15738
    },
    // 导航栏选项
    links(){
      const originLinks = [this.language.SHEIN_KEY_PC_14710, this.language.SHEIN_KEY_PC_14711, this.language.SHEIN_KEY_PC_14714, this.language.SHEIN_KEY_PC_14715]
      const cartLang = this.language?.SHEIN_KEY_PC_14710
      if(!!this.buyNowNo){
        return originLinks.includes(cartLang) ? originLinks.filter(f => f !== cartLang) : originLinks.slice(1)
      }
      return originLinks
    },
    // 导航栏激活的下标
    active(){
      return !!this.buyNowNo ? 0 : 1
    },
    // 立即购
    showTogetherBuy() {
      if (this.checkout?.checkoutBFFAbtInfo?.NewUserCasualBuy?.param?.NewUserCasualBuy == 'new') {
        return false
      }
      
      const show = !this.IS_RW && this.togetherBuy.togetherBuyType
      return !!this.buyNowNo ? show && this.checkout.CHECKOUT_SWITCH_BUY_TOGETHER_NOW == 'on' : show
    },
    paylalLimit(){
      const { paypalSelect, currentProduct } = this.productAutoInfo || {}
      const { paypalSelect: xtraPaypalSelect, currentProduct: xtraCurrentProduct } = this.xtraAutoController || {}
      const prime = this.paypalGaVault?.type == 1 && paypalSelect && (currentProduct?.product_activity_type == 1 || this.paypalGaVault?.isPayListDailog)
      const xtra = this.paypalGaVault?.type == 1 && xtraPaypalSelect && (xtraCurrentProduct?.product_activity_type == 1 || this.paypalGaVault?.isPayListDailog)
      return { prime, xtra }
    },
    //默认运输方式曝光埋点
    viewShippingAnalysis() {
      if (typeof window == 'undefined') return {}
      const defaultShippingList = (this.defaultShipping || []).map(item => {
        const default_shippingmethod = item?.shipping_method?.transport_type || ''
        let default_from = 0 //默认运输方式来源；0-后端返回 1-前端缓存
        if (this.checkout.cacheInfo?.mall_transport_type?.used) {
          const cacheTransportTypeList = this.checkout.cacheInfo.mall_transport_type.val
          if (cacheTransportTypeList.some(val => val.mall_code == item.mall_code && val.transport_type == default_shippingmethod)) {
            default_from = 1
          }
        }
        return { default_shippingmethod, default_from }
      })
      if (defaultShippingList.length > 1) {
        return {
          default_from: defaultShippingList.map(item => item.default_from).join('^'),
          default_shippingmethod: defaultShippingList.map(item => item.default_shippingmethod).join('^'),
        }
      }
      return defaultShippingList[0]
    },
    discountRewardList() {
      const PUBLIC_CDN = this.PUBLIC_CDN
      let list = []
      const price = this.checkout?.mall_caculate_info || {}
      const isFree = handleShipMethodsPrice({ defaultShipping: this.defaultShipping, price })
      // 当前选中的运输方式 
      // 若存在多个选项，则优先级为免邮＞优惠＞积分
      if (isFree) { // 多mall下均免邮才展示
        list.push({
          name: 'freeShipping', 
          des: this.language.SHEIN_KEY_PC_15911,
          type: 1, 
          img: `${PUBLIC_CDN}/she_dist/images/checkout/sui_img_shipping_22-77152940c9.png`,
        })
      }
      const showSaveAmount = this.checkout.checkoutBFFAbtInfo?.Shownewsaveamount?.param?.show_new_save_amount == 'on' ? true : false
      const discountPrice = showSaveAmount ? price?.saved_total_price : price?.discount_price_with_coupon
      if (+discountPrice?.amount > 0) {
        list.push({
          name: 'allDiscount', 
          des: this.template(discountPrice?.amountWithSymbol, this.language.SHEIN_KEY_PC_23749),
          type: 2, 
          img: `${PUBLIC_CDN}/she_dist/images/checkout/sui_img_save_22-002990156c.png`,
        })
      }
      if (+price?.totalPoints > 0) {
        list.push({
          name: 'rewardPoint', 
          des: this.template(price?.totalPoints, this.language.SHEIN_KEY_PC_23750),
          type: 3, 
          img: `${PUBLIC_CDN}/she_dist/images/checkout/sui_img_points_22-4d69c8d662.png`,
        })
      }
      if (list.length) list.sort((a, b) => a.type - b.type)
      return list
    },
    isChooseFoldedPayment() {
      const { folded_position, originFoldedPosition } = this.checkout.results || {}
      if (!this.caculateData.payment_id || folded_position !== -1 || originFoldedPosition <= 0) {
        return false
      }
      const selectedPaymentIndex = this.checkout.results.paymentMethods.findIndex(item => item.id === this.caculateData.payment_id)
      return selectedPaymentIndex >= originFoldedPosition
    },
    defaultAddressStateCityDistrict() {
      const { countryId, stateId, cityId, districtId } =
        (this.checkout && this.checkout.default_address) || {}
      return {
        countryId,
        stateId,
        cityId,
        districtId,
      }
    },
    cartsData() {
      const { mallShippingMethods } = this
      const mallCode = mallShippingMethods.mall_list && mallShippingMethods?.mall_list[0] && mallShippingMethods?.mall_list[0].mall_code || '1'
      return this.checkout?.results?.carts?.carts?.filter(item => item.mall_code == mallCode) || []
    },
    quickShipInfo() {
      const { mallShippingMethods } = this
      const mallCode = mallShippingMethods?.mall_list?.[0]?.mall_code || '1'
      const filterShip = mallShippingMethods?.mall_list?.find(item => item.mall_code == mallCode) || {}
      return { desc_quick_ship_time: filterShip?.desc_quick_ship_time || '', quick_ship_time: filterShip?.quick_ship_time || '' }
    },
    cartsMallInfo() {
      const { mallShippingMethods } = this
      const mallCode = mallShippingMethods.mall_list && mallShippingMethods.mall_list[0] && mallShippingMethods.mall_list[0].mall_code || '1'
      return this.checkout?.results?.carts?.mall_list?.find(item => item.mall_code == mallCode) || {}
    },
    promotionNew() {
      return this.checkout?.results?.carts?.data?.promotionNew || {}
    },
    extraPromotionTip() {
      const { extraPromotion, language } = this
      return transformExtraPromotionTip({ language, extraPromotion })
    },
    checkUserWallet() {
      return +this.checkout?.mall_caculate_info?.wallet?.subtractPrice?.amount > 0
    },
    adrNameVerifiedTitleTip() {
      if(typeof window == 'undefined') return this.language.SHEIN_KEY_PC_23405
      let osType = /macintosh|mac os x/i.test(navigator.userAgent) ? 'ios' : 'android'
      return this.template(this.EZ_WAY_APP[osType], this.language.SHEIN_KEY_PC_23405) // "為符合台灣海關實名認證規定，請<a href=\"{0}\">提前下載</a>官方實名認證App“EZ WAY易利委”快速註冊，讓包裹無憂通關！"
    },
    // 总价色值调整
    // 免运费或存在saved，且全链路优惠abt开启时
    isDiscountTotalColor () {
      const scene = 'checkout'
      const rewardNames = ['freeShipping', 'allDiscount']
      const dataMatch = !!this.discountRewardList.some(v => rewardNames.includes(v.name))
      const isShowAbt = () => {
        if (!scene || this.discountRewardList?.length < 2) return false
        const abtData = this.checkout.checkoutBFFAbtInfo?.TradeCountHint?.param
        if (abtData && abtData?.indexOf('concealDiscountTitle') != -1 && abtData?.indexOf(scene) != -1) return false
        return true
      }
      return dataMatch && isShowAbt()
    },
    placeOrderTxt() {
      if (this.prePayUnPayParams?.installmentsInfo?.stage_num === 1) return this.language.SHEIN_KEY_PC_14711 
      const priceDom = `<span class='pre-pay-price'>${this.prePayUnPayParams?.installmentsInfo?.installment_amount}</span>`
      return Object.keys(this.prePayUnPayParams?.installmentsInfo || {}).length 
        ? _commonTemplate(priceDom, this.language?.SHEIN_KEY_PC_31568)
        : this.language.SHEIN_KEY_PC_14711 
    }
  },
  watch: {
    'checkout.mall_caculate_info': function (val) {
      this.handleNotSupportCodTip(val)
      this.updateRiskStr()
      this.getInterestsData({ initFirstReport: false })
      this.userBenefit()
      // 当计价有变动，即出现切换了支付方式等场景时，需要判断是否需要清空卡分期前置缓存数据
      this.checkResetPrePayCheckoutInfo()
    },
    'showXtra': function(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.getInterestsData({ initFirstReport: false })
      }
    },
    showTogetherBuy() {
      this.getInterestsData({ initFirstReport: false })
    },
    modalTipsShow(val) {
      val &&
        daEventCenter.triggerNotice({
          daId: '1-11-1-2',
        })
    },
    defaultAddressStateCityDistrict() {
      this.getSecondLanguage()
    },
    showShippingFeeDrawer(v) {
      if (v) this.$refs.shippingFeeDrawer?.init()
    },
    currency: {
      immediate: true,
      handler(v) {
        if(v) this.currencyCode = v
      }
    },
  },
  async created() {
    this.setNowCheckoutScene('Checkout')
    this.assignState({ checkoutScene: this.nowCheckoutScene })
    this.initCheckoutData()
    this.initFirstPageData()

    this.initBankLists(this.checkout?.results?.paymentMethods)
    this.initPayInlineSession({
      paymentMethods: this.checkout?.results?.paymentMethods,
      justReq: true,
    })
    this.payLimit()

    // 赋值club模块需要的事件
    this.initPrimeModule({ updatePayment: this.updatePayment, handleAutoUsePrimeCoupon: this.handleAutoUsePrimeCoupon, filterCouponParams: this.filterCouponParams, getUpdateCoupon: this.getUpdateCoupon })

    if(typeof window != 'undefined') {
      if (this.checkout?.results?.paymentMethods?.length) {
        this.setPaymentAbtTest(this.checkout?.checkoutBFFAbtInfo?.['PaymentABT'] || {})
        SaPageInfo.page_param.is_shop = this.isShopType
  
        this.checkout.results['originFoldedPosition'] = this.checkout?.results?.folded_position
  
        this.getInstallmentInfo()
      }

      let activity_method = judgeActivityIsExist(
        this.checkout.results.carts.carts,
        [3, 10]
      )
      SaPageInfo.page_param.activity_method = activity_method
      if (this.checkout?.results?.mallShippingMethods?.mall_list?.length) {
        const mall_code = this.handleMallCode(this.checkout?.results?.mallShippingMethods?.mall_list) || ''
        SaPageInfo.page_param.mall_code = mall_code
      }
      this.$nextTick(()=>{
        let $checkoutSkeleton = document.querySelector('#checkout-skeleton')
        $checkoutSkeleton && ($checkoutSkeleton.style.display = 'none')
      })
    }
  },
  mounted: function () {
    this.isClient = true
    this.getSecondLanguage()
    // 一站多合规
    this.getMixPrivacyAbt()
    document.addEventListener('ftr:tokenReady', function (evt) {
      window.forterDeviceId = evt.detail || window.forterDeviceId || ''
    })
    initCybersource()
    this.forterLoad()

    this.googlePayInit()
    this.paidyPayInit(this.checkout.results.paymentMethods)
    this.initApplePay(this.checkout.results.paymentMethods)

    // mounted和unload都需要清空缓存，保证如果用户刷新下单页，永远不会走缓存
    htmlClearCacheFn()
    CustomQuotaInstance
      .setQuotaReg({ imgGroupRegAry: this.getCartsImg() })
      .sendBatchData()

    window.checkoutEventCenter.on('update-total', res => {
      const { opt, cb, obj } = res
      this.updateTotal(opt, cb, obj)
    })
    window.checkoutEventCenter.on('orign-update-total', res => {
      const { opt, cb, obj } = res
      this.orignUpdateTotal(opt, cb, obj )
    })
    window.checkoutEventCenter.on('reset-update-total', ({ errCb = null } = {}) => {
      this.resetMallCacuTotal({ errCb })
    })
    window.checkoutEventCenter.on('click-payment', res => {
      const { id, code, selectItem, nowPaymentCoupon, isDoNeedMallCalculateTotal = false } = res
      this.clickPayment(id, code, selectItem, nowPaymentCoupon, { isDoNeedMallCalculateTotal })
    })
    window.checkoutEventCenter.on('again-choose-payment', res => {
      const { id, code, item, paymentCoupon } = res?.paymentInfo || {}
      this.clickPayment(id, code, item, paymentCoupon, { iSautoPay: 'autoPay', Index: res?.Index || 0, isdont: res?.isdont, afterCallBack: res?.afterCallBack })
    })
    window.checkoutEventCenter.on('add-store-address', (params) => {
      this.getStoreAddress(params)
    })
    window.checkoutEventCenter.on('update-shipping-method', async({ 
      address, 
      shippingMethod = {}, 
      mallCode = '', 
      isLimit = 0, 
      skipShipIntercept, 
      modifyCart = false, 
      isInterceptBack = false,
    }) => {
      // 即将选中的是否是店配
      const isStoreAddress = TRANSPORT_TYPE_SHOP.includes(shippingMethod?.transport_type)
      let shippingParams = {
        countryId: isStoreAddress ? this.storeData.countryId : address.countryId,
        address_id: isStoreAddress ? '' : address.addressId,
        has_shop_transit_address: !!this.storeData ? 1 : 0,
        checkout_no: this.buyNowNo
      }
      const data = await this.getShippingList(shippingParams)

      this.resetShippingData(data)

      if(isLimit == 1) {
        this.handleShippingLimit({ 
          mallCode, 
          shippingMethod, 
          skipShipIntercept, 
          modifyCart, 
          isInterceptBack,
        })
      } else {
        this.clickShipping({ scence: 1, modifyCart })
      }
    })
    window.checkoutEventCenter.on(
      'change-xtra-prime-calcu',
      ({ xtraSeleted, primeSeleted }) => {
        // 同时选择付费会员，超省卡包，xtraSeleted, primeSeleted 这两值应该是一样的
        this.changeXtraPrimeUpDateCalcu({ xtraSeleted, primeSeleted })
      }
    )
    window.checkoutEventCenter.on(
      'click-apply-assets',
      (obj) => {
        this.clickApply(obj)
      }
    )
    window.checkoutEventCenter.on(
      'apply-giftcard',
      () => {
        this.applyGiftcard()
      }
    )
    window.checkoutEventCenter.on(
      'cancel-giftcard',
      () => {
        this.cancelGiftCard()
      }
    )
    window.checkoutEventCenter.on(
      'change-xtra-calcu',
      ({ xtraSeleted }) => {
        this.changeXtraUpdateCalcu({ xtraSeleted })
      }
    )
    window.checkoutEventCenter.on(
      'checkout-update-payment',
      ({ 
        updatePaypalGaVaultStatus, 
        request_card_token, 
        token_id = '', 
        isNeedMallCal,
        isInstallment = false,
        updateCb = () => {},  
      }) => {
        let nowSelect = {}

        if (isNeedMallCal && (this?.status?.cardPay || '')?.length > 0) {
          const { paymentMethods = [] } = this.checkout?.results || {}
          nowSelect = paymentMethods.find(item => item.code === this?.status?.cardPay)
          if (nowSelect) {
            this.caculateData.payment_id = nowSelect?.id
            this.caculateData.payment_code_unique = nowSelect?.code
          }
        }

        let parames = {
          value: this.checkout.default_address?.value,
          updatePaypalGaVaultStatus,
          request_card_token, token_id, isNeedMallCal
        }
        if (token_id && token_id !== '') {
          parames.token_id = token_id

          if (isInstallment) {
            parames.installment_token_id = token_id
          }
        }

        let cb = (data) => {
          updateCb && updateCb(data)

          if (isNeedMallCal) {
            if (nowSelect) {
              this.caculateData.payment_id = nowSelect?.id
              this.caculateData.payment_code_unique = nowSelect?.code
            }
            this.orignUpdateTotal(this.caculateData)
          }
        }

        this.updatePayment(parames, cb)
      }
    )
    window.checkoutEventCenter.on(
      'click-address-tips',
      () => {
        this.clickAddressTips()
      }
    )
    window.checkoutEventCenter.on(
      'click-edit-address',
      ({ index, address, type }) => {
        this.clickEditAddress(index, address, type)
      }
    )
    window.checkoutEventCenter.on(
      'click-add-address',
      () => {
        this.clickAddAddress()
      }
    )
    window.checkoutEventCenter.on(
      'click-edit-store-address',
      ({ type, otherData = {}, params = {} }) => {
        this.clickEditStoreAddress(type, otherData, params)
      }
    )

    this.initEvent()
    this.bindRetainEvent()

    //当险种为退货险，且所有商品不可退货(isDisplayShippingInsurance)时，取消勾选险种
    const { isChange = false, insuranceCheck } = handleMallInsuranceChange({ mallInsuranceCheck: this.mallInsuranceCheck, insuranceMallData: this.checkout?.results?.insuranceMallData || {}, mall_caculate_info: this.checkout?.mall_caculate_info })
    if (isChange) {
      this.assignState({ mallInsuranceCheck: insuranceCheck })
      this.updateCalcuMallParam({ selectMallShip: this.defaultShipping, mallInsuranceCheck: insuranceCheck })
      this.updateTotal(this.caculateData, () => {
      })
    } else {
      this.exposeNotSupportCodCode(this.checkout.mall_caculate_info.notSupportCodTips && this.checkout.mall_caculate_info.notSupportCodTips.code || '')
    }


    // 部分站点置灰地址
    this.filteGrayAddress()
    this.setAdrTitleTipsStatus()

    this.getErrorGuideAbt()
    this.updateRiskStr()
    this.getRiskifiedDeviceid()
    this.handleCartsTspLabels()
    // token前置初始化
    this.initTokenPayConfig({ 
      checkoutType: 'normal',
      toggleLoadingStatus: status => { // 控制loading状态
        // 1.token前置初始化失败（与支付相关）（点击成单按钮 - 调用成单接口 中途拦截流程）
        this.status.checkout = status ? 1 : 0
      } 
    })
    this.initPrePayForCheckoutConfig({
      checkoutType: 'normal',
      toggleLoadingStatus: status => { // 控制loading状态
        // 1.token前置初始化失败（与支付相关）（点击成单按钮 - 调用成单接口 中途拦截流程）
        this.status.checkout = status ? 1 : 0
      } 
    })
    setTimeout(()=>{
      this.isInitNotReoprt = false
      this.getInterestsData({ initFirstReport: true })
      this.userBenefit()
    })

    // 记录默认的运输方式
    this.assignState({ initializeDefaultShipping: this.viewShippingAnalysis || {} })
    // TR-10891【交易前台】商城下单/支付页接入同盾设备指纹
    import('public/src/pages/common/RiskCommon/libs/BlackBox/index.js').catch(() => {})
    this.setUserEmail()
    // 支付模块
    import(
      /* webpackChunkName: "checkout-handlepay-js" */ '../common/handlePay/index.js'
    ).then(() => {
      window.GB_handlePayMethods?.scollEvent?.()
    })

    this.prePayEventBus?.updateCacuTotal?.on?.(({ data, cb }) => {
      // console.log('prePayEventBus=====bin===', data)
      const prePayBinParams = {
        selected_bin: data?.selected_bin,
        bins: data?.bins,
        need_select: data?.need_select
      }
      this.orignUpdateTotal(
        {
          ...this.caculateData,
          ...(data?.selected_bin ? prePayBinParams : {} )
        },
        res => {
          if (typeof cb === 'function') return cb(res)
          const prePayCheckoutInfo = this.formatPrePayCheckoutInfo({
            mallCaculateInfo: this.checkout.mall_caculate_info,
            defaultAddress: this.checkout.default_address,
            normalGoodsList: this.checkout?.results?.carts?.carts || [],
            usedPrimeProductInfo: this.primeProductList?.[this.usedPrimeIndex] || {},
            usedXtraProductInfo: this.usedXtraCode && this.xtraProductInfo || {},
            routeCode: this.status.cardPay,
            bin_discount_list: this.checkout?.results?.bin_discount_list || [],
            bin_discount_info: this.checkout.results?.bin_discount_info || {},
            default_address: this.checkout.default_address,
            isAutoRenewal: this.isAutoRenewal
          })
          this.updatePrePaySetCheckoutInfo(prePayCheckoutInfo)
          this.prePayGetInstallmentInfo()
          this.prePayUpdateState('displayCardBinDiscountInfo', this.prePayCheckoutInfo?.cardBinDiscountInfo || {})
          // 单后埋点信息
          const product_type = this.usedXtraCode ? 'order_sheinsaver' : this.usedPrimeCode ? 'order_membership' : 'commontype'
          this.prePayMergeState('clickContinueResultInfo', {
            prime_deduction: this.checkout?.mall_caculate_info?.prime_deduce_price?.amountWithSymbol || '',
            product_type
          })
          this.prePayEventBus?.cacuTotalFinish?.emit?.(res)
        }
      )
    })
    this.prePayEventBus?.createOrder?.on?.(({
      skipFreezeOperate = false,
      failReasonResults,
      prePayCardVaildFail = false
    } = {}) => {
      this.assignState({
        prePayForCheckoutReady: prePayCardVaildFail ? false : true
      })

      if (prePayCardVaildFail) {
        SIMetric.metricCount({
          metric_name: 'web_payment_error_total', // 指标名称
          tags: { // 指标维度
            sub_site: gbCommonInfo?.SiteUID || '',
            error_scene: this.prePayCheckoutInfo?.billno ? 'pre_un_pay_vaild_fail_scene' : 'pre_pay_vaild_fail_scene',
            product_type: 'normal'
          },
          message: `err: ${failReasonResults?.join?.(',')}, billno: ${this.prePayCheckoutInfo?.billno}`
        })
      }

      if (this.prePayCheckoutInfo.billno) {
        const getIsCvvLimit = (cvv = '') => {
          const cvvValIsNoLimitList = ['000', '0000', '']
          const cvvErr = failReasonResults?.find?.(v => v?.startsWith?.('cvv'))
          return cvvErr || !cvvValIsNoLimitList.includes(cvv)
        }
        this?.prePayMergeState('clickContinueResultInfo', {
          status: 'fail',
          failure_reason: prePayCardVaildFail ? JSON.stringify({
            code: '-',
            result: failReasonResults?.join?.(',') || ''
          }) : {},
          failure_type: 'front_end',
          scene: 'new',
          instalment_num: this.prePayUnPayParams?.installments || '',
          is_remember: this.prePayUnPayParams?.rememberCard ? '1' : '0',
          stay_time: parseInt((Date.now() - this.pageReadyTime) / 1000),
          payment_method: this.prePayCheckoutInfo?.routeCode || '',
          // is_need_cvv: failReasonResults?.find?.(v => v?.startsWith?.('cvv')) ? '1' : '0',
          is_need_cvv: '-',
          is_cvvnolimit: getIsCvvLimit(this.prePayCheckoutInfo?.cvv) ? '0' : '1',
        })
        if (prePayCardVaildFail) {
          this.reportClickContinueResult(this.clickContinueResultInfo)
          return
        }
        this.status.checkout = 1
        this.handlePreUnPay({ skipFreezeOperate })
      } else {
        this.createOrder({ prePayCardVaildFail })
      }
    })
  },
  methods: {
    ...mapMutations([
      'updateCheckoutState', 
      'assignState', 
      'changeParamsStatus', 
      'updateSelectedPrime',
      'changeTogetherBuy',
      'changeVirtualCouponInfo',
      'upDateSceneStatusNew',
      'updateShippingAddStatusInfo',
      'updateIsNoNeedCardBin',
      'updateCreateOrderBinDialogShow',
    ]),
    ...mapActions([
      'updateCart', 
      'getCartPromotionInfo', 
      'getCouponListNotice', 
      'handleShipInterceptModal',
      'updateXtraPaymentsRight',
      'handleCartsTspLabels',
      'handleMallCartsList',
      'getShippingFreeMall',
      'initCheckoutData',
      'analysisAutoUseWallet',
      'sendAddressRefillPopUpAnalysis',
    ]),
    getGoodsUrl,
    htmlDecode,
    getCartsImg() {
      let imgAry = []
      try {
        if (typeof window === 'undefined') { return }
        const cartData = this?.checkout?.results?.carts?.carts
        if (Array.isArray(cartData) && cartData?.length > 0) {
          const cartInitImg = cartData
            .filter(item => item?.product?.goods_img)
            .map(item => item?.product?.goods_img)
          // 代码里可能会调用transformImg处理图片，避免工具函数获取资源时遗漏，需要包含format后的url
          imgAry = Array.from(new Set([...cartInitImg, ...cartInitImg.map(item => transformImg({ img: item }))]))
        } 
      } catch (e) {
        console.error('getCartsImg Error', e)
        imgAry = []
      } finally {
        return imgAry
      }
    },
    clickPaypalSelect(e){
      const tostLanguage = this.language.SHEIN_KEY_PC_31173
      if (this.paylalLimit?.prime || this.paylalLimit?.xtra) {
        e && e.preventDefault()
        return SToast( tostLanguage )
      } else {
        this.paypalGaVault.radioType = PPGA_CHECK_TYPE.UNCHECKED
      }
    },
    handleShippingLimit({ 
      mallCode, 
      shippingMethod, 
      skipShipIntercept, 
      modifyCart, 
      isInterceptBack,
    }) {
      this.assignState({
        showShippingLimitAddress: false,
        showShippingLimitGoods: false,
        showShippingLimitStore: false,
      })

      // 此处判断是否受限应该要用即将真实使用的，可能存在请求接口之后原数据不存在场景  
      const { 
        shippingMethod: currShippingMethod, 
        notIncludeMall, 
        prevShippingMethod 
      } = handleNextShippingMethod({ 
        mallShippingMethods: this.mallShippingMethods, 
        mallCode, 
        transportType: shippingMethod.transport_type, 
        prevMallParams: this.caculateData.mall_params, 
        orderInterceptByAbt: this.orderInterceptByAbt,
      })

      // 直接没有对应mall不弹限制弹窗；没有对应type继续受限需要弹限制弹窗
      if(notIncludeMall) { // 重新请求接口后无当前操作的mall时，直接走初始化逻辑，不进行限制弹窗提示（此处不会触发宅店配切换互斥逻辑）
        this.changeParamsStatus({ type: 'shippingLimitInfo', params: { shippingLimitModalCount: 0 } })
        this.clickShipping({ scence: 1, modifyCart })
        return
      }

      const { isIntercept, hintType } = isShipOrderIntercept({ 
        mallShippingMethods: this.mallShippingMethods, 
        shippingMethod: currShippingMethod, 
        mallCode, 
        orderInterceptByAbt: this.orderInterceptByAbt,
      })

      const scence = (skipShipIntercept && !isIntercept) ? 1 : 3

      if(isIntercept && !skipShipIntercept) {
        this.handleShipInterceptModal({ hintType, shippingMethod: currShippingMethod, mallCode })
      } else {
        this.changeParamsStatus({ type: 'shippingLimitInfo', params: { shippingLimitModalCount: 0 } })

        // 是否是 即将切换的受限就切回当前选中的且跳过受限弹窗 场景
        if(isInterceptBack && isIntercept) {
          const prevTransportType = this.caculateData?.mall_params?.find(_ => _.mall_code == mallCode)?.transport_type
          const { shippingMethod: currShippingMethod } = handleNextShippingMethod({ 
            mallShippingMethods: this.mallShippingMethods, 
            mallCode, 
            transportType: prevTransportType, 
            prevMallParams: this.caculateData.mall_params, 
            orderInterceptByAbt: this.orderInterceptByAbt,
          })

          this.assignState({
            forceUpdateShippingMethod: {
              shippingMethod: currShippingMethod,
              mallCode: mallCode,
              isUpdate: true
            }
          })

          this.clickShipping({ scence, mallCode, transport_type: currShippingMethod.transport_type, item: currShippingMethod, modifyCart })

          return
        }

        // 店配宅配互斥弹窗
        const isShowMutexDialog = isShowMultiMallAddressMutex({
          mallShippingMethods: this.mallShippingMethods,
          isShopTransit: this.isShopTransit,
          shippingMethod: currShippingMethod
        })
        if (isShowMutexDialog) {
          this.assignState({
            shippingTypeDialog: true,
            shippingTypeChangeInfo: {
              ...this.shippingTypeChangeInfo,
              shippingMethod: currShippingMethod,
              mallCode,
              prevShippingMethod
            },
            shippingTypeDialogIsLoaded: true
          })
          daEventCenter.triggerNotice({
            daId: this.isShopTransit ? '1-11-1-69' : '1-11-1-68',
          })
          return
        }

        if(this.forceUpdateShippingMethod.isUpdate) {
          this.assignState({
            forceUpdateShippingMethod: {
              shippingMethod: currShippingMethod,
              mallCode: mallCode,
              isUpdate: true
            }
          })
        }
        this.changeParamsStatus({ type: 'shippingLimitInfo', params: { shippingLimitModalCount: 0 } })
        this.clickShipping({ scence, mallCode, transport_type: currShippingMethod.transport_type, item: currShippingMethod, modifyCart })
      }
    },
    sortAddressCardInfo,
    closeShippingFee() {
      this.assignState({
        showShippingFeeDrawer: false,
      })
    },
    chooseAddressHandler() {
      this.status.chooseAddress = 0
      daEventCenter.triggerNotice({
        daId: '1-11-1-129',
      })
    },
    clickNavBar(index) {
      if(index == 0) this.clickCartGuide('cart')
    },
    clickHeaderLogo() {
      this.clickCartGuide('logo')
    },
    clickHeaderShopping() {
      this.clickCartGuide('continue')
    },
    bindRetainEvent() {
      document.documentElement.addEventListener('mouseleave', (e) => {
        this.layerY = e.clientY || 0
        if (this.layerY <= 0 || Math.abs(this.layerY) <= 15) {
          this.clickCartGuide('move')
        }
      })
    },
    clickCartGuide(type) {
      this.$refs?.RetainDialog?.init(type)
    },
    handleXtraErrCb({ flag }) {
      // 价格接口调用失败，取消/勾选,还原选择
      if (flag) {
        this.assignState({ usedXtraCode: '', xtraProductCodeInDrawer: '' })
        this.changeParamsStatus({
          type: 'caculateData',
          params: {
            save_card_product_code: this.usedXtraCode,
            payment_rights: [
              {
                is_buy: 0,
                product_code: this.usedXtraCode,
                rights_type: 1
              }
            ]
          },
        })
        this.changeXtraUpdateCalcu({ xtraSeleted: false, alreadyReset: true })
      } else {
        this.assignState({ usedXtraCode: this.xtraProductInfo.product_code })
        this.changeParamsStatus({
          type: 'caculateData',
          params: {
            save_card_product_code: this.usedXtraCode,
            payment_rights: [
              {
                is_buy: 1,
                product_code: this.usedXtraCode,
                rights_type: 1
              }
            ]
          },
        })
        this.changeXtraUpdateCalcu({ xtraSeleted: true, alreadyReset: true })
      }
    },
    handleAutoUsePrimeCoupon(cancelAutoUse) {
      if (!!this.is_manual_usedCoupon || cancelAutoUse) return false
      const right_config_list = this.primeProductList?.[this.usedPrimeIndex || 0]?.right_config_list || []
      const right_type_code = right_config_list?.map(item => item.right_type_code)?.join(',') || ''
      let hasFree = right_type_code.indexOf('FREE_SHIPPING_TIMES') != -1
      return hasFree
    },
    filterCouponParams({ scene = '' } = {}) {
      const { applyCouponsSuccess } = this.coupon
      let usedCoupon = []
      if(scene == 'prime') {
        usedCoupon = applyCouponsSuccess.filter(item => [53, 58].includes(item.typeId)) || []
      } else if(scene == 'xtra') {
        usedCoupon = applyCouponsSuccess.filter(item => [55, 59].includes(item.typeId)) || []
      }

      if (usedCoupon.length && this.caculateData.coupon) {
        const filterCoupon = usedCoupon.map(item => item.couponCode.toLowerCase()) || []
        let couponParam = this.caculateData.coupon.split(',')
        let newCouponParam = []
        couponParam.forEach(item => {
          if (!filterCoupon.includes(item)) newCouponParam.push(item)
        })
        this.changeParamsStatus({ type: 'caculateData', params: { 
          coupon: newCouponParam.join()
        } })
      }
    },
    async changeXtraUpdateCalcu({ xtraSeleted, alreadyReset }) {
      this.changeParamsStatus({
        type: 'caculateData', params: {
          save_card_product_code: this.usedXtraCode,
          payment_rights: [
            {
              is_buy: xtraSeleted ? 1 : 0,
              product_code: this.usedXtraCode,
              rights_type: 1
            }
          ]
        }
      })
      this.assignState({
        isCancelXtra: !xtraSeleted
      })
  
      this.updatePayment(this.checkout.default_address)

      // 取消选中产品包过滤之前自动使用的超省卡优惠券
      if(!xtraSeleted && !this.isCouponAutoUse) this.filterCouponParams({ scene: 'xtra' })
     
      this.getUpdateCoupon({}, { isXtra: true, cb: opt => {
        if (!opt && !alreadyReset) this.handleXtraErrCb({ flag: xtraSeleted })
      } })
    },
    async changeXtraPrimeUpDateCalcu({ primeSeleted, alreadyReset, xtraSeleted, cancelAutoUse }){
      // 付费会员
      this.changePrimeUpdateCalcu({ primeSeleted, alreadyReset, cancelAutoUse })
      // 超省卡
      this.changeParamsStatus({
        type: 'caculateData', params: { save_card_product_code: this.usedXtraCode, payment_rights: 
          [{  is_buy: xtraSeleted ? 1 : 0,  product_code: this.usedXtraCode,  rights_type: 1 }]
        }
      })
      this.assignState({
        isCancelXtra: !xtraSeleted
      })
      this.updatePayment(this.checkout.default_address)
      // 取消选中产品包过滤之前自动使用的超省卡优惠券
      if(!xtraSeleted && !this.isCouponAutoUse) this.filterCouponParams({ scene: 'xtra' })
     
      this.getUpdateCoupon({}, { isXtra: true, cb: opt => {
        if (!opt && !alreadyReset) this.handleXtraErrCb({ flag: xtraSeleted })
      } })
    },
    queryProductsByMember() {
      return new Promise(resolve => {
        schttp({
          method: 'POST',
          url: '/api/checkout/queryProductsByMember/get',
          data: {
            scene: 1
          }
        }).then(res => {
          if(res.code == 0 && !!res.info?.result?.length) {
            resolve(res.info?.result[0] || {})
          } else {
            resolve({})
          }
        }).catch(() => {
          resolve({})
        })
      })
    },
    async updateXtra() {
      const xtraData = await this.queryProductsByMember()
      const limitedPayTypeList = xtraData?.auto_renewal_limit_pay_type_list || []
      let xtraProductBagInfo =  xtraData?.product_info_list || [] // 当前所有的产品包数据
      const productCodeArr = xtraProductBagInfo.map(_ => _.product_code) || []
      const xtraAutoPayLimitInfo = this.checkout?.results?.paymentMethods.filter(item => limitedPayTypeList?.includes(item.code) && item?.enabled != 0).map(m => m.code)
      if(xtraAutoPayLimitInfo){
        xtraProductBagInfo = xtraProductBagInfo.filter( item => item.product_activity_type != 1)
      }

      let xtraProductData = {}
      const findSelectedData = xtraProductBagInfo?.find(_ => _.product_code == this.usedXtraCode)
      const isShowSaverRenewStatus = this.checkout?.checkoutBFFAbtInfo?.SheinSaverRenew?.param?.SheinSaverRenewStatus == 1

      // 寻找选择中的
      if (findSelectedData) {
        xtraProductData = findSelectedData
      } else if (isShowSaverRenewStatus) {
        xtraProductData = xtraProductBagInfo[0] // abt是1的话，默认取第一个
      } else if (!isShowSaverRenewStatus) { 
        xtraProductData = xtraProductBagInfo?.find(_ => _.product_activity_type != 1) || {}// abt没有打开的时候，需要取接口返回的产品包数据中第一个 非自动续费产品包
      }
        
      // 更新checkout，防止其他地方使用state中的checkout
      const checkout = {
        ...this.checkout,
        results: {
          ...this.checkout.results,
          xtraProductInfo: {
            limitedPayTypeList,
            xtraProductBagInfo,
            xtraProductData,
          }
        }
      }

      this.assignState({
        checkout,
        xtraProductInfo: xtraProductData,
        xtraProductBagInfo,
        xtraCouponList: [],
      })

      this.updateXtraPaymentsRight()

      if(this.usedXtraCode && !productCodeArr.includes(this.usedXtraCode)) {
        this.assignState({
          'usedXtraCode': ''
        })
        this.changeXtraUpdateCalcu({ xtraSeleted: false })
      }
      // 更新会费券信息
      this.upDateSceneStatusNew()
    },
    handleMallCode(mall_list) {
      let mall_code = []
      mall_list.forEach(item => {
        mall_code.push(item.mall_code)
      })
      return mall_code.join(',')
    },
    handleMallShippingInfo(mallShippingMethods, mallShipIndex) {
      let res = []
      const { mall_list = [] } = mallShippingMethods
      mall_list.forEach(item => {
        const shipIndex = mallShipIndex.find(mall => mall.mall_code == item.mall_code)?.ship_index || 0
        let shipping_method = item.shipping_methods[shipIndex]
        res.push({ mall_code: item.mall_code, shipping_method, index: shipIndex })
      })
      return res
    },
    /**
     *
     * 1、支付方式列表返回有默认支付方式*且*计算价格接口返回下单币种钱包余额为0但有其他币种钱包余额时
     * 2、支付方式列表返回默认支付方式为cod*且*除去COD服务费以外，客户此单应付金额为0时
     *
     */
    initDefaultMethods() {
      this.paymentSuggestion = {}
      this.status.cardPay = ''
      this.caculateData.payment_id = ''
      this.caculateData.payment_code_unique = ''
      let paymentLists = this.checkout?.results?.paymentMethods || []
      // 没有选择默认支付方式 支付方式按照后端顺序排序
      paymentLists = paymentLists.sort((prev, next) => {
        if (prev.sort > next.sort) {
          return 1
        } else if (prev.sort < next.sort) {
          return -1
        } else {
          return 0
        }
      })
      this.checkout.results['paymentMethods'] = paymentLists
    },
    resetDefaultMethods({ code: lastPaymentCode, action = null } = {}) {
      if (!lastPaymentCode) {
        action && action()
        return
      }
      const noUseLastPayment = this.checkout?.results?.noUseLastPayment || []
      if (noUseLastPayment.includes(lastPaymentCode.toUpperCase())) {
        this.initDefaultMethods()
        return
      }

      const caculateInfo = this.checkout?.mall_caculate_info
      if (
        lastPaymentCode.toLowerCase() === 'cod' &&
        +caculateInfo.grandTotalPrice?.amount === 0
      ) {
        this.initDefaultMethods()
      } else {
        const totalPrice = caculateInfo?.wallet?.totalPrice || []
        const totalAmount = totalPrice.reduce((prev, next) => {
          return prev + Number(next?.price?.amount || 0)
        }, 0)
        if (totalAmount !== 0) {
          this.initDefaultMethods()
        } else {
          action && action()
        }
      }
    },
    getSecondLanguage() {
      this.secondLanguage = {
        state: '',
        city: '',
        district: '',
      }
      const { countryId, stateId, cityId, districtId } =
        (this.checkout && this.checkout.default_address) || {}
      if (
        this.SECOND_LANGUAGE_COUNTRIES.includes(+countryId) &&
        (stateId || cityId || districtId)
      ) {
        const cacheKey = `${countryId}_${stateId || '0'}_${cityId || '0'}_${districtId || '0'}`
        if (this.secondLanguageCache[cacheKey]) {
          this.secondLanguage = this.secondLanguageCache[cacheKey]
        } else {
          schttp({
            url: '/api/user/addressbook/stateCitySecondLanguage/get',
            params: {
              countryId,
              stateId,
              cityId,
              districtId,
            },
          }).then((data) => {
            if (data.code == 0 && data.info && data.info.res) {
              const {
                stateSecondLanguage = '',
                citySecondLanguage = '',
                districtSecondLanguage = '',
              } = data.info.res
              this.secondLanguageCache[cacheKey] = {
                state: stateSecondLanguage,
                city: citySecondLanguage,
                district: districtSecondLanguage,
              }
              this.secondLanguage = this.secondLanguageCache[cacheKey]
            }
          }
          )
        }
      }
    },
    updateRiskStr() {
      let riskStr = 1
      if (
        this.checkout.mall_caculate_info &&
        this.checkout.mall_caculate_info.wallet &&
        this.checkout.mall_caculate_info.wallet.risk_res_info &&
        this.checkout.mall_caculate_info.wallet.risk_res_info.risk_decision == 0
      ) {
        riskStr =
          (this.$$refs?.checkoutDialog?.$refs?.mainLogin &&
            this.$$refs?.checkoutDialog?.$refs?.mainLogin.updateRiskStr(
              this.checkout.mall_caculate_info.wallet.risk_res_info
            )) ||
          1
      }
      this.updateCheckoutState({
        key: 'riskStr',
        val: riskStr
      })
    },
    initBankLists(paymentMethods = []) {
      paymentMethods.forEach((payment) => {
        if (payment.bank_list && payment.bank_list.length) {
          const banks = payment.bank_list.map((item = {}) => {
            const { code, name } = item || {}
            return {
              ...item,
              value: code,
              label: name
            }
          })
        
          const backupConfig = this.banksConfig[payment.code] || {}
          const matchSelect = banks.find(item => item.code == backupConfig.bankCode) || {}
          this.updateBanksConfig({
            [payment.code]: {
              list: banks,
              bankCode: matchSelect?.code || '',
              bankCodeText: matchSelect.name || '',
              bankLogo: matchSelect?.logo || '',
              error: false
            }
          })
        }
      })
    },
    updateCalcuMallParam({ selectMallShip, mallInsuranceCheck, isInit }) {
      let switchQsFlag = this.switchQsFlagByAbt
      const mall_params = handleCalcuMallParam({ selectMallShip, mallInsuranceCheck, switchQsFlag, isClickedQsFreight: this.isClickedQsFreight, qsFreightSelected: this.qsFreightSelected })
      
      if(isInit) {
        this.assignState({
          prevCalcuMallParams: mall_params
        })
        if(this.checkout.cacheInfo.mall_switch_qs_flag.used) { // 页面初始化时调用，需应用qs缓存
          // 目前只有单mall有该逻辑
          switchQsFlag = this.checkout?.cacheInfo?.mall_switch_qs_flag?.val?.[0]?.switch_qs_flag || switchQsFlag
        }
      }
      this.changeParamsStatus({ type: 'caculateData', params: { mall_params } })
    },
    // 执行时机需要在获取abt后期，运输方式变更后需要重新触发
    initShipTimeDes(mallShippingMethods) {
      const shipList = JSON.parse(JSON.stringify(mallShippingMethods?.mall_list || []))

      return shipList.map(mall => {
        return { mall_code: mall?.mall_code || '', shipping_methods: mall?.shipping_methods.map(method => {
          // 时效百分比只存在于非全qs的情况下且时效百分比abt开启，isAllQuickShip || !this.timePercentAbt
          let shipping_time_desc = 'none'
          if (this.timePercentAbt) {
            let isShowPercentDes = method.desc_type == 1 ? !!method?.delivery_day_percent_desc : !!method.shipping_day_percent_desc
            let isShowPercentIcon = !!method?.day_percents?.length
            if (isShowPercentDes && isShowPercentIcon) {
              shipping_time_desc = 'desc_and_rule'
            } else if (isShowPercentDes && !isShowPercentIcon) {
              shipping_time_desc = 'desc_only'
            }
          }

          return { transport_type: method.transport_type, shipping_time_desc }
        }) }
      })
    },
    // 初始化事件
    async initEvent () {
      // 店配地址
      if (TRANSPORT_TYPE_SHOP.includes(this.defaultShipping[0]?.shipping_method?.transport_type) && !this.storeData) {
        this.getStoreAddress()
      }

      this.getCouponListNotice()
      this.updateFirstCouponRes({ couponInfo: this.checkout.couponListForOrderInfo || {}, mallCaculateInfo: this.checkout.mall_caculate_info?.originMallCaculData || {} })
      // 已选券赠品刷新页面异常处理
      this.updateCouponGiftCart()
      this.initAdress()
      this.judgePay()
      this.judgeStock()
      // 默认支付
      this.getPaymentSuggestion()

      this.handleNotSupportCodTip(this.checkout.mall_caculate_info)
      this.assignState({
        shipTimeDesList: this.initShipTimeDes(this.checkout.results.mallShippingMethods)
      })
     
      this.getShipppingFree()
      this.paypalSignAnalysis()

      this.initShippingAddStatus()
    },
    // 初始化首屏数据
    initFirstPageData() {
      let mallShipIndex = initMallShipIndex({ 
        mallShippingMethods: this.checkout.results?.mallShippingMethods, 
        has_shop_transit_address: !!this.checkout.address_store_list?.length ? 1 : 0, 
        orderInterceptByAbt: this.orderInterceptByAbt,
      }) || []
      let selectMallShip = this.handleMallShippingInfo(this.checkout.results.mallShippingMethods, mallShipIndex)
      let mallInsuranceCheck = updateMallInsuranceCheck({ insuranceMallData: this.checkout?.results?.insuranceMallData, forcedShippingByAbt: this.forcedShippingByAbt })

      //应用shipping缓存
      if (this.checkout.cacheInfo.mall_transport_type.used) {
        const mall_transport_type = this.checkout.cacheInfo.mall_transport_type.val
        const { mall_list = [] } = this.checkout.results.mallShippingMethods || {}

        mallShipIndex = mallShipIndex.map(mall => {
          const filterTransType = mall_transport_type.find(item => item.mall_code == mall.mall_code) || {}
          const filterShippingMethods = mall_list.find(item => item.mall_code == mall.mall_code) || {}
          const cacheIndex = filterShippingMethods?.shipping_methods.findIndex(method => method.transport_type == filterTransType.transport_type)
          if (cacheIndex != -1) {
            return Object.assign({}, mall, { ship_index: cacheIndex })
          } else {
            return mall
          }
        })
        selectMallShip = this.handleMallShippingInfo(this.checkout.results.mallShippingMethods, mallShipIndex)
      }


      this.updateCalcuMallParam({ selectMallShip, mallInsuranceCheck, isInit: true })
      this.assignState({
        defaultShipping: selectMallShip,
        mallShippingMethods: this.checkout.results.mallShippingMethods,
        mallInsuranceCheck,
        primeProductList: this.checkout?.results?.primeProductInfo?.primeProductList || [],
        lowPrimeProduct: JSON.parse(JSON.stringify(this.checkout?.results?.primeProductInfo?.primeProductList?.filter(item => +item.product_price_info?.special_price > 0) || [])).sort((a, b) =>  +a.product_price_info?.special_price - +b.product_price_info?.special_price)[0] || {},
        xtraCouponList: this.checkout.results?.xtraCouponList?.couponList || [],
        xtraAggregatedCouponList: this.checkout.results?.xtraCouponList?.aggregatedCouponList || [],
        xtraProductBagInfo: this.checkout.results?.xtraProductInfo?.xtraProductBagInfo || [],
        xtraProductInfo: this.checkout.results?.xtraProductInfo?.xtraProductData || {},
        xtraAllProductBagInfo: this.checkout.results?.xtraAllProductBagInfo || {},
        xtraDiscountInfo: this.checkout.results?.xtraDiscountInfo?.payment_right || {},
        xtraDisplayCouponInfo: this.checkout.results?.xtraDisplayCouponInfo?.data || {},
        primeSavingSummary: this.checkout.results?.primeSavingSummary?.savingSummary || {},
        togetherBuy: this.checkout?.togetherBuy || {},
        firstCoupon: this.checkout.results.firstCoupon,
        isPrimeMember: this.checkout.isPrimeMember || false,
        defaultSelectTokenPay: this.checkout?.nowDefaultTokenByBin || {},
        handlerSelectBin: this.checkout?.nowDefaultTokenByBin?.card_bin || '',
        installmentSelectBin: this.checkout?.defaultTokenByMallCal?.card_bin || '', // 计价接口返回的最优 token bin
        installmentSelectToken: this.checkout?.defaultTokenByMallCal || {}, // 计价接口返回的最优 token bin
      })

      this.upDateSceneStatusNew()

      if (this.checkout?.address_store_list?.length) {
        let address_store_list = this.checkout?.address_store_list?.[0] || ''
        const countryId = this.checkout.default_address.countryId
        if (
          countryId == 209 &&
          (!address_store_list || address_store_list.status != '1')
        ) {
          this.assignState({
            storeData: ''
          })
        } else {
          this.assignState({
            storeData: address_store_list
          })
        }
      }

      // 首次添加地址
      if (this.caculateData.address_id == 0) {
        this.checkout.mall_caculate_info = this.checkout.results.carts.data
        this.checkout.mall_caculate_info.isSupportCod = 1
        this.checkout.mall_caculate_info.is_display = 1
      }
    },
    initShippingAddStatus() {
      if (window?.sessionStorage?.getItem) {
        const showStatus = sessionStorage.getItem('checkout_shipping_additem_success')
  
        this.updateShippingAddStatusInfo({
          getStatus: true,
          addStatus: showStatus == 1
        })
      }
    },
    paypalSignAnalysis() {
      if (this.checkout?.results?.paymentMethods?.length) {
        this.checkout.results.paymentMethods.forEach((item) => {
          if (
            item.enabled == 1 &&
            item.code == 'PayPal-GApaypal' &&
            item.paymentSignUp &&
            item.paymentSignUp.length
          ) {
            this.paypalGaVaultBi &&
              this.paypalGaVaultBi('expose_paypal_vaulting')
          }
        })
      }
    },
    async getShipppingFree() {
      //获取埋点数据
      if (this.checkout.results.carts.data) {
        const cartData = this.checkout.results.carts.data
        await this.getShippingFreeMall()
        const shippingContentInfo = this.shippingContentInfo

        const mallShippingAnalysisParam = handleMallShippingAnalysisParam({ cartInfo: cartData, mallShippingInfo: shippingContentInfo })
        let mallShipParam = handleMallShippingAnalysis(mallShippingAnalysisParam)

        if (!shippingContentInfo) {
          sa('send', 'pageEnter', { start_time: new Date().getTime() })
          return
        }

        //活动id和是否满足
        let promotion_method = ''
        let page_fromcart = ''
        let checkout_from = ''
        let checkout_total = ''
        if (window.sessionStorage?.getItem) {
          try {
            let cartPoints = window.sessionStorage.getItem('cartPoints')
            if (cartPoints) {
              cartPoints = JSON.parse(cartPoints)
              let cartSa = cartPoints.sa
              if (cartSa) {
                promotion_method = cartSa.promotion
                page_fromcart = cartSa.page_from

                checkout_from = cartSa.click_from ? `${cartSa.click_from}\`${cartSa.button_name}` : ''
                checkout_total = cartSa.checkout_total
              }
              if(!!cartPoints.checkout_from) checkout_from = cartPoints.checkout_from
            }
          } catch (e) {
            console.log(e)
          }
        }


        let pageParam = {
          promotion: promotion_method,
          //取购物车的page_from
          page_fromcart,
          checkout_total,
          checkout_from,
          mall_shipping_method: mallShipParam?.mall_shipping_method || '',
          mall_origin_price: mallShipParam?.mall_origin_price || '',
          mall_is_fullshippingactivity: mallShipParam?.mall_is_fullshippingactivity || '',
          mall_step_fee: mallShipParam?.mall_step_fee || '',
          mall_charging_type: mallShipParam?.mall_charging_type || '',
          mall_num_price: mallShipParam?.mall_num_price || '',
        }

        Object.assign(SaPageInfo.page_param, pageParam)
        if(!SaPageInfo.page_param.page_from) {
          let page_from_key = ''
          if (window.sessionStorage) {
            let sessionVisitTrajectory = window.sessionStorage.getItem('visit_trajectory')
            let visitTrajectory = JSON.parse(sessionVisitTrajectory)
            if (visitTrajectory && visitTrajectory.length > 1) {
              page_from_key = visitTrajectory[visitTrajectory.length - 1]
            }
          }
          SaPageInfo.page_param.page_from = page_from_key
        }
      }
      sa('send', 'pageEnter', { start_time: new Date().getTime() })
    },
    showTip: function (text) {
      this.tipModal.show = true
      this.tipModal.text = text
      this.tipModal.showBtn = false
      setTimeout(() => {
        this.tipModal.show = false
        this.tipModal.text = ''
      }, 2500)
    },
    filterPaymentByAbt({ paymentMethods }) {
      let newPayments = paymentMethods?.concat?.([]) || []
      const paymentAbtInfo = this.checkout?.checkoutBFFAbtInfo?.['PaymentABT'] || {}
      if (
        paymentAbtInfo &&
        paymentAbtInfo.p &&
        paymentAbtInfo.p.indexOf('paymentoff=') > -1
      ) {
        let abtPayments = paymentAbtInfo.p.split('=')[1]
        if (abtPayments) {
          abtPayments = abtPayments.split(',')
          if (abtPayments.length) {
            let filterPayment = newPayments.filter(
              (item) => !abtPayments.includes(item.code)
            )
            // 如果过滤出来的都不可用，则取消过滤，所以至少有一个支付可用
            if (filterPayment.some((item) => item.enabled == 1)) {
              newPayments = filterPayment
            }
          }
        }
      }
      return newPayments
    },
    //boleto支付邮箱校验
    checkEmail: function () {
      var val = this.emailTips.val?.trim() || ''
      if (val) {
        var reg =
          /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/
        if (reg.test(val)) {
          this.emailTips.err = ''
          this.emailTips.val = val
          this.emailTips.show = false
          this.createOrder({ checkEmail: true })
        } else {
          this.emailTips.err = this.language.SHEIN_KEY_PC_14427
        }
      } else {
        this.emailTips.err = this.language.SHEIN_KEY_PC_14426
      }
    },
    applyGiftcard: function () {
      var _this = this
      if (!this.giftcard.num?.trim()) {
        this.giftcard.err = this.language.SHEIN_KEY_PC_15574
        //ada聚焦提示
        this.$nextTick(() => {
          document.querySelector('.c-input-giftcard input')?.focus()
        })

        return
      } else if (!this.giftcard.pin?.trim()) {
        this.giftcard.err = this.language.SHEIN_KEY_PC_15575
        //ada聚焦提示
        this.$nextTick(() => {
          document.querySelector('.c-input-pin input')?.focus()
        })
        return
      }
      this.giftcard.err = ''
      this.giftcard.loading = true
      this.giftcard.apply = true

      this.updateTotal(
        this.caculateData,
        function (data) {
          _this.status.loading.twice = 0
          
          daEventCenter.triggerNotice({
            daId: '1-11-1-141',
            extraData: {
              result: data.code == 0 ? '1' : '0'
            }
          })

        },
        { type: 'giftcard', notTips: true }
      )
    },
    cancelGiftCard: function () {
      var _this = this
      this.giftcard.apply = false
      this.giftcard.pin = ''
      this.updateTotal(
        this.caculateData,
        function () {
          _this.status.loading.twice = 0
        },
        { type: 'giftcard' }
      )
    },
    giftCardRemove() {
      this.cancelGiftCard()
      this.giftCardLimit.show = false
      this.giftCardLimit.clickConfirm = false
      daEventCenter.triggerNotice({
        daId: '1-11-1-41',
      })
    },
    giftCardConfirm() {
      this.giftCardLimit.verify = 1
      this.giftCardLimit.show = false
      this.giftCardLimit.clickConfirm = true
      this.createOrder()
    },
    // 地址组件初始化
    initAdress: function () {
      if (this.status.hasAddress == 0) {
        if (this.$refs.firstAddressVue) {
          this.$refs.firstAddressVue.updateUI('add')
          typeof GB_analysis_obj != 'undefined' &&
            GB_analysis_obj.exposeFirstAddress()
          this.sendAddressAnalysis(
            {
              page_type: 'add',
              activity_from: 'checkout_active',
            },
            { type: 'expose' }
          )
        }
      }
    },
    getSupplement() {
      schttp({
        url: '/api/checkout/supplement/get',
      }).then((json) => {
        this.assignState({ firstCoupon: json.firstCoupon || {} })
        this.getFirstUseCoupon()
      })
    },
    judgePay: function () {
      // 受政策影响，yandex暂时隐藏
      if (this.lang == 'ru') {

        var qiwiCountry = ['178', '110', '20']
        if (
          qiwiCountry.indexOf(this.checkout.default_address.countryId) != -1
        ) {
          this.status.pay.qiwiShow = true
        } else {
          this.status.pay.qiwiShow = false
          if (this.status.cardPay == 'worldpay-qiwi') {
            this.caculateData.payment_id = ''
          }
        }
      }
    },
    handleDefauShippingInfo({ val, method, mall_code, index }) {
      if (!mall_code) return
      return val.map(item => {
        if (item.mall_code == mall_code) {
          return Object.assign({}, item, { shipping_method: method, index })
        } else {
          return item
        }
      })
    },
    handleSelectShipMethod({ mallCode, index }) {
      const filterMallShippingMethods = this.mallShippingMethods?.mall_list?.find(mall => mall.mall_code == mallCode) || {}
      const filterShippingMethod = filterMallShippingMethods?.shipping_methods || []
      return filterShippingMethod[index] || {}
    },
    changeSelectShip({ item, index, mallCode, prevMallParams }) {
      this.assignState({
        defaultShipping: this.handleDefauShippingInfo({ val: this.defaultShipping, method: item, mall_code: mallCode, index: index })
      })

      const mall_params = prevMallParams.map(mall => {
        if (mall.mall_code == mallCode) {
          return Object.assign({}, mall, { transport_type: item.transport_type, switch_qs_flag: handleCurrShipSwitchQsFlag({ switchQsFlagByAbt: this.switchQsFlagByAbt, shippingMethod: item, isClickedQsFreight: this.isClickedQsFreight, qsFreightSelected: this.qsFreightSelected }) })
        } else {
          return mall
        }
      })
      this.changeParamsStatus({ type: 'caculateData', params: { mall_params } })
    },
    changeAllShip({ transport_type, prevMallParams, mallCode, scence = 0 }) {
      let mallShipIndex = []
      if (![0, 2].includes(scence)) {
        mallShipIndex = initMallShipIndex({ 
          mallShippingMethods: this.mallShippingMethods, 
          has_shop_transit_address: !!this.storeData ? 1 : 0, 
          orderInterceptByAbt: this.orderInterceptByAbt, 
          prevMallParams: prevMallParams ?? [],
        })
      }
      if([2].includes(scence)) {
        // 切换店配/宅配，切换所有mall运输方式
        mallShipIndex = handleMallShipIndex({ 
          mallShippingMethods: this.mallShippingMethods, 
          orderInterceptByAbt: this.orderInterceptByAbt, 
          transportType: transport_type, 
          mallCode,
        })
      }

      const selectMallShip = this.handleMallShippingInfo(this.mallShippingMethods, mallShipIndex)
      this.updateCalcuMallParam({ selectMallShip, mallInsuranceCheck: this.mallInsuranceCheck })
      this.assignState({ defaultShipping: selectMallShip })
    },
    /**
     * 选择运输方式
     * scene
     * -1: 回退先前运输方式
     * 0: 切换当前mall运输方式
     * 1: 正常切换或者编辑地址重新获取接口
     * 2: 所有mall切换 店配/宅配运输方式
     * 3: 运输方式限制前置重新获取接口
     */
    clickShipping({ item, index, mallCode, transport_type, scence = 0, modifyCart }) {
      // 重置切店配运输方式新增店配地址的状态数据，防止异常选中
      if (mallCode != this.selectStoreAddressShippingInfo?.mallCode) {
        this.assignState({
          selectStoreAddressShippingInfo: {
            shippingMethod: {},
            mallCode: '',
            isAdd: false
          }
        })
      }
      if (mallCode != this.forceUpdateShippingMethod?.mallCode) {
        this.assignState({
          forceUpdateShippingMethod: {
            shippingMethod: {},
            mallCode: '',
            isUpdate: false
          }
        })
      }

      let prevMallParams = this.caculateData.mall_params
      // 运输方式限制时需要先替换成即将选中的;宅店配切换时先替换成即将选中的;强制刷新运输方式是替换成即将选中的
      const specialSelect = this.selectStoreAddressShippingInfo?.isAdd || this.forceUpdateShippingMethod?.isUpdate
      if(scence == -1) {
        prevMallParams = this.prevCalcuMallParams
      } else if (scence == 3 || specialSelect) {
        prevMallParams = prevMallParams?.map(mall => {
          if(mall.mall_code == mallCode) return Object.assign({}, mall, { transport_type: transport_type, switch_qs_flag: handleCurrShipSwitchQsFlag({ switchQsFlagByAbt: this.switchQsFlagByAbt, shippingMethod: item, isClickedQsFreight: this.isClickedQsFreight, qsFreightSelected: this.qsFreightSelected }) })
          return mall
        })
      }

      // 切换其中一种运输方式 
      if (!scence) {
        this.changeSelectShip({ item, index, mallCode, prevMallParams })
      } else {
        this.changeAllShip({ transport_type, prevMallParams, mallCode, scence })
      }

      // 运输方式是否为店配
      const isStoreAddress = TRANSPORT_TYPE_SHOP.includes(transport_type)
      // 运输方式为店配，且店配地址不存在时，不走计价
      if (!isStoreAddress || this.storeData?.status == 1) {
        this.changeShipUpdateCalcu({ transport_type, scence, modifyCart })
      }

      this.assignState({
        selectStoreAddressShippingInfo: {
          ...this.selectStoreAddressShippingInfo,
          isAdd: false
        },
        forceUpdateShippingMethod: {
          ...this.forceUpdateShippingMethod,
          isUpdate: false
        },
      })
    },
    changeShipUpdateCalcu({ transport_type, scence, modifyCart }) {
      let cb = res => {
        // cod支付，后端控制强制转换货币逻辑
        if (
          res?.code == '0' &&
          res?.info?.changeCodLocalCurrency?.isChange == 1 &&
          this.currencyCode != res?.info?.changeCodLocalCurrency?.codLocalCurrency
        ) {
          this.updateCheckoutState({
            key: 'codCurrencyTip',
            val: this.template(res.info.changeCodLocalCurrency.codLocalCurrency, this.language.SHEIN_KEY_PC_17533)
          })

          // cod支付后端转换货币后前端跟随转换对应货币
          this.updateTotal(this.caculateData)

        } else {
          this.updateCheckoutState({
            key: 'codCurrencyTip',
            val: ''
          })
        }

        if((scence == 1 || scence == 3) && res.code == 0) {
          this.judgeStock()
          //当险种(insurance_type)为退货险，且所有商品不可退货(isDisplayShippingInsurance)时，取消勾选险种
          this.handleUseInsurance({ mallInsuranceCheck: this.mallInsuranceCheck, insuranceMallData: this.checkout?.results?.insuranceMallData, updataCalcu: true })
        }

        if(res.code != 0) {
          if(modifyCart) {
            this.resetMallCacuTotal()
            return
          }

          if(!scence && (res.code == '300628' || res.code == '301109')) { // 券无法抵扣当前运输方式
            this.clickShipping({ scence: -1 })
          }
        }
      }

      if(gbCommonInfo?.SHOP_TRANSIT?.site?.includes(gbCommonInfo.SiteUID)) {
        this.caculateData.payment_code_unique = ''
        this.caculateData.payment_id = ''
        if (TRANSPORT_TYPE_SHOP.includes(transport_type)) {
          // 台湾/法国店配地址
          this.getStoreAddress({}, () => {
            this.updatePayment(this.checkout.default_address)
            this.updateTotal(this.caculateData, cb, { type: modifyCart ? 'modifyCart' : '' })
          })
        } else {
          this.updatePayment(this.checkout.default_address)
          this.updateTotal(this.caculateData, cb, { type: modifyCart ? 'modifyCart' : '' })
        }
      } else {
        this.updateTotal(this.caculateData, cb, { type: modifyCart ? 'modifyCart' : '' })
      }
    },
    // 选择运费险
    clickInsurance: function (mall_code) {
      let mallInsuranceCheck = this.mallInsuranceCheck
      mallInsuranceCheck = mallInsuranceCheck.map(insurance => {
        if (insurance.mall_code == mall_code) {
          return Object.assign({}, insurance, { use_insurance: insurance.use_insurance == 1 ? 0 : 1 })
        } else {
          return insurance
        }
      })
      this.assignState({ mallInsuranceCheck: mallInsuranceCheck })
      this.updateCalcuMallParam({ selectMallShip: this.defaultShipping, mallInsuranceCheck })
      this.updateTotal(this.caculateData)
    },
    // isChangeCurrency() {
    //   let result = false
    //   return result
    // },
    // 选择支付方式
    clickPayment: function (id, code, item, paymentCoupon, { iSautoPay, Index, isdont, isDoNeedMallCalculateTotal, afterCallBack } = { iSautoPay: '', Index: 0, isdont: false, isDoNeedMallCalculateTotal: false, afterCallBack: () => {} }, idx) {
      // console.log('====================clickPayment====================', window?.appEventCenter)
      // 切换包逻辑，自动续费包情况切换到非快捷，需要弹出切包弹窗
      const { limitedPayTypeList, currentProduct, currentProductCycleDays, caculateData } = this.productAutoInfo || {}
      const { xtraAutoPayLimitInfo, currentXtraIsAuto, sheinSaverRenewAbt } = this.xtraAutoController || {}

      const specialPayment = ['PayPal-GApaypal', 'afterpay-card'].includes(code)
      const specialPaypal = this.paypalGaVault?.type == 0
      const specialAftepay = this.afterpayVault?.type == 0 || !this.afterpayVault?.switch
      let currentAutoProductDwType = 'prime'
      
      let specialResult = false
      if(code == 'PayPal-GApaypal'){
        specialResult = specialPaypal
      } else {
        specialResult = specialAftepay
      }

      const limitedPayTypeListResult = {
        currentPrime: limitedPayTypeList.includes(caculateData?.payment_code_unique), // 当前支付方式是否是快捷
        nextPrime: !limitedPayTypeList.includes(code), // 要点击的支付方式不能是快捷
        currentXtra: xtraAutoPayLimitInfo.includes(caculateData?.payment_code_unique),
        nextXtra: !xtraAutoPayLimitInfo.includes(code),
        currentPrimeIsAuto: currentProduct?.product_activity_type == 1 && !!currentProductCycleDays,
        currentXtraIsAuto
      }
      let nextPaymentPrimeResult = specialPayment ? specialResult : limitedPayTypeListResult.nextPrime
      let nextPaymentXtraResult = specialPayment ? specialResult : limitedPayTypeListResult.nextXtra
      if(specialPayment && !limitedPayTypeList.includes(code)){
        nextPaymentPrimeResult = true
      }
      if(specialPayment && !xtraAutoPayLimitInfo.includes(code)){
        nextPaymentXtraResult = true
      }

      const primeResult = limitedPayTypeListResult.currentPrimeIsAuto && nextPaymentPrimeResult
      const xtraResult = !!sheinSaverRenewAbt && limitedPayTypeListResult.currentXtraIsAuto && nextPaymentXtraResult

      let limitPaymentsSwitchProduct = primeResult || xtraResult
      currentAutoProductDwType = primeResult && xtraResult ? 'common' : primeResult ? 'prime' : 'xtra'
      // 支付方式是否符合调起切包弹窗 1.当前支付是快捷，当前包是自动续费包 2.点击的支付方式是非快捷 3.针对PayPal-GApaypal、afterpay-card额外处理
      if ( !iSautoPay && limitPaymentsSwitchProduct && item?.enabled != 0 ) {
        window?.checkoutEventCenter.emit('payment-choosepayment-event', { id, code, item, paymentCoupon, currentAutoProductDwType })
        isDoNeedMallCalculateTotal = true
      }

      var _this = this
      if (this.isAllBusiness) return
      if (GooglePay.googlePayMethods.includes(code) && !this.googleValid) return

      if (item && item.enabled != 1) return

      if (code.toLowerCase() == 'cod') {
        if (!this.checkout.mall_caculate_info.isSupportCod) {
          return
        }
      }

      if (this.status.loading.twice) return

      var _item = this.checkout.results.paymentMethods.filter(
        (item) => item.code == this.caculateData.payment_code_unique
      )
      //记录切换前的支付方式
      this.assignState({ lastSelectePayment: _item[0] || {} })

      this.status.loading.twice = 1

      this.caculateData.payment_id = id
      this.caculateData.payment_code_unique = code
      this.status.error.nopayment = 0
      this.status.cardPay = code

      this.showWidgetForm = 1

      setTimeout(function () {
        _this.status.loading.twice = 0
      }, 300)

      // 切包逻辑，切换完支付方式，再通知包更新
      if(iSautoPay) {
        // return window?.checkoutEventCenter.emit('handle-prime-select-prod', { Index, isdont })
        return afterCallBack && afterCallBack({ Index, isdont })
      }

      if (isDoNeedMallCalculateTotal) return // 不需要触发计价

      //如果是通过支付方式优惠券选择支付方式，则先不执行价格计算，由使用优惠券来执行
      if (paymentCoupon) return
      const { folded_position, originFoldedPosition } = this.checkout?.results || {}

      daEventCenter.triggerNotice({
        daId: '1-11-1-132',
        extraData: {
          payment_method: code,
          is_vaulting: code == 'PayPal-GApaypal' ? (!!this.ppgvAccountInfo?.signUpEmail ? '1' : '0') : '-',
          is_folded: (folded_position == -1 && idx >= originFoldedPosition) ? 1 : 0,
          is_pay_promotion: this.checkout.mall_caculate_info?.onlinePayDiscountInfo
            ?.onlinePayDiscountValue?.[item.code] ? 1 : 0,
          is_default: (item?.enabled == 1 && item.last_payment) ? 1 : 0,
          default_type: item.last_payment ? 1 : 0,
          sequence: idx + 1
        },
      })
      if (this.isChooseFoldedPayment) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-73',
        })
      }

      this.updateTotal(
        { ...this.caculateData, isClickPayment: true },
        (res) => {
          if (res && res.code == 0 && res.info) {
            const info = res.info
            //cod支付，后端控制强制转换货币逻辑
            if (
              info.changeCodLocalCurrency &&
              info.changeCodLocalCurrency.isChange == 1 &&
              this.currencyCode != info.changeCodLocalCurrency.codLocalCurrency
            ) {
              if (item && item.isDefaultMethod) {
                // 下单币种不在默认支付方式支持的币种列表范围时，取消该支付方式默认勾选逻辑，改由用户手动选择支付方式；也不执行强制币种转换逻辑；
                this.initDefaultMethods()
              } else {
                this.updateCheckoutState({
                  key: 'codCurrencyTip',
                  val: this.template(info.changeCodLocalCurrency.codLocalCurrency, this.language.SHEIN_KEY_PC_17533)
                })

                //cod支付后端转换货币后前端跟随转换对应货币
                this.updateTotal(this.caculateData)
              }
            } else {
              this.updateCheckoutState({
                key: 'codCurrencyTip',
                val: ''
              })
            }
          }
        }
      )
    },
    // 选择地址
    clickSelectAddress: function (index, isFirst = false) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-130',
      })

      var self = this
      this.checkout.default_address = this.checkout.address_list[index]
      this.assignState({
        checkout: {
          ...this.checkout,
          default_address: this.checkout.address_list[index]
        }
      })
      this.caculateData.address_id = this.checkout.default_address.addressId

      // 存在下单限制前置 - 修改地址逻辑时，宅配地址更新后，更新运输方式后续逻辑
      if (this.shippingLimitInfo?.checkUpdateShippingMethod) {
        this.assignState({ showAddressDialog: false })
        window.checkoutEventCenter.emit('update-shipping-method', {
          address: this.checkout?.default_address || {},
          shippingMethod: this.shippingLimitInfo?.shippingMethod || {},
          mallCode: this.shippingLimitInfo?.mallCode || '',
          isLimit: 1
        })
      } else {
        this.updateShipping({
          countryId: this.checkout.default_address.countryId,
          address_id: this.checkout.default_address.addressId
        },
        isFirst ? () => {
          this.$nextTick(() => {
            // 首单优惠
            this.getSupplement()
          })
        } : () => {}
        )
      }

      this.updatePayment(this.checkout.default_address)

      this.isNeedAgreenPrivacy()

      this.status.chooseAddress = 1
      this.status.selectIndex = index

      this.$nextTick(function () {
        self.judgePay()
      })
    },
    // 更改默认地址
    updateDefaultAddress: function (address) {
      this.checkout.default_address = address
      this.caculateData.address_id = this.checkout.default_address.addressId

      // 存在下单限制前置 - 修改地址逻辑时，宅配地址更新后，更新运输方式后续逻辑
      let shippingMethod = {}
      let mallCode = ''
      let isLimit = 0
      if(this.shippingLimitInfo?.checkUpdateShippingMethod) {
        this.assignState({ showAddressDialog: false })
        shippingMethod = this.shippingLimitInfo?.shippingMethod || {}
        mallCode = this.shippingLimitInfo?.mallCode || ''
        isLimit = 1
      }
      window.checkoutEventCenter.emit('update-shipping-method', {
        address: this.checkout?.default_address || {},
        shippingMethod,
        mallCode,
        isLimit,
      })

      this.updatePayment(address)

      this.status.chooseAddress = 1
      this.$nextTick(function () {
        this.judgePay()
      })
    },
    clickAddressTips() {
      this.clickEditAddress(-1, this.checkout.default_address, 1)
      typeof GB_analysis_obj != 'undefined' && GB_analysis_obj.clickConstomsinfoGuide()
    },
    // 添加地址
    clickAddAddress: function () {
      if (parseInt(this.checkout.address_total) >= 20) {
        this.status.addressAddTips = true
        this.status.tipsText = this.language.SHEIN_KEY_PC_15997
        return
      }
      if (!this.$refs?.addressCom?.$refs?.addressVue) {
        return
      }
      this.status.addressCompOpen = true
      this.$nextTick(() => {
        this.$refs?.addressCom?.$refs?.addressVue?.updateUI('add')
      })
      typeof GB_analysis_obj != 'undefined' &&
        GB_analysis_obj.exposeAddressFrom('add')
      this.sendAddressAnalysis(
        {
          page_type: 'add',
          activity_from: 'checkout_active',
        },
        { type: 'expose' }
      )
    },
    // 编辑地址 type为1时，展示护照信息
    clickEditAddress(index, address, type) {
      if (!this.$refs?.addressCom?.$refs?.addressVue) {
        return
      }
      this.status.editIndex = index
      this.status.addressCompOpen = true
      this.$nextTick(() => {
        this.$refs?.addressCom?.$refs?.addressVue?.updateUI('edit', {
          ...(toRaw(address)),
          new_tel: address.tel
        }, () => {
          type === 1 && this.$refs?.addressCom?.$refs?.addressVue?.scrollPassport?.()
        })
      })

      typeof GB_analysis_obj != 'undefined' &&
        GB_analysis_obj.exposeAddressFrom('edit')
      this.sendAddressAnalysis(
        {
          page_type: 'edit',
          activity_from: 'checkout_active',
        },
        { type: 'expose' }
      )
    },
    //删除地址
    handleDelete: function (item) {
      this.addressId = item.addressId
      this.status.delete = true
    },
    confirmDel: function () {
      var addressId = this.addressId
      
      fetchDelAddressBookApi({
        addressId
      }).then((res) => {
        if (res.code == 0) {
          this.sourceGray = this.sourceGray.filter(function (item) {
            return item.addressId != addressId
          })
          this.status.delete = false
          this.checkout.address_total--
        }
      })
    },
    //筛选出要显示的置灰地址
    filteGrayAddress: function () {
      this.checkout.addressListGray.forEach((item) => {
        if (this.lang == 'uk' && item.countryId == '104')
          this.sourceGray.push(item)
        if (
          ['ma', 'ar', 'ru', 'it', 'de', 'es'].includes(this.lang) &&
          ['100', '137'].includes(item.countryId)
        )
          this.sourceGray.push(item)
      })
    },
    // 默认选择支付
    getPaymentSuggestion: function () {
      // console.log('jojo >>>>>>')
      if (
        this.checkout &&
        this.checkout.results &&
        this.checkout.results.paymentMethods
      ) {
        let lastPayment = this.checkout.results.paymentMethods.filter(
          (item) =>
            item.last_payment &&
            item.last_payment == 1 &&
            item.is_display == 1 &&
            item.enabled == 1
        )
        if (lastPayment && lastPayment[0]) {
          let item = lastPayment[0]
          // 下单币种不在默认支付方式支持的币种列表范围时，取消该支付方式默认勾选逻辑，改由用户手动选择支付方式；也不执行强制币种转换逻辑；
          const { isChangeCurrency } = handleSwitchCurrency({
            currencySupport: this.currencySupport,
            currency: this.checkout.mall_caculate_info?.orderCurrency?.code || '',
            countryCode: this.checkout.default_address?.value || '',
            paymentCode: item.code,
          })
          if (isChangeCurrency) return false

          if (item.code == 'ebanx-oxxo' && !this.status.pay['ebanx-oxxo']) {
            return false
          }
          this.paymentSuggestion = item
          let new_item = JSON.parse(JSON.stringify(item))
          new_item.isDefaultMethod = true // 表示初始化的默认支付方式，用于判断默认支付方式为cod的话是否需要选中

          if (Object.keys(this.checkout?.tempNewLastPayment || {}).length > 0) {
            this.resetDefaultMethods({
              code: this.checkout?.tempNewLastPayment?.code,
              action: () => {
                this.clickPayment(this.checkout?.tempNewLastPayment?.id, this.checkout?.tempNewLastPayment?.code, this.checkout?.tempNewLastPayment)
              },
            })
          } else {
            this.resetDefaultMethods({
              code: new_item.code,
              action: () => {
                this.clickPayment(new_item.id, new_item.code, new_item)
              },
            })
          }
        }
      }
    },
    pointsCaculate: function (opt) {
      // 已使用，采用remove操作
      if (opt && opt.isUse) {
        this.updateCheckoutState({
          key: 'pointsData',
          val: ''
        })
        this.caculateData.auto_use_point = 0
        this.caculateData.auto_use_point_threshold = ''
        this.caculateData.points = 0

        daEventCenter.triggerNotice({
          daId: '1-11-1-140',
        })

        return false
      }

      if (opt && opt.byUser) {
        this.caculateData.auto_use_point = 0
        this.caculateData.auto_use_point_threshold = ''
      }

      var _pointsData = Number((this.pointsData + '').trim())

      if (_pointsData > 0) {
        const maxAvailablePoint = this.IS_RW ? this.checkout.mall_caculate_info?.maxAvailablePoint : this.checkout.mall_caculate_info?.maxAvailablePoint2
        if (_pointsData > maxAvailablePoint) {
          _pointsData = maxAvailablePoint
        }
        this.caculateData.points = _pointsData
        this.status.error.points = 0
      } else {
        this.status.error.pointsCode = 0
        this.caculateData.points = 0
        this.status.error.points = 1

        //ada聚焦提示
        this.$nextTick(() => {
          document.querySelector('.c-input-points input')?.focus()
        })

        if (this.checkout.mall_caculate_info.pointPrice.amount <= 0) {

          if(!opt.isUseAll && opt.byUser) {
            daEventCenter.triggerNotice({
              daId: '1-11-1-139',
              extraData: {
                result: 0
              }
            })
          }
          return true
        }

      }

      return false
    },
    walletCaculate: function (opt) {
      // 已使用，采用remove操作
      if (opt && opt.isUse) {
        this.updateCheckoutState({
          key: 'walletData',
          val: ''
        })
        this.caculateData.use_wallet = 0
        this.caculateData.use_wallet_amount = ''
        this.status.isWalletAmoutHit = true

        daEventCenter.triggerNotice({
          daId: '1-11-1-135',
        })

        return false
      }

      let _walletData = Number((this.walletData + '').trim()),
          _trunc = this.checkout.mall_caculate_info.orderCurrency.trunc, // 最小使用单位
          _decimal_place = this.checkout.mall_caculate_info.orderCurrency.decimal_place // 小数点位数

      if (_walletData > 0) {
        // 在使用递交时，将输入数字按最小使用单位取整
        let _int = (_walletData + 'e+' + _decimal_place) * 1
        let _unit = _int % _trunc

        if (_unit > 0) {
          let _dis = _int - _unit
          _walletData = (_dis + 'e-' + _decimal_place) * 1

          this.updateCheckoutState({
            key: 'walletData',
            val: _walletData
          })
        }

        // 超过最大可用金额，则自动转成最大可用金额，传值0为使用全部可用钱包金额
        if (
          _walletData >= this.checkout.mall_caculate_info.wallet.subtractPrice.amount
        ) {
          _walletData = this.checkout.mall_caculate_info.wallet.subtractPrice.amount
        }

        this.caculateData.use_wallet = 1
        this.caculateData.use_wallet_amount = _walletData
        this.status.error.wallet = 0
      } else {
        this.caculateData.use_wallet = 0
        this.status.error.walletCode = 0
        this.caculateData.use_wallet_amount = ''
        this.status.error.wallet = 1

        //ada聚焦提示
        this.$nextTick(() => {
          document.querySelector('.c-input-wallet input')?.focus()
        })

        if (this.checkout.mall_caculate_info.walletPrice.amount <= 0) {
          return true
        }
        return true
      }

      return false
    },
    // 积分/钱包
    clickApply (opt) {
      let self = this

      if (opt.type == 'wallet' && this.riskStr == 2) {
        this.$refs?.checkoutDialog?.$refs?.mainLogin?.dialogUpdateInfo({
          scene: 'view_wallet_balance',
          info: this.checkout.mall_caculate_info.wallet.risk_res_info,
        })
        this.setShowRiskDialog(true)
        return
      }
      this.changeParamsStatus({ type: 'codConfirm', params: { applyType: opt.type } })
   
      if (opt.type == 'points') {
        if (this.pointsCaculate(opt)) return
      } else if (opt.type == 'wallet') {
        if (this.walletCaculate(opt)) return
      }

      if (this.status.loading[opt.type]) return
      self.status.loading[opt.type] = 1
      self.status.success[opt.type] = 0
      self.status.error[opt.type + 'Code'] = 0

      self.updateTotal(self.caculateData, function (res, isCod) {
        self.status.loading[opt.type] = 0
        if (res.code == 0) {
          //取消优惠券后马上又取消积分使用，避免同时取消时价格计算触发两次notFreeShippingTips
          if (opt.type == 'coupon' && opt.cancelPoints) {
            opt.resultCb && opt.resultCb()
          }

          if (opt.type == 'points') {
            if(self.caculateData['points'] && !opt.isUseAll && opt.byUser) {
              daEventCenter.triggerNotice({
                daId: '1-11-1-139',
                extraData: {
                  result: 1
                }
              })
            }
          } else if (opt.type == 'wallet') {

            if(self.caculateData['use_wallet'] && !opt.isUseAll && opt.byUser) {
              daEventCenter.triggerNotice({
                daId: '1-11-1-134',
                extraData: {
                  result: 1
                }
              })
            }
          }

          self.status.success[opt.type] = 1

          // 更新购物车
          self.$nextTick(function () {
            const maxAvailablePoint = self.IS_RW ? self.checkout.mall_caculate_info?.maxAvailablePoint : self.checkout.mall_caculate_info?.maxAvailablePoint2
            if (self.pointsData > maxAvailablePoint) {
              self.updateCheckoutState({
                key: 'pointsData',
                val: maxAvailablePoint
              })
            }
          })
        } else {

          if (!isCod) {
            self.status.error[opt.type] = 1
            self.status.error[opt.type + 'Code'] = res.code
          }

          if (opt.type == 'points') {
            if(self.caculateData['points'] && !opt.isUseAll && opt.byUser) {
              daEventCenter.triggerNotice({
                daId: '1-11-1-139',
                extraData: {
                  result: 0
                }
              })
            }

            let caculate_info_points = 0
            if (isCod) {
              let limit = res.info && res.info.notSupportCodTips && res.info.notSupportCodTips.params && res.info.notSupportCodTips.params.limit || ''
              if (limit == 'min') {
                caculate_info_points = 0
              } else if (limit == 'max') {
                caculate_info_points = self.checkout.mall_caculate_info.points
              }
            }
            self.caculateData[opt.type] = isCod ? caculate_info_points : 0
            self.updateCheckoutState({
              key: 'pointsData',
              val: isCod ? caculate_info_points || '' : ''
            })
            if (isCod && caculate_info_points > 0) {
              self.status.success.points = 1
            }
            self.updateTotal(self.caculateData)
          }

          if(self.caculateData['use_wallet'] && !opt.isUseAll && opt.byUser) {
            daEventCenter.triggerNotice({
              daId: '1-11-1-134',
              extraData: {
                result: 0
              }
            })
          }
        }

      }, { type: opt.type })
    },
    // cod金额为0时默认选择支付方式
    codZeroPaymentSelect: function () {
      var _this = this

      setTimeout(function () {
        if (_this.status.cardPay && _this.status.cardPay.toLowerCase() != 'cod')
          return

        if (_this.checkout.mall_caculate_info.paymentMethodLimitInfo && _this.checkout.mall_caculate_info.paymentMethodLimitInfo[_this.paymentSuggestion.code] == 0) return

        if (
          _this.paymentSuggestion.code &&
          _this.paymentSuggestion.code.toLowerCase() != 'cod'
        ) {
          var now_item = _this.checkout.results.paymentMethods.filter(function (
            item
          ) {
            return item.code == _this.paymentSuggestion.code
          })
          if (now_item && now_item[0]) {
            now_item = now_item[0]
            _this.clickPayment(now_item.id, now_item.code, now_item)
          }
        } else if (_this.paymentSuggestion.code) {
          var _item = _this.checkout.results.paymentMethods.filter(function (
            item
          ) {
            return item.code.toLowerCase() != 'cod'
          })
          if (_item && _item.length) {
            _this.clickPayment(_item[0].id, _item[0].code)
          }
        }
      }, 300)
    },
    exposeNotSupportCodCode(notSupportCodTipsCode) {
      if (
        notSupportCodTipsCode !== '' &&
        this.checkout.results.paymentMethods.some((item) => item.code == 'cod')
      ) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-8',
          extraData: {
            fail_reason: notSupportCodTipsCode,
          },
        })
      }
    },
    handleGiftLimitDrawer() {
      const { giftCardLimitGoods = [] } = handleLimitGoods({
        mallStoreInfos:
          this.checkout?.mall_caculate_info?.mall_store_infos || [],
        carts: this.checkout?.results?.carts?.carts || [],
      })
      if (giftCardLimitGoods.length) {
        this.assignState({
          giftCardLimitGoods: giftCardLimitGoods,
        })
        this.giftcard.limit = true
      }
    },
    handleResetAssets({ isHadCoupon, isHadPoint, isHadWallet, isHadGiftcard }) {
      // 券＞积分＞钱包＞礼品卡
      let assetsList = []
      if(isHadCoupon) assetsList.push(this.language.SHEIN_KEY_PC_28861)
      if(isHadPoint) assetsList.push(this.language.SHEIN_KEY_PC_28863)
      if(isHadWallet) assetsList.push(this.language.SHEIN_KEY_PC_28894)
      if(isHadGiftcard) assetsList.push(this.language.SHEIN_KEY_PC_28862)
      return assetsList
    },
    // 二次请求计价（修改商品触发）
    resetMallCacuTotal({ errCb = null } = {}) {
      // 修改商品触发的失败回调需要删除优惠券，支付方式(此处直接使用运输方式限制处触发判断),用券报错，删券后再次请求(此次用户无感知，不进行报错提示)，删券后请求再报错
      let toastText = ''
      const isHadPayment = this.caculateData.payment_id?.length > 0
      const isHadCoupon = this.caculateData.coupon?.length > 0
      const isHadPoint = +this.caculateData.points > 0
      const isHadWallet = +this.caculateData.use_wallet_amount > 0
      const isHadGiftcard = this.caculateData.card_no?.length > 0
      const assetsList = this.handleResetAssets({ isHadCoupon, isHadPoint, isHadWallet, isHadGiftcard })
  
      const assetsTip = !assetsList.length ? '' : (assetsList.length > 2 ? ` ${this.language.SHEIN_KEY_PC_28817} ${this.language.SHEIN_KEY_PC_28818}` : assetsList.slice(0, 2).reduce((prev, next) => {
        return prev + ` ${this.language.SHEIN_KEY_PC_28817} ` + next
      }, ''))
  
      if(isHadPayment) {
        let tip = !!assetsTip ? (this.language.SHEIN_KEY_PC_28860 + assetsTip) : this.language.SHEIN_KEY_PC_28860
        toastText = this.template(tip, this.language.SHEIN_KEY_PC_28816)
      } else {
        toastText = !!assetsTip ? this.template(assetsTip.replace(` ${this.language.SHEIN_KEY_PC_28817} `, ''), this.language.SHEIN_KEY_PC_28816) : ''
      }
  
      this.changeParamsStatus({
        type: 'caculateData',
        params: {
          payment_code_unique: '',
          payment_id: '',
          coupon: '',
          use_wallet: 0,
          use_wallet_amount: '',
          points: '',
          card_no: '',
          card_pin: '',
        },
      })
  
      apiReport.report({
        apiPath: '/checkout/remove_goods'
      })
  
      const fnCb = (res) => {
        if (res.code == 0) {
          !!toastText && SMessage({
            message: toastText,
            type: 'error'
          })
        } else {
          SMessage({
            message: res.tips,
            type: 'error'
          })
          errCb && errCb()
        }
      }
      if(isHadCoupon) {
        this.getUpdateCoupon({}, {
          noLoading: true,
          type: 'originCallBack',
          isModifyCart: true,
          cb: fnCb
        })
      } else {
        this.updateTotal(this.caculateData, fnCb, { type: 'modifyCart' })
      }
    },
    updateTotal (opt, cb, obj = {}) {
      const { notTips, type } = obj
      const isAutoUseCoupon = opt?.is_coupon_auto_use == 1 || this.isCouponAutoUse
      // 自动用券、修改商品均需要重新调用券列表
      if (isAutoUseCoupon || type == 'modifyCart') {
        this.getUpdateCoupon(opt, {
          noLoading: type == 'modifyCart',
          isModifyCart: type == 'modifyCart',
          cb,
          type: 'originCallBack',
          notTips
        })
      } else {
        this.orignUpdateTotal(opt, cb, obj)
      }
    },

    // 是否展示卡bin优惠
    isShowCardBinDiscount: function () {
      // 是否展示卡bin优惠abt
      const { BinDiscountdiscount = {} } = this.checkout?.checkoutBFFAbtInfo || {}
      const isShowCardBinDiscountABT = BinDiscountdiscount?.param?.BinDiscountShow === 'Show'

      // 支付方式列表
      const paymentMethods = this.checkout?.results?.paymentMethods || []

      // 当前支付方式是否支持卡bin优惠
      let result = false
      for(let i = 0; i < paymentMethods.length; i++){
        const item = paymentMethods[i]
        if (this.checkout?.results?.bin_discount_list?.length > 1) {
          let is_discount_type = false
          let is_match_payments = this.checkout?.results?.bin_discount_list
            .map(binDiscountItem => {
              const { discount_type = 0, match_payments = [] } =
                binDiscountItem || {}
              const matchPayment = (match_payments || []).some(
                v => v === item.code
              )
              if (discount_type != 0) {
                is_discount_type = true
              }
              return matchPayment
            })
            .some(isMatchPayment => isMatchPayment)
          if (
            item.enabled &&
            is_discount_type &&
            is_match_payments &&
            isShowCardBinDiscountABT
          ) {
            result = true
            break
          }
        }
      }

      return result
    },

    // 是否展示支付方式优惠
    isShowOnlinePayDiscount: function() {
      // 支付方式列表
      const paymentMethods = this.checkout?.results?.paymentMethods || []
      const randomDiscountAbt = this.checkout?.checkoutBFFAbtInfo?.PaymentRandomDiscount?.param
        ?.PaymentRandomShow == 'Show'

      let result = false
      for(let i = 0; i < paymentMethods.length; i++){
        const item = paymentMethods[i]
        const onlinePayDiscountValue = this.checkout.mall_caculate_info?.onlinePayDiscountInfo?.onlinePayDiscountValue?.[
          item?.code
        ] || {}

        const isShowOnlineRandomDiscount = 
          onlinePayDiscountValue.discountType == 2 &&
          +onlinePayDiscountValue?.random_max?.amount != 0 &&
          randomDiscountAbt
        
        if (
          item.enabled &&
          !!Object.keys(onlinePayDiscountValue)?.length &&
          ((onlinePayDiscountValue.discountType != 2 &&
            onlinePayDiscountValue?.min &&
            onlinePayDiscountValue?.min?.amount != 0) ||
            (onlinePayDiscountValue.discountType == 1 &&
              onlinePayDiscountValue?.limit_value &&
              onlinePayDiscountValue?.limit_value?.amount != 0) ||
              isShowOnlineRandomDiscount)
        ) {
          result = true
          break
        }
      }

      return result
    },

    // 用户权益埋点
    userBenefit: function(){
      const interests = []
      if (this.isShowOnlinePayDiscount() || this.isShowCardBinDiscount()) {
        interests.push('pay_promotion')
      }
      if (this.isShowCardBinDiscount()) {
        interests.push('bin_promotion')
      }
      if(interests.length) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-205',
          extraData: {
            interests: interests.join('`')
          }
        })
      }
    },

    getBinsReqData() {
      let binsOpt = {}
      if (this.isUsePrePayForCheckout) {
        return {}
      }
      if (this.isShowTokenPay || this.isShowTokenFrontInstallment) {
        const routerPayCardData = (this.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-card')
        const installmentRouterPayCardData = (this.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-cardinstallment')
        let tempList = []
        if (this.isShowTokenPay) {
          tempList = [...(routerPayCardData?.card_token_list || [])]
        }
        if (this.isShowTokenFrontInstallment) {
          tempList = [...tempList, ...(installmentRouterPayCardData?.card_token_list || [])]
        }

        binsOpt.bins = [...new Set(tempList.map(item => item?.card_bin).filter(item => item && item?.length > 0))]
        binsOpt.need_select = ['routepay-card', 'routepay-cardinstallment'].includes(this?.status?.cardPay) ? 1 : 0
        binsOpt.selected_bin = ''
        if (this?.status?.cardPay === 'routepay-card') {
          const bin = this.handlerSelectBin || this.defaultSelectTokenPay?.card_bin || ''
          binsOpt.selected_bin = (this?.isSelectTokenPay && binsOpt.need_select === 1) ? bin : ''
        } 
        if (this?.status?.cardPay === 'routepay-cardinstallment') {
          binsOpt.selected_bin = (this?.isSelectInstallmentTokenPay && binsOpt.need_select === 1) ? this?.installmentSelectBin : ''
        }
      }
      return binsOpt
    },

    // 更新价格，chooseShip: 是否用户自主选择邮费，用于免邮卡index
    orignUpdateTotal: function (opt, cb, obj = {}) {
      let binsOpt = this.getBinsReqData()
      // if (this.isShowTokenPay) {
      //   const routerPayCardData = (this.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-card')
      //   const binList = (routerPayCardData?.card_token_list || [])?.map(item => item?.card_bin).filter(item => item && item?.length > 0)
      //   binsOpt.bins = binList
      //   binsOpt.need_select = this.status?.cardPay === 'routepay-card' ? 1 : 0
      //   binsOpt.selected_bin = binsOpt.need_select === 1 ? (this.defaultSelectTokenPay?.card_bin || this.handlerSelectBin || '') : ''
      // }

      const { mallUrl, isCoupon, type = '' } = obj
      var _this = this
      var _opt = opt
      let _url = mallUrl || '/api/checkout/mallCacuTotal/get'

      this.assignState({ showMarkedPrice: 0 })
      const { newPrimeProductList } = this?.virtualCouponInfo || {}
      const vip_order_source = newPrimeProductList.find(f => f.product_code === this.usedPrimeCode)?.primeProductList?.product_activity_type == 1 ? 1 : 0
      const save_card_vip_order_source = this?.xtraAllProductBagInfo[this?.usedXtraCode]?.activityType == 1 ? 1 : 0

      // 当选中权益包是续费产品包（product_activity_type为1）的时候，增加vip_order_source字段
      // if (this.usedPrimeCode && _url === '/api/checkout/mallCacuTotal/get') {
      //   const selectPrimeInfo = this.primeProductList?.filter(item => item?.product_code == this.usedPrimeCode)
      //   const vipOrderSource = selectPrimeInfo?.length && selectPrimeInfo[0].product_activity_type == 1
        
      //   if (vipOrderSource) {
      //     _opt.vip_order_source = 1
      //   }
      // }

      //当险种为退货险，且所有商品不可退货(isDisplayShippingInsurance)时，取消勾选险种
      this.handleUseInsurance({ mallInsuranceCheck: this.mallInsuranceCheck, insuranceMallData: this.checkout?.results?.insuranceMallData || {} })
      _opt.mall_params = this.caculateData.mall_params

      //链接带上币种会切换
      /**
       * 获取强制切换币种
      //  */
      let isChangeCurrency = false
      let currentPayment = null
      if (this.checkout.mall_caculate_info && this.checkout.mall_caculate_info.orderCurrency && this.checkout.mall_caculate_info.orderCurrency.code) {
        const switchCurrencyResult = handleSwitchCurrency({
          currencySupport: this.currencySupport,
          currency: this.checkout.mall_caculate_info.orderCurrency.code,
          countryCode: this.checkout.default_address.value || '',
          paymentCode: _opt.payment_code_unique
        })
        if (switchCurrencyResult) {
          isChangeCurrency = switchCurrencyResult.isChangeCurrency
          currentPayment = switchCurrencyResult.currentPayment
        }
      }

      //如果使用钱包后总价为0，则不走转换货币
      if (this.checkout.mall_caculate_info.grandTotalPrice &&
        +this.checkout.mall_caculate_info.grandTotalPrice.amount == 0 &&
        this.checkout.mall_caculate_info.wallet &&
        this.checkout.mall_caculate_info.wallet.subtractPrice &&
        +this.checkout.mall_caculate_info.wallet.subtractPrice.amount > 0 &&
        isChangeCurrency
      ) {
        console.log('useing fulled wallet does not change currency')
        isChangeCurrency = false
        this.fullWalletNoCurr = true
      }

      if (isChangeCurrency) {
        if (!this.beforeChangedCurrency) {
          // 只在第一次发生转化时，运行一次
          _opt.sub_currency_code = this.checkout.mall_caculate_info.orderCurrency.code
        }
        // 只有发生币种转换时 更新前币种beforeChangedCurrency
        this.beforeChangedCurrency = this.checkout.mall_caculate_info.orderCurrency.code
        const { defaultCurrency } = currentPayment
        this.currTips.currBox = true
        this.currTips.txt = this.template(defaultCurrency, this.language.SHEIN_KEY_PC_17805)

        // 货币切换钱包使用清空
        this.walletCaculate({ isUse: true })
        // abt为自动使用钱包
        if (this.isAutoUseWallet) {
          _opt.use_wallet = 1
          _opt.use_wallet_amount = ''
        } else {
          _opt.use_wallet = 0
          _opt.use_wallet_amount = ''
        }
        
        _url = mergeQueryString({
          mergeObj: { currency: defaultCurrency }, 
          url: _url,
        })
        this.currencyCode = defaultCurrency
        daEventCenter.triggerNotice({ daId: '1-11-1-15' })
        // 强制币种转换后需重新调用支付方式列表
        this.updatePayment({
          value: this.checkout.default_address?.value,
          newDefaultCurrency: defaultCurrency
        })
      }
      // 当发生过币种转换，就要一直传sub_currency_code
      if (this.checkout.mall_caculate_info.subGrandTotalPrice) {
        _opt.sub_currency_code = this.beforeChangedCurrency
      }

      // cod支付后端转换货币后前端跟随转换对应货币
      if (
        _opt.payment_code_unique.toLowerCase() == 'cod' &&
        this.checkout.mall_caculate_info.changeCodLocalCurrency &&
        this.checkout.mall_caculate_info.changeCodLocalCurrency.isChange == 1 &&
        this.currencyCode != this.checkout.mall_caculate_info.changeCodLocalCurrency.codLocalCurrency
      ) {
        this.currencyCode = this.checkout.mall_caculate_info.changeCodLocalCurrency.codLocalCurrency

        _url = mergeQueryString({
          mergeObj: { currency: this.currencyCode }, 
          url: _url,
        })
        // 货币切换钱包使用清空
        this.walletCaculate({ isUse: true })
        _opt.use_wallet = 0
        _opt.use_wallet_amount = ''

        isChangeCurrency = true
      }
      

      if (this.giftcard.apply) {
        _opt.card_no = this.giftcard.num
        _opt.card_pin = this.giftcard.pin
        this.giftcard.limit = false
      } else {
        _opt.card_no = ''
        _opt.card_pin = ''
        this.giftcard.limit = false
        this.assignState({
          giftCardLimitGoods: [],
        })
      }
      //运输方式是否为店配
      const isShopTransit = TRANSPORT_TYPE_SHOP.includes(this.defaultShipping[0]?.shipping_method?.transport_type)
      _opt.is_shop_transit = isShopTransit ? 1 : 0
      _opt.shop_transit_country_id = isShopTransit ? this.checkout.default_address.countryId : 0
      // 地址为空(0)不走价格计算接口
      if (opt.address_id == 0) return
      // 店配地址为空不走价格计算接口
      if (TRANSPORT_TYPE_SHOP.includes(this.defaultShipping[0]?.shipping_method?.transport_type) && (!this.storeData || !this.storeData?.storeId)) {
        return
      }

      if (this.codConfirm.applyType == 'points' && this.codConfirm.pointCb == '') {
        this.changeParamsStatus({ type: 'codConfirm', params: { pointCb: cb } })
      }

      this.assignState({
        updatingPrice: true
      })

      this.calCbHasErrTip = false

      schttp({
        url: _url,
        method: 'POST',
        data: {
          ..._opt,
          mall_params: JSON.stringify(_opt.mall_params),
          vip_order_source,
          save_card_vip_order_source,
          ...binsOpt
        },
        schttp: {
          needLogin: true,
        },
      }).then(async res => {
        const user = (await UserInfoManager.get({ key: 'UserInfo', actionType: 'userInfo'  })) ?? {}
        // 券模块需要串行请求价格，完整参数作为newRes转发出去
        let newRes
        if (isCoupon && mallUrl) {
          newRes = res
          res = res.calculate
        }
        this.assignState({
          updatingPrice: false
        })

        let elseErrCode = false
        let notTips = false // 用券错误是否不提示toast

        // 增加 纯计价接口赋值，回调 只处理成功，失败不做下一步处理，统一tost提示
        if(_opt?.pureCallBack){
          typeof _opt.pureCallBack === 'function' && _opt.pureCallBack(res)
          if(res.code == 0) { 
            _this.checkout['mall_caculate_info'] = res.info
            this.getShippingFreeMall()
          }
          return
        }

        if (res.code == 0) {
          this.assignState({ mallCacuTime: new Date().getTime() })
          this.assignState({ cardBinDiscountInfo: res.info?.cardBinDiscountInfo || {} })
          _this.checkout['mall_caculate_info'] = res.info
          this.assignState({
            prevCalcuMallParams: this.caculateData?.mall_params
          })

          //记录价格计算成功的入参缓存
          if (_this.checkout.cacheAbtValid) {
            const { address_id, mall_params } = opt
            const mall_transport_type = mall_params.map(mall => {
              return { mall_code: mall.mall_code, transport_type: mall.transport_type }
            })
            const mall_use_insurance = mall_params.map(mall => {
              return { mall_code: mall.mall_code, use_insurance: mall.use_insurance }
            })
            // 用户手动点击过qs运费才记录缓存，且当前值为0时不使用
            const mall_switch_qs_flag = mall_params.map(mall => {
              return { mall_code: mall.mall_code, switch_qs_flag: _this.isClickedQsFreight ? mall.switch_qs_flag : 0, transport_type: mall.transport_type }
            })
            const checkoutCache = [user.member_id, user.login_time_last, gbCommonInfo.SiteUID, address_id, JSON.stringify(mall_transport_type), JSON.stringify(mall_use_insurance), JSON.stringify(mall_switch_qs_flag)].join(';')
            //半小时缓存时间
            this.setCacheBysetLocalStorage({ data: checkoutCache, type: 'checkcache', expire: 30 * 60 * 1000 })
          }

          if (_this.checkout.default_address) {
            const { addressId, countryId, cityId, stateId } = _this.checkout.default_address
            const { member_id, login_time_last } = user
            const addressData = {
              addressId, countryId, cityId, stateId,
              memberId: member_id,
              loginTimeLast: login_time_last,
              createdTime: +new Date(),
              siteUid: gbCommonInfo.SiteUID,
            }
            setTimeout(() => {
              this.setCacheBysetLocalStorage({ data: addressData, type: 'addrcache' })
            }, 200)
          }

          if (res.info.extraPromotion) {
            _this.extraPromotion = res.info.extraPromotion
          } else {
            _this.extraPromotion = ''
          }

          _this.exposeNotSupportCodCode(res.info.notSupportCodTips && res.info.notSupportCodTips.code || '')
          // 只在强制币种转换才会触发自动使用钱包
          if (isChangeCurrency) {
            _this.analysisAutoUseWallet()
          }

          if (this.giftcard.apply && this.giftcard.loading) {
            _this.handleGiftLimitDrawer()
          }

          //处理inline 模式sdk
          _this.initPayInlineSession({
            paymentMethods: _this.checkout.results.paymentMethods,
            cb: () => {
              // klarna处理
              let payInlineChannel = _this.judgeNeedPayInlineChannel(_opt.payment_code_unique)
              // klarna处理和paypal处理
              if ((!_opt.isClickPayment && payInlineChannel) || ['paypal'].includes(payInlineChannel)) {
                _this[`${payInlineChannel}LoadWidget`] && _this[`${payInlineChannel}LoadWidget`]()
              }
            }
          })

          _this.payLimit(res.info)

          if (_this.checkout.results && _this.checkout.results.paymentMethods && _this.checkout.results.paymentMethods.length) {
            _this.getInstallmentInfo()
          }

          if (res.info.isDisabledCodPayment) {
            _this.checkout.mall_caculate_info.notSupportCodPaymentMethodReasonTip = _this.language.SHEIN_KEY_PC_14815
            _opt.is_shop_transit == 0 && _this.codZeroPaymentSelect()
          }

          if (_this.status.cardPay.toLowerCase() == 'cod' && res.info.isSupportCod == 0) {
            _this.status.cardPay = ''
            _this.caculateData.payment_id = ''
            _this.caculateData.payment_code_unique = ''

            _this.checkout.results.paymentMethods.forEach((item, index) => {
              if (item.code == 'cod') {
                _this.checkout.results.paymentMethods[index]['enabled'] = 0
              }
            })
          }

          _this.setAdrTitleTipsStatus()

          // 处理免邮不足提示
          this.updateFreeShipTip({ info: res.info, type })
          this.getShippingFreeMall()

          // 将更新使用钱包提前，防止刷新券列表时，使用钱金额未更新，导致自动使用钱包失效
          if (res.info) {
            let { points = 0, walletPrice = {} } = res.info || {}
            _this.updateCheckoutState({
              key: 'pointsData',
              val: points > 0 ? points : ''
            })
            _this.caculateData.points = Number(points)
            _this.status.success.points = points > 0 ? 1 : 0
            if (walletPrice.amount > 0) {
              _this.caculateData.use_wallet = 1
              _this.caculateData.use_wallet_amount = walletPrice.amount
              _this.updateCheckoutState({
                key: 'walletData',
                val: walletPrice.amount
              })
              _this.status.isWalletAmoutHit = true
              _this.status.success.wallet = 1
            } else {
              _this.caculateData.use_wallet = 0
              _this.caculateData.use_wallet_amount = ''
              _this.updateCheckoutState({
                key: 'walletData',
                val: ''
              })
              _this.status.isWalletAmoutHit = true
              _this.status.success.wallet = 0
            }
            _this.status.error.points = 0
            _this.status.error.wallet = 0
          }

          // 如果 切换支付方式 券需要更新一下券列表以及买券，让支持的可选 不支持的置灰
          if ((_this.coupon_payment_code_unique != _opt.payment_code_unique) && _opt.payment_code_unique) {
            // 修复切换货币的情况下重复请求券列表接口
            if (!isChangeCurrency) {
              this.updateCouponListOnly()
            }
            _this.assignState({ coupon_payment_code_unique: _opt.payment_code_unique })
          }

          // 切换货币后， 需要用新货币更新一次券列表，更新购物车币种，更新运费险币种, 更新付费会员产品包，更新超省卡产品包,更新付费会员已省金额
          if (isChangeCurrency) {
            this.updateCouponListOnly()
            this.updateCart({ noUpdataShipping: 1 })
            this.updateShippingInsurance({
              countryId: _this.checkout.default_address.countryId,
              address_id: _this.checkout.default_address.addressId,
              mallList:
                _this.mallShippingMethods?.mall_list?.map((item) => {
                  return { mall_code: item.mall_code }
                }) || [],
            })
            if (this.primeProductList.length) {
              this.changePrimeInfo()
            }
            if (this.showXtra) this.updateXtra()
            // 下单页币种转换后需更新活动信息，用于订单返券包金额展示
            this.getCartPromotionInfo({ forceRequest: true })
            // 随手购更新数据
            if (_this.togetherBuy.togetherBuyType) window.appEventCenter?.emit?.('update-recommend-data')
          }

          //如果原本钱包0元，操作后导致不到0元，则重新请求
          if (_this.fullWalletNoCurr && res.info.grandTotalPrice && +res.info.grandTotalPrice.amount != 0) {
            console.log('reset currency')
            _this.fullWalletNoCurr = false
            setTimeout(() => {
              _this.orignUpdateTotal(_this.caculateData)
            }, 10)
          }

        } else if (res.code == 300412) {
          //打折限购超过数量
          _this.updateCheckoutState({ key: 'limitTip', val: {
            show: true,
            txt: _this.language.SHEIN_KEY_PC_17483,
            type: 'discountLimit'
          } })
          sa('send', { 'activity_name': 'expose_checkoutpurchaselimitation' })

        } else if (res.code == 300516 || res.code == 300509 || res.code == 300510 || res.code == 300511 || res.code == 300518 || res.code == 300512 || res.code == 300513) {
          _this.checkout.mall_caculate_info.isSupportCod = 0

          if (res.info) {
            _this.handleNotSupportCodTip(res.info)
          }

          if (_this.status.cardPay.toLowerCase() == 'cod') {

            //当使用优惠卷或积分后达不到cod金额条件
            if (res.code == 300511) {
              notTips = true
              _this.assignState({ codCoupon: _this.caculateData.coupon })
              var codConfirmTxt = _this.codConfirm.applyType == 'coupon' ? _this.language.SHEIN_KEY_PC_16299 : _this.language.SHEIN_KEY_PC_16300
              var resInfo = res.info && res.info.notSupportCodTips && res.info.notSupportCodTips.params || {}
              _this.changeParamsStatus({ type: 'codConfirm', params: { currBox: true, res, txt: codConfirmTxt.replace('{min}', resInfo.min).replace('{max}', resInfo.max) } })
            } else {
              _this.status.cardPay = ''
              _this.caculateData.payment_id = ''
              _this.caculateData.payment_code_unique = ''
            }
          }

          if(!notTips) _this.orignUpdateTotal(_this.caculateData)

          //支付优惠券不可用
        } else if (res.code == 300627) {
          //取 可用支付方式 和 支付优惠券限定的支付方式 交集
          let arr = []
          if (res.info && res.info.paymentLimit && res.info.paymentLimit.length) {
            arr = _this.checkout.results.paymentMethods.filter(item => {
              if (item.enabled && res.info.paymentLimit.includes(item.code)) {
                return item
              }
            })
          }
          this.assignState({ paymentCouponList: arr })

          if (arr.length) {
            if (opt.latest_coupon) {
              // 使用券提醒切支付方式 如果是使用优惠券不匹配当前支付，则弹出优惠券对应的可使用的支付方式
              this.assignState({ showPaymentCoupon: true, nowPaymentCoupon: this.coupon.val })
            } else if (this.applyCouponsSuccess.length) {
              // 计算价格 切支付方式 如果原来已成功使用支付优惠券，从可用支付方式切换至不可用支付方式
              this.assignState({ confirmUsePaymentCouponShow: true })
              sa('send', { 'activity_name': 'expose_popup_switchunavailablepaymethodbox' })
            }
          }

        } else if (res.code == 301711) {
          _this.giftcard.apply = false
          _this.giftcard.err = _this.language.SHEIN_KEY_PC_17671
        } else if (res.code == 300365) {
          _this.giftcard.apply = false
          _this.giftcard.err = _this.language.SHEIN_KEY_PC_15330
        } else if (res.code == 300366) {
          // 新增全部商品不可使用礼品卡报错
          _this.giftcard.apply = false
          _this.giftcard.limit = true
          _this.assignState({
            giftCardLimitGoods: _this.checkout?.results?.carts?.carts || [],
          })
        } else if (res.code == 300363) {
          _this.giftcard.apply = false
          _this.giftcard.err = _this.language.SHEIN_KEY_PC_17315
        } else if (res.code == 300364) {
          _this.giftcard.apply = false
          _this.giftcard.err = res.tips || _this.language.SHEIN_KEY_PC_14463
        } else if (res.code == 300300) {
          _this.giftcard.apply = false
          _this.giftcard.err = res.tips || _this.language.SHEIN_KEY_PC_14465
        } else if ([301709, 301700, 301715, 301716, 301717].includes(+res.code)) {
          _this.giftcard.apply = false
          _this.giftcard.err = res.tips
        } else if (res.code == 301713) {
          _this.giftcard.apply = false
          _this.giftcard.err = (res.tips || '').replace(/%s/g, res?.info?.market_name || '').replace(/\{0\}/g, res?.info?.market_name || '').replace(/\{\}/g, res?.info?.market_name || '')
        } else if (res.code == 300625 && type != 'coupon') {    // apply优惠券出现两个提示，故用type限定
          _this.showTip(_this.language.SHEIN_KEY_PC_17392)
          _this.clearCouponUpdateCalculator()
        } else if (res.code == 301714) {
          blackMask.text = res.tips
          blackMask.showClose = false
          blackMask.showMask = true
          blackMask.cb = () => {
            blackMask.showClose = true
            daEventCenter.triggerNotice({
              daId: '1-11-1-29',
              extraData: {
                error_scene: 0
              }
            })
          }
          daEventCenter.triggerNotice({
            daId: '1-11-1-28',
            extraData: {
              error_scene: 0
            }
          })
        } else if (
          res.code &&
          [
            300601, 300605, 300606, 300612, 300613, 300614, 300615, 300618,
            300619, 300620, 300621, 300623, 301108, 300632, 300633, 302451, 302452, 302453,
          ].includes(+res.code)
        ) {
          // 代金券报错
          _this.status.error.couponCode = res.code
          _this.status.error.coupon = 1
          if (this.couponCodeErrorTips && !isCoupon && type != 'modifyCart') {
            this.$message({
              message: this.couponCodeErrorTips,
              type: 'error',
              offset: 186,
            })
            // 非券列表请求 导致券失败的，删券在请求价格
            this.clearCouponUpdateCalculator()
          }
        } else if (res.code == 300407) {
          _this.updateCheckoutState({ key: 'limitTip', val: {
            show: true,
            txt: res.tips,
            type: 'cartLimit'
          } })
        }  else if (res.code == 300401) { //购物车为空
          _this.assignState({
            showCartNullDialog: true,
            nullDialogTip: res.tips
          })
        } else if (res.code == 302458 && !this.coupon?.showCouponList) { // 入参支付方式和卡bin优惠优惠券限制的支付方式一致，但是卡bin不一致
          // let nowBinList = []

          // const getNowBinList = () => {
          //   (this.couponBinList || []).forEach(item => {
          //     if (opt?.coupon?.includes(item?.coupon_code)) {
          //       nowBinList = [...new Set([...nowBinList, ...item?.bin_list])]
          //     }
          //   })
          // }

          // getNowBinList()

          if (this.coupon?.applyCouponsSuccess?.length) {
            // 计算价格 切支付方式 如果原来已成功使用支付优惠券，从可用支付方式切换至不可用支付方式
            this.assignState({
              confirmUsePaymentCouponShow: true,
              isSelectCouponsBinError: true
            })
          }
        } else {
          elseErrCode = true
        }
        _this.giftcard.loading = false

        if (type === 'coupon') {
          daEventCenter.triggerNotice({
            daId: '1-11-1-24',
            extraData: {
              coupon: _opt.coupon || '',
              code: res.code || '',
              msg: res.msg || ''
            }
          })
        }

        if (typeof cb == 'function') {
          cb(res, newRes)
        }
        //如果上面cb里未处理错误code，且有其它未处理的报错elseErrCode=true，则弹出提示统一错误提示
        if (!this.calCbHasErrTip && elseErrCode && !isCoupon && type != 'modifyCart' && !_opt?.pureCallBack) {
          this.$message({
            message: res.tips,
            type: 'error',
            offset: 186,
          })
          this.calCbHasErrTip = false
        }
      })
    },
    delCacheBysetLocalStorage(key) {
      schttp({
        method: 'POST',
        url: '/api/common/busineseCache/del',
        data: {
          key
        }
      })
    },
    setCacheBysetLocalStorage ({ data, type, expire }) {
      schttp({
        method: 'POST',
        url: '/api/common/busineseCache/update',
        data: {
          cacheData: data,
          scanType: type,
          expire
        }
      })
    },
    updateFreeShipTip(opts) {
      let { info = {}, type = 'coupon' } = opts
      const { mall_list = [] } = info
      let mall_list2 = this.checkout?.results?.carts?.mall_list || []
      // 过滤掉1，2单独在各种模块展示
      let allFreeTips = [],
          couponFreeTips = [],
          pointFreeTips = [],
          mergeMallList = mallMerge(mall_list, mall_list2)

      mergeMallList.forEach(item => {
        let freeType = item.not_free_shipping_tips
        if (+item.not_free_shipping_diff.amount > 0 && freeType) {
          if (freeType == 1) {
            couponFreeTips.push(item)
          } else if (freeType == 2) {
            pointFreeTips.push(item)
          } else {
            allFreeTips.push(item)
          }
        }
      })

      this.changeParamsStatus({
        type: 'freeShipTip',
        params: {
          coupon: couponFreeTips,
          point: pointFreeTips,
          other: allFreeTips,
          otherType: type
        }
      })
    },
    // 巴勒斯坦>117美金需补充身份证号
    checkPLNationalId() {
      const self = this
      const threshold = 117
      const { grandTotalPrice, shipping_price, insurancePrice } = self.checkout && self.checkout.mall_caculate_info || {}
      const price = Number(grandTotalPrice?.usdAmount) - Number(shipping_price?.usdAmount) - Number(insurancePrice?.usdAmount)
      return (price > threshold && !self.checkout?.default_address?.nationalId)
    },
    checkTwName() {
      const defaultAddress = this.checkout && this.checkout.default_address
      if (defaultAddress.countryId == 209) {
        if (TRANSPORT_TYPE_SHOP.includes(this.defaultShipping[0]?.shipping_method?.transport_type) && typeof this.storeData.nameVerified != 'undefined') {
          this.checkNationalId()
          this.status.adrTitleTipsShow = this.storeData.nameVerified == 0
        } else {
          //   FR-8016 补充场景参数 scene=2 下单页查询是否实名
          fetchCheckTwNameApi({
            country_id: defaultAddress.countryId,
            fname: defaultAddress.fname,
            lname: defaultAddress.lname,
            tel: defaultAddress.tel,
            scene: '2',
          }).then((data) => {
            this.checkNationalId()
            if (
              data.code == 0 &&
              data.info &&
              data.info.result &&
              data.info.result.name_verified == 0
            ) {
              this.status.adrTitleTipsShow = true
              this.storeData && (this.assignState({
                storeData: {
                  ...this.storeData,
                  nameVerified: '0'
                }
              }))
            } else {
              this.status.adrTitleTipsShow = false
              this.storeData && (this.assignState({
                storeData: {
                  ...this.storeData,
                  nameVerified: '1'
                }
              }))
            }
          })
        }
      }
    },
    checkNationalId() {
      //沙特展示文案取价格计算接口返回
      const { rule_id, hint_type, msg } = this.checkout.mall_caculate_info?.rule_intercept || {}
      if( rule_id > 0 && hint_type == 3 ){
        this.status.adrTitleTipsShow = true
        this.status.adrTitleSaTipsContent = msg || this.language.SHEIN_KEY_PC_19321
      } else {
        this.status.adrTitleTipsShow = false
        this.status.adrTitleSaTipsContent = ''
      }
    },
    // 当险种(insurance_type)为退货险，且所有商品不可退货(isDisplayShippingInsurance)时，取消勾选险种
    handleUseInsurance({ mallInsuranceCheck, insuranceMallData, updataCalcu }) {
      const { isChange = false, insuranceCheck } = handleMallInsuranceChange({ mallInsuranceCheck, insuranceMallData, mall_caculate_info: this.checkout?.mall_caculate_info })

      if (!isChange) return
      // mallInsuranceCheck 发生变化才进行下述操作
      this.assignState({ mallInsuranceCheck: insuranceCheck })
      this.updateCalcuMallParam({ selectMallShip: this.defaultShipping, mallInsuranceCheck: insuranceCheck })

      if (!updataCalcu) return
      this.updateTotal(this.caculateData, () => {
      })
    },
    getShippingList(params) {
      return new Promise(resolve => {
        schttp({
          url: '/api/checkout/shippingList/get',
          params
        }).then(json => {
          resolve(json)
        }).catch(
          () => {
            resolve({})
          })
      })
    },
    resetShippingData(shippingList) {
      const insuranceMallData = shippingList?.insuranceMallData || {}
      const mallInsuranceCheck = updateMallInsuranceCheck({ insuranceMallData, forcedShippingByAbt: this.forcedShippingByAbt, preMallInsuranceCheck: this.mallInsuranceCheck })

      let mallShippingMethods = shippingList?.mallShippingList?.info || {}
      this.checkout.results.mallShippingMethods = mallShippingMethods
      this.checkout.results.insuranceMallData = insuranceMallData || {}
      let shipTimeDesList = this.initShipTimeDes(mallShippingMethods)
      this.assignState({ 
        mallInsuranceCheck: mallInsuranceCheck,
        mallShippingMethods: mallShippingMethods,
        shipTimeDesList
      })
    },
    // 更新运输方式
    updateShipping: function (opt, cb) {
      schttp({
        url: '/api/checkout/shippingList/get',
        params: {
          ...opt,
          has_shop_transit_address: !!this.storeData ? 1 : 0,
          checkout_no: this.buyNowNo
        },
      }).then((json) => {
        this.resetShippingData(json)

        this.clickShipping(
          {
            scence: 1,
          }
        )
        if (typeof cb == 'function') {
          cb()
        }
      })
    },
    // 切换货币单独获取运费险mall信息
    updateShippingInsurance(opt) {
      if (opt.mallList) opt.mallList = JSON.stringify(opt.mallList)
      schttp({
        url: `/api/checkout/shippingInsuranceMall/get`,
        params: opt,
      }).then((json) => {
        const insuranceMallData = json?.insuranceMallData || {}
        const { mall_list = [] } = insuranceMallData
        if (!mall_list.length) return

        const mallInsuranceCheck = updateMallInsuranceCheck({ insuranceMallData, forcedShippingByAbt: this.forcedShippingByAbt, preMallInsuranceCheck: this.mallInsuranceCheck })
        this.checkout.results.insuranceMallData = insuranceMallData || {}

        this.assignState({ mallInsuranceCheck: mallInsuranceCheck })

        //当险种(insurance_type)为退货险，且所有商品不可退货(isDisplayShippingInsurance)时，取消勾选险种
        this.handleUseInsurance({
          mallInsuranceCheck,
          insuranceMallData: this.checkout?.results?.insuranceMallData,
          updataCalcu: true,
        })
      })
    },
    unfold() {
      this.checkout.results['folded_position'] = -1
    },
    // 更新支付方式
    updatePayment: function (opt, cb) {
      console.log('opt >>>>', opt)
      var self = this
      var _country = opt && opt.value ? opt.value : ''
      var type = newSetShippingList(this.caculateData?.mall_params || [])

      let membership_order_scene, xtra_order_scene
      if (this.primeProductList.length) membership_order_scene = 2
      if (this.showXtra) xtra_order_scene = 2

      this.paymentList.loading = true
      var timer = setTimeout(function () {
        //超过1s的加载时间出现弹窗
        if (self.paymentList.loading) {
          self.paymentList.showLoading = true
        }
      }, 1000)
      let formatDetail = this.getPaymentsOrderDetails?.map(cur => `${cur.businessModel}:${cur.companyId}`).join(',')
      if(self.usedXtraCode){
        const xtraDetail = `${self.xtraProductInfo?.business_model}:null`
        formatDetail = formatDetail ? `${formatDetail},${xtraDetail}` : xtraDetail
      }
      if(self.usedPrimeCode && self.usedPrimeIndex !== -1){
        const currentPrime = self.primeProductList[self.usedPrimeIndex]
        const primeDetail = `${currentPrime?.business_model}:null`
        formatDetail = formatDetail ? `${formatDetail},${primeDetail}` : primeDetail
      }

      const params = {
        type,
        country: _country,
        membership_order_scene,
        order_details: formatDetail,
        currency: opt.newDefaultCurrency || '',
        xtra_order_scene,
        request_card_token: 1
      }
    
      if ('token_id' in opt && opt.token_id) {
        params.token_id = opt.token_id
      } else if (window?.gbCoSsrData?.checkout) {
        const tokenDefault = (window?.gbCoSsrData?.checkout?.results?.paymentMethods || [])?.find(item => 'card_token_list' in item) || {}
        if (Object.keys(tokenDefault)?.length > 0) {
          params.token_id = tokenDefault?.card_token_list?.find(item => item?.default_token === 1)?.id
        }
      }

      if ('installment_token_id' in opt) {
        params.installment_token_id = opt?.installment_token_id
      } 

      schttp({
        url: '/api/checkout/paymentList/get',
        params: params,
      }).then((res) => {
        clearTimeout(timer)
        self.paymentList.loading = false
        self.paymentList.showLoading = false

        if (res.code == 0) {
          // 当前选中购买会员产品包才置灰
          if (self.primeProductList.length && self.usedPrimeIndex != -1 || self.usedXtraCode) {
            const isChoosePrime = !!(self.primeProductList.length && self.usedPrimeIndex != -1)
            const isChooseXtra = !!self.usedXtraCode
            res.info.payments = self.filterPrimePayments({
              paymentMethods: res.info.payments,
              isChooseXtra, 
              isChoosePrime
            })
          }
          res.info.payments = self.filterPaymentByAbt({
            paymentMethods: res.info.payments,
          })
          res.info.payments = self.filterStorePayments({
            paymentMethods: res.info.payments,
          })
          res.info.payments = self.filterPaymentsByBusinessModePayAbt({ 
            paymentMethods: res.info.payments,
            formatDetail
          })

          res.info.payments.map((item) => {
            if (GooglePay.googlePayMethods.includes(item.code)) {
              if (item.enabled == 1) {
                googlePayment.onGooglePayLoaded(
                  { createDom: false, code: item.code },
                  function (valid) {
                    self.googleValid = valid
                    if (!valid) {
                      item.enabled = 0
                    }
                  }
                )
                googlePayment.setPaymentMethods([...res.info.payments])
              }
              //return false
            }
            return item
          })
          

          //self.checkout.results.paymentMethods = res.info.payments
          // 展开后不再折叠
          const position = res?.info?.folded_position || 0
          if (self.checkout?.results?.folded_position !== -1) {
            self.checkout.results['folded_position'] = position
          }
          self.checkout.results['originFoldedPosition'] = position
          self.checkout.results['paymentMethods'] = res.info.payments
          self.checkout.results['bin_discount_info'] = res.info?.bin_discount_info || {}
          self.checkout.results['bin_discount_list'] = res.info?.bin_discount_list || []
          if (opt.updatePaypalGaVaultStatus) {
            self.initPaypalGaVault()
          }
          self.initBankLists(res.info.payments)
          self.initPayInlineSession({ paymentMethods: res.info.payments })

          self.payLimit()

          self.initApplePay(res.info.payments)
          self.paidyPayInit(res.info.payments)

          self.paypalSignAnalysis()
          // fix 因cod的置灰逻辑还依赖计价，因此重新获取支付方式后需处理cod状态，否则cod状态会出现不一致
          self.handleNotSupportCodTip(self.checkout.mall_caculate_info)

          var haveChosen = (res.info?.payments || []).some(item => {
            return item.id == self.caculateData.payment_id &&
              item.is_display == 1 &&
              item.enabled == 1
          }) //已选择的支付方式是否还在新支付方式列表中

          //用户没有选择支付方式
          if (!haveChosen) {
            //勾选默认支付方式
            self.status.cardPay = ''
            self.caculateData.payment_id = ''
            self.caculateData.payment_code_unique = ''
            self.getPaymentSuggestion()
          }
        }
        if (typeof cb == 'function') {
          cb(res)
        }
      }
      )
    },
    payLimit: function (info) {
      info = info || this.checkout.mall_caculate_info
      var _this = this
      //有金额限制的支付方式，若不满足则置灰
      if (info.paymentMethodLimitInfo) {
        var newPayList =
          (_this.checkout.results.paymentMethods &&
            _this.checkout.results.paymentMethods.slice(0)) ||
          []
        newPayList.forEach(function (item) {
          if (+info.paymentMethodLimitInfo[item.code] == 0) {
            //首次记录时，记录金额限制前该支付方式是否enabled
            if (item.beforeLimitEnabled === undefined) {
              item.beforeLimitEnabled = item.enabled
            }
            item.enabled = 0

            if (_this.status.cardPay == item.code) {
              _this.status.cardPay = ''
              _this.caculateData.payment_id = ''
              _this.caculateData.payment_code_unique = ''
            }
          } else if (
            +info.paymentMethodLimitInfo[item.code] == 1 &&
            item.beforeLimitEnabled !== undefined
          ) {
            //取消限制后，恢复原来的enabled
            item.enabled = item.beforeLimitEnabled
          }

          //如果有店铺商品，则可用支付方式都为“支持店铺的”支付方式，不支持店铺的支付方式置灰
          // if (
          //   _this.hasStoreGoods &&
          //   item.support_platform != undefined &&
          //   item.support_platform != 1
          // ) {
          //   item.enabled = 0
          //   item.show_description = 1
          //   item.isStoreDisabled = true
          // }
        })
        _this.checkout.results['paymentMethods'] = newPayList
      }
      // abt/没有快捷/置灰/不满足金额门槛/业务分流 => 自动续费包过滤掉
      this.autoPrimePackagefilter()
    },
    // 更新钱包价格
    updateWalletPrice: function () {
      var self = this
      if (this.status.loading.twice) return
      this.status.loading.twice = 1

      this.caculateData.use_wallet == 0
        ? (this.caculateData.use_wallet = 1)
        : (this.caculateData.use_wallet = 0)

      this.updateTotal(this.caculateData, function (data) {
        self.status.loading.twice = 0

        // cod
        if (
          self.status.cardPay.toLowerCase() == 'cod' &&
          data.info.isSupportCod == 0
        ) {
          self.status.cardPay = ''
          self.caculateData.payment_id = ''
          self.caculateData.payment_code_unique = ''
        }
      })
    },
    // 判断是否有库存
    judgeStock(outStockCarts = []) {
      const outStockCartsData = this.checkout.mall_caculate_info?.outStockCarts || []
      const newOutStockCarts = !!outStockCarts.length ? outStockCarts : outStockCartsData
      if (!!newOutStockCarts?.length) {
        this.status.cartStock = true
        this.newOutStockCarts = newOutStockCarts
        this.soldoutType = 0
        this.updateCheckoutState({ key: 'soldOutDialog', val: {
          show: true,
          loaded: true,
          productLimitTip: '',
          hintType: 0,
        } })
        return true
      } else {
        this.newOutStockCarts = []
        this.status.cartStock = false
        this.soldoutType = -1
        return false
      }
    },
    // qiwi phone check
    qiwiPhoneCheck: function () {
      var _reg_a = new RegExp('[0-9]{5}$')
      var _reg_b = new RegExp(/^[\d\s+-]*$/g)
      var _val = this.qiwiPayPhone.trim()

      if (
        !_val ||
        !_reg_b.test(_val) ||
        !_reg_a.test(_val.replace(/[^\d]/g, ''))
      ) {
        this.status.error.qiwiPhone = 1
        return true
      } else {
        this.status.error.qiwiPhone = 0
        return false
      }
    },
    // type: 1 mall_shipping_recommend, 2 mall_freight_insurance, 3 mall_shipping_method_id
    handleMallBiData(type) {
      if (type == 1) {
        let mall_shipping_recommend = ''
        if (!this.defaultShipping.length) return mall_shipping_recommend
        let shipping_recommend_temp = []
        this.defaultShipping.forEach((item) => {
          let shipping_recommend = `{mall:${item.mall_code},shipping_method:${item?.index == 0 ? '1' : '0'
            }}`
          shipping_recommend_temp.push(shipping_recommend)
        })
        return `{${shipping_recommend_temp.join('')}}`
      }
      if (type == 2) {
        let mall_freight_insurance = ''
        if (!this.mallInsuranceCheck.length) return mall_freight_insurance
        let freight_insurance_temp = []
        this.mallInsuranceCheck.forEach((item) => {
          let freight_insurance = `{mall:${item.mall_code},freight_insurance:${item.use_insurance}}`
          freight_insurance_temp.push(freight_insurance)
        })
        return `{${freight_insurance_temp.join('')}}`
      }
      if (type == 3) {
        let mall_shipping_method_id = ''
        if (!this.caculateData?.mall_params?.length)
          return mall_shipping_method_id
        let shipping_method_id_temp = []
        this.caculateData.mall_params.forEach((item) => {
          let shipping_method_id = `{mall:${item.mall_code},transport_type:${item.transport_type}}`
          shipping_method_id_temp.push(shipping_method_id)
        })
        return `{${shipping_method_id_temp.join('')}}`
      }
      return ''
    },
    handleShipTimeDesSaParam() {
      let des = ''
      if (!this.shipTimeDesList.length) return des

      const desList = this.shipTimeDesList.map(mall => {
        const filterMall = this.defaultShipping?.filter(item => item.mall_code == mall.mall_code) || []
        let methodInx = mall?.shipping_methods?.findIndex(method => method?.transport_type && method?.transport_type == filterMall?.[0]?.shipping_method?.transport_type)
        methodInx = methodInx == -1 ? 0 : methodInx

        return mall.mall_code + '`' + mall.shipping_methods?.[methodInx]?.shipping_time_desc
      }) || []

      des = desList?.join(',')
      return des
    },
    handleQsFreightSaParam() {
      // 1.qs的总abt没开或者多mall,是否满足传其他情况 ‘-’,勾选情况传'-'
      // 2.已满足全部商品都是qs，是否满足传其他情况 ‘-’，勾选情况传'-'
      // 3.部分qs的场景（总abt打开的情况下）
      // -未露出qs补运费的模块，表示已满足 ‘1’，勾选情况传'-'
      // -露出了qs补运费的模块，表示未满足 ‘0’，勾选情况按照实际传0或1
      let final_qs_freight_status = '-'
      const switch_qs_flag = this.switchQsFlagByAbt
      const qs_freight_status = this.defaultShipping?.[0]?.shipping_method?.qs_freight_status || 0

      if (switch_qs_flag != 0 && qs_freight_status == 1) { // 该场景下露出入口
        const { mall_params = [] } = this.caculateData || {}
        const switch_qs_flag = mall_params?.[0]?.switch_qs_flag || 0
        final_qs_freight_status = switch_qs_flag == 1 ? 1 : 0
      }

      return final_qs_freight_status
    },
    handleCouponMethodSaParam() {
      let coupon_method = '-'
      const autoUseByAbt = this.checkout.checkoutBFFAbtInfo?.Placeordercouponoptimization?.param?.place_order_coupon_optimization == 'on' ? true : false
      const auto_coupon = getQueryString({ key: 'auto_coupon' }) || ''
      if(autoUseByAbt) coupon_method = this.coupon?.applyCouponsSuccess?.map(item => item.couponCode?.toLowerCase())?.join(',') == auto_coupon.toLowerCase() ? '1' : '0'

      return coupon_method
    },
    // 点击下单按钮后上报埋点
    sendBi: function ({
      payment_method_id,
      coupon,
      points,
      result,
      result_reason,
      order_id,
    } = {}) {
      if (payment_method_id == undefined) payment_method_id = ''
      coupon == undefined || coupon == '' ? (coupon = '0') : (coupon = '1')
      points == undefined || points == '0' ? (points = '0') : (points = '1')
      
      let is_insured_goods = this.checkout.results.carts.carts.find(
        (item) => item.product.insurable
      )

      let storeInfo = [] // 店铺类型`店铺ID`商品数量
      let mallCodeSet = new Set()
      const cartsData = this.checkout?.results?.carts?.carts || []
      let overTypeSet = new Set()
      cartsData.forEach(cartItem => {
        let store = storeInfo.find(item => item.store_code == cartItem.store_code)
        if (store) {
          store.productNum += cartItem.quantity
        } else {
          storeInfo.push({
            store_code: cartItem.store_code,
            store_type: cartItem.store_type,
            productNum: cartItem.quantity,
            mall_code: cartItem.mall_code
          })
        }
        mallCodeSet.add(cartItem.mall_code)
        if(cartItem.normalFlashSale?.limit_mode == 1){
          if(cartItem.normalFlashSale.single_over == 1) { overTypeSet.add('single') }
          if(cartItem.normalFlashSale.member_over == 1) { overTypeSet.add('total') }
        }
      })
      let overType = overTypeSet.size ? overTypeSet.size == 2 ? 0 : overTypeSet.has('single') ? 'single' : 'total' : 0
      // quickship_tp：当下单商品中无quickship商品时传0，当全部是quickship时传1，当部分quickship商品时传2
      const quickshipCarts = cartsData.filter(item => item.real_quick_ship == 1) || []
      let quickship_tp = !quickshipCarts.length ? '0' : (quickshipCarts.length == cartsData.length ? '1' : '2')

      let label_id = 0
      if(typeof window != 'undefined'){
        label_id = sessionStorage.getItem('filterType')
        if(label_id == 'none' || !label_id) label_id = 0
      }


      let extraData = {}

      if (this.caculateData?.payment_code_unique === 'PayPal-GApaypal') {
        extraData.is_vaulting = this.paypalGaVault?.type === PPGA_CHECK_TYPE.CHECKED ? '1' : '0'
      }
      // 随手购新增字段
      if(this.togetherBuy?.togetherBuyType && this.togetherBuy?.addCartProducs?.length){
        Object.assign(extraData, {
          transaction_activity: 'add_more_items',
          coupon_info: this.coupon.applyCoupons.join(',')
        })
      }

      const lastPayment = this.checkout?.results?.paymentMethods.filter(
        (item) =>
          item.last_payment &&
          item.last_payment == 1 &&
          item.is_display == 1 &&
          item.enabled == 1
      )
      // 最后选择的支付方式是否是默认的支付方式
      const isSelectedDefaultPayment = lastPayment?.length && lastPayment[0]?.code == this.status?.cardPay
      // 最后选择的运输方式是否是默认的运输方式
      const isSelectedDefaultShipping = this.defaultShipping?.length && this.defaultShipping[0]?.shipping_method?.transport_type == this.initializeDefaultShipping?.default_shippingmethod

      let prime_level = 0
      if (this.usedPrimeIndex != -1) prime_level = this.primeProductList?.[this.usedPrimeIndex]?.product_cycle_type || 0

      const commonParams = {
        mall_shipping_recommend: this.handleMallBiData(1) || '',
        mall_freight_insurance: this.handleMallBiData(2) || '',
        coupon_use: this.applyCouponsSuccess.length ? '1' : '0',
        payment_method: this.caculateData.payment_code_unique,
        payment_code: this.caculateData.payment_code_unique,
        address_type: this.checkout.default_address.tag,
        promotion: this.getPromotionIsFullPromotion(),
        version: '1', // 1（代表新样式）、2（代表老样式）
        is_choose_foldedpayment: this.isChooseFoldedPayment ? 1 : 0,
        prime_level,
        prime_deduction: this.checkout?.mall_caculate_info?.prime_deduce_price?.amountWithSymbol || 0,
        prime_product_id: this.usedPrimeCode || '',
        is_insured_goods: is_insured_goods ? 1 : 0,
        shipping_marked_price: this.showMarkedPrice || 0,
        mall_code: [...mallCodeSet].join(','),
        store_info: storeInfo.map(item => `${item.store_type}\`${item.store_code}\`${item.productNum}\`${item.mall_code}`).join(','),
        quickship_tp,
        freeshipping_tp: this.checkout?.mall_caculate_info?.actual_shipping_price?.amountWithSymbol && (+this.checkout?.mall_caculate_info?.actual_shipping_price?.amount == 0) ? '0' : '1',
        over_type: overType,
        final_qs_freight_status: this.handleQsFreightSaParam(),
        Saver: this.usedXtraCode || '-',
      }
      
      sa('send', {
        'activity_name': 'click_place_order',
        'activity_param': {
          shipping_time_desc: this.handleShipTimeDesSaParam(),
          coupon_method: this.handleCouponMethodSaParam(),
          label_id,
          is_payment_default: isSelectedDefaultPayment ? 1 : 0,
          is_shipping_default: isSelectedDefaultShipping ? 1 : 0,
          product_type: 'commontype',
          ...commonParams,
          ...extraData
        }
      })
      let order_id_list = this.billnoList.join(',') || ''
      sa('send', {
        'activity_name': 'click_place_order_result',
        'activity_param': {
          payment_method_id: payment_method_id + '',
          mall_shipping_method_id: this.handleMallBiData(3) || '',
          points: points,   // points > 0   使用
          result: result,
          result_reason: result_reason,
          order_id: order_id,
          uorder_id: order_id,
          order_id_list,
          activity_method: judgeActivityIsExist(this.checkout.results.carts.carts, [3, 10]),
          ...commonParams,
          ...extraData
        }
      })
    },
    validateWithUser(opt) {
      // 15.巴西50美金税拦截（点击成单按钮 - 调用成单接口 中途拦截流程）
      const taxHintCache = sessionStorage.getItem('checkout_br_tax_hint') || false
      if (this.showTaxHint && !taxHintCache) {
        this.assignState({
          showBagTaxDialog: true,
        })

        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'br_tax_err',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })
        
        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: '4_2',
            order_id: '',
          }
        }
      }


      // 一站多合规协议
      // 4.一站多合规校验（点击成单按钮 - 调用成单接口 中途拦截流程）
      if (this.mixPrivacy.needPop) {
        this.mixPrivacy.loaded = true
        this.mixPrivacy.show = true
        this.mixPrivacy.tmp = opt

        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'mix_privacy',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })
        
        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: 'other',
            order_id: '',
          }
        }
      }


      // 5.库存校验（点击成单按钮 - 调用成单接口 中途拦截流程）
      // 库存校验
      if (this.judgeStock()) {
        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'out_stock',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })
        
        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: '4_1',
            order_id: '',
          }
        }
      }

      // 14.选中店配运输方式但无店配地址（点击成单按钮 - 调用成单接口 中途拦截流程）
      //!!!!applepay支付调用链路里不能有promise异步函数
      const isShopTransit = TRANSPORT_TYPE_SHOP.includes(this.defaultShipping[0]?.shipping_method?.transport_type)
      if (isShopTransit && !(this.checkStoreAddress())) {
        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'shop_transit_no_address',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })
        
        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: '3',
            order_id: '',
          }
        }
      }


      return { 
        notValid: false, 
        saParams: {}
      }
    },
    validateByCheckout() {
      const isOrderIntercept = (item) => {
        const interceptList = [1, 2, 3, 7]
        return this.orderInterceptByAbt && item.is_available == 0 && item.is_available_msg == 9 && interceptList.includes(+item.rule_info?.hint_type)
      }
      const { mallCode: selectMallCode } = this.selectStoreAddressShippingInfo || {}
    
      const noCheckedMallShipping = this.defaultShipping.filter(item => isOrderIntercept(item.shipping_method) && item.mall_code != selectMallCode) || []
      // 3.未选中运输方式（点击成单按钮 - 调用成单接口 中途拦截流程）
      if(!!noCheckedMallShipping.length) {
        const shippingItemEl = document.getElementById(`shipping-options__container-${noCheckedMallShipping[0].mall_code}`)
        shippingItemEl?.scrollIntoView({ block: 'center' })

        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'no_shipping_method',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })
        
        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: '2',
            order_id: '',
          }
        }
      }


      return { 
        notValid: false, 
        saParams: {}
      }
    },
    async validateByPay(opt = {}) {
      // 1.（与支付相关）token前置初始化失败 重复点击（点击成单按钮 - 调用成单接口 中途拦截流程）
      if (this.status.checkout) {
        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'payment_double_click',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })
        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: '1',
            order_id: '',
          }
        }
      }

      // 2.请求支付列表ing（与支付相关）（点击成单按钮 - 调用成单接口 中途拦截流程）
      if (this.paymentList?.loading) {
        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'payment_list_loading',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })
        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: '2',
            order_id: '',
          }
        }
      }

      //订单金额大于0，才进行相关校验
      if (this.checkout?.mall_caculate_info?.grandTotalPrice?.amount > 0) {
        // payment_id
        // 6.未选中支付方式（与支付相关）（点击成单按钮 - 调用成单接口 中途拦截流程）
        if (!this.caculateData.payment_id) {
          this.status.error.nopayment = 1
          this.$nextTick(() => {
            this.$refs.paymentOptions.$refs.noPaymentTip.focus()
          })
          const element = document.querySelector('#checkPayment')
          const topH = (element?.getBoundingClientRect().top ?? 0) + window.scrollY //该标签距离页面顶部的距离：0000
          let viewH = window.document.documentElement.clientHeight //浏览器视窗的高度：000
          let scrollH = topH - viewH / 2 //滚动到屏幕中间
          window.scrollTo({
            top: scrollH,
            behavior: 'smooth',
          })
          setTimeout(() => {
            this.shake = true
          }, 100)
          setTimeout(() => {
            this.shake = false
          }, 1000)
          this.replaceTxt.gaLabelError = 'Please select your payment method.'
          
          SIMetric.metricCount({
            metric_name: 'fe_create_order_error', // 指标名称
            tags: { // 指标维度
              SiteUID: gbCommonInfo?.SiteUID || '',
              scene: 'no_payment_id',
              productType: 'normal',
              checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
            }
          })
          return { 
            notValid: true, 
            saParams: {
              result: '0',
              result_reason: '3',
              order_id: '',
              payment_method_id: '',
              coupon: this.caculateData.coupon,
              points: this.caculateData.points
            }
          }
        }

        const cardPayMethod = this.status.cardPay
        // 没选择 bank_list
        if (this.banksConfig?.[cardPayMethod]?.list?.length) {
          const noSelectBank = !this.banksConfig[cardPayMethod].bankCode
          this.updateBanksConfig({
            [cardPayMethod]: {
              ...this.banksConfig[cardPayMethod],
              error: noSelectBank
            }
          })
          // 7.未选中银行（与支付相关）（点击成单按钮 - 调用成单接口 中途拦截流程）
          if (noSelectBank) {
            const element = document.querySelector(
              `.payment-item[type=${cardPayMethod}]`
            )
            const topH = (element?.getBoundingClientRect().top ?? 0) + window.scrollY //该标签距离页面顶部的距离：0000
            const viewH = window.document.documentElement.clientHeight //浏览器视窗的高度：000
            const scrollH = topH - viewH / 2 //滚动到屏幕中间
            window.scrollTo({
              top: scrollH,
              behavior: 'smooth'
            })
            this.replaceTxt.gaLabelError = 'Please select bank.'
            const paymentMethods = this.checkout.results.paymentMethods
            const selectedPayment = paymentMethods?.find(p => 
              p.code === cardPayMethod
            )
            this.handleStartSelectBank && this.handleStartSelectBank(selectedPayment, true)

            SIMetric.metricCount({
              metric_name: 'fe_create_order_error', // 指标名称
              tags: { // 指标维度
                SiteUID: gbCommonInfo?.SiteUID || '',
                scene: 'payment_no_select_bank',
                productType: 'normal',
                checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
              }
            })
            return { 
              notValid: true, 
              saParams: {
                result: '0',
                result_reason: '7',
                order_id: '',
                payment_method_id: this.caculateData.payment_id
              }
            }
          }
        }

        // 用户信息收集校验
        const paymentMethods = this.checkout?.results?.paymentMethods
        const selectedPayment = paymentMethods?.find(p => 
          p.code === cardPayMethod
        )
        // const noEmailInfo = (selectedPayment.collect_email == 1) && !this.capitecDialog?.email
        // const noTelInfo = (selectedPayment.collect_phone_number == 1) && !this.capitecDialog?.phone
        const isDlocalEft = cardPayMethod === 'dlocal-eft' && 
          this.banksConfig[cardPayMethod].bankCode === 'Capitec bank' && 
          (!this.capitecDialog?.email ||
          !this.capitecDialog?.phone)
        const isDlocalPse = cardPayMethod === 'dlocal-pse' && !this.capitecDialog?.email

        // dlocal-eft+非Capitec bank，不收集tel/email
        const isDlocalEftBankOthers = cardPayMethod === 'dlocal-eft' && 
          this.banksConfig[cardPayMethod].bankCode != 'Capitec bank'
        // 不是'ebanx-boleto', 'dlocal-boleto', 'adyen-mbway'(下面都有自己的判断逻辑)
        const isNotSpecial = cardPayMethod != 'adyen-mbway' && cardPayMethod != 'dlocal-boleto' && cardPayMethod != 'ebanx-boleto'

        if(!isDlocalEftBankOthers && isNotSpecial && (isDlocalEft || isDlocalPse)) {
          const element = document.querySelector(
            `.payment-item[type=${cardPayMethod}]`
          )
          const topH = (element?.getBoundingClientRect().top ?? 0) + window.scrollY //该标签距离页面顶部的距离：0000
          const viewH = window.document.documentElement.clientHeight //浏览器视窗的高度：000
          const scrollH = topH - viewH / 2 //滚动到屏幕中间
          window.scrollTo({
            top: scrollH,
            behavior: 'smooth'
          })
          this.replaceTxt.gaLabelError = 'Please select bank.'
          this.handleStartSelectBank && this.handleStartSelectBank(selectedPayment, true, true)

          SIMetric.metricCount({
            metric_name: 'fe_create_order_error', // 指标名称
            tags: { // 指标维度
              SiteUID: gbCommonInfo?.SiteUID || '',
              scene: 'payment_phone_email_empty',
              productType: 'normal',
              checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
            }
          })

          return { 
            notValid: true, 
            saParams: {
              result: '0',
              // result_reason: '7',
              order_id: '',
              payment_method_id: this.caculateData?.payment_id
            }
          }
        }

        // 8.特殊支付方式（与支付相关）worldpay-qiwi 手机号格式校验失败（点击成单按钮 - 调用成单接口 中途拦截流程）
        // qiwi
        if (this.status.cardPay == 'worldpay-qiwi') {
          if (opt && opt.type == 'phone') {
            if (this.qiwiPhoneCheck()) {
              this.replaceTxt.gaLabelError = ''

              SIMetric.metricCount({
                metric_name: 'fe_create_order_error', // 指标名称
                tags: { // 指标维度
                  SiteUID: gbCommonInfo?.SiteUID || '',
                  scene: 'payment_qiwi_phone_error',
                  productType: 'normal',
                  checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
                }
              })
              return { 
                notValid: true, 
                saParams: {
                  result: '0',
                  result_reason: '8',
                  order_id: '',
                  payment_method_id: this.caculateData.payment_id
                }
              }
            } else {
              this.status.pay.qiwiPhoneShow = false
              return { 
                notValid: false, 
                saParams: {}
              }
            }
          } else {
            this.qiwiPayPhone = ''
            this.status.error.qiwiPhone = 0
            this.status.pay.qiwiPhoneShow = true
            this.replaceTxt.gaLabelError = ''

            SIMetric.metricCount({
              metric_name: 'fe_create_order_error', // 指标名称
              tags: { // 指标维度
                SiteUID: gbCommonInfo?.SiteUID || '',
                scene: 'payment_qiwi_phone_empty',
                productType: 'normal',
                checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
              }
            })
            return { 
              notValid: true, 
              saParams: {
                result: '0',
                result_reason: '17',
                order_id: '',
                payment_method_id: this.caculateData.payment_id
              }
            }
          }
        }
      }

      //订单金额为0，默认选择第一个非cod的支付方式
      if (
        +this.checkout.mall_caculate_info.grandTotalPrice.amount == 0 &&
        (!this.caculateData.payment_id ||
          this.caculateData.payment_code_unique == 'cod')
      ) {
        let payment = this.checkout.results.paymentMethods.filter((item) => {
          return item.code != 'cod' && item.enabled
        })
        if (payment.length && payment[0] && payment[0].enabled != 0) {
          this.status.cardPay = payment[0].code
          this.caculateData.payment_id = payment[0].id
          this.caculateData.payment_code_unique = payment[0].code
        } else {
          // 12.当前未选中支付方式（与支付相关）订单金额为0，默认选择第一个非cod的支付方式，如果第一个支付方式不可用时阻止后续操作（点击成单按钮 - 调用成单接口 中途拦截流程）
          SIMetric.metricCount({
            metric_name: 'fe_create_order_error', // 指标名称
            tags: { // 指标维度
              SiteUID: gbCommonInfo?.SiteUID || '',
              scene: 'payment_amount_zero_no_select_payment',
              productType: 'normal',
              checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
            }
          })
          return { 
            notValid: true,
            saParams: {
              result: '0',
              result_reason: '12', // 待修改
              order_id: '',
            }
          }
        }
      }

      // 9.特殊支付方式（与支付相关）adyen-fpx支付方式的升级银行app弹窗提示
      if (this.checkShowFpxTips(this.caculateData.payment_code_unique)){
        const res = await this.showFpxTips(this.caculateData.payment_code_unique)
        // 点击弹窗的关闭按钮
        if (res === 'cancel') {
          SIMetric.metricCount({
            metric_name: 'fe_create_order_error', // 指标名称
            tags: { // 指标维度
              SiteUID: gbCommonInfo?.SiteUID || '',
              scene: 'payment_fpx_upgrade_app_cancel',
              productType: 'normal',
              checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
            }
          })
          return { 
            notValid: true, 
            saParams: {
              result: '0',
              result_reason: '9',
              order_id: '',
              payment_method_id: this.caculateData.payment_id
            }
          }
        }
      }

      const paymentMethods = this.checkout?.results?.paymentMethods
      const selectedPayment = paymentMethods?.find(p => 
        p.code === this.caculateData.payment_code_unique
      )
      
      // 10.特殊支付方式（与支付相关）adyen-mbway支付收集手机号弹窗（点击成单按钮 - 调用成单接口 中途拦截流程）
      //adyen-mbway支付填写电话
      const needTel = selectedPayment.collect_phone_number == 1
      if (
        this.caculateData.payment_code_unique == 'adyen-mbway' &&
        !opt?.mbwayConfirValid && needTel
      ) {
        this.status.checkout = 1
        await this.showMbwayDialog()
        if (this.mbwayInfo.dialogShow) {
          daEventCenter.triggerNotice({ daId: '1-11-1-51' })
        }
        this.status.checkout = 0

        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'payment_mbway_phone_empty',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })
        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: '10',
            order_id: '',
            payment_method_id: this.caculateData.payment_id
          }
        }
      }

      // 11.特殊支付方式（与支付相关）boleto邮箱收集弹窗（点击成单按钮 - 调用成单接口 中途拦截流程）
      //br巴西站boleto邮箱
      const needEmail = selectedPayment.collect_email == 1
      if (
        ['ebanx-boleto', 'dlocal-boleto'].includes(this.caculateData.payment_code_unique)
      ) {
        if (
          this.checkout.mall_caculate_info.grandTotalPrice &&
          +this.checkout.mall_caculate_info.grandTotalPrice.amount != 0
        ) {
          if (opt && opt.checkEmail) {
          } else if (needEmail) {
            let uEmail = UserInfoManager.getSync({ key: 'userinfo_email', actionType: '/checkout/createOrder' }) || ''
            this.emailTips.val = uEmail
            this.emailTips.err = ''
            this.emailTips.show = true

            SIMetric.metricCount({
              metric_name: 'fe_create_order_error', // 指标名称
              tags: { // 指标维度
                SiteUID: gbCommonInfo?.SiteUID || '',
                scene: 'payment_boleto_email_empty',
                productType: 'normal',
                checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
              }
            })
            return { 
              notValid: true, 
              saParams: {
                result: '0',
                result_reason: '11',
                order_id: '',
                payment_method_id: this.caculateData.payment_id
              }
            }
          }
        }
      }

      const self = this
      if (
        ['afterpay-card', 'afterpay-clearpay', 'afterpay-cashapp'].includes(
          this.caculateData.payment_code_unique
        )
      ) {
        this.status.checkout = 1
        if (document.getElementById('afterpay-checkout-script')) {
          document.getElementById('afterpay-checkout-script').remove()
        }
        let code = this.caculateData.payment_code_unique

        let src = ''
        let isSandBox = ['debug', 'localhost'].includes(gbCommonInfo?.NODE_SERVER_ENV)
        if (code == 'afterpay-card' || code === 'afterpay-cashapp') {
          src = isSandBox
            ? 'https://portal.sandbox.afterpay.com/afterpay.js'
            : 'https://portal.afterpay.com/afterpay.js'
        } else if (code == 'afterpay-clearpay') {
          src = isSandBox
            ? 'https://portal.sandbox.clearpay.co.uk/afterpay.js'
            : 'https://portal.clearpay.co.uk/afterpay.js'
        }

        let isValid = true

        await asyncLoadFile({
          label: 'script',
          attrs: { src, id: 'afterpay-checkout-script' },
        })
          .then(() => { })
          .catch((err) => {
            isValid = false
            blackMask.showMask = !blackMask.showMask
            blackMask.text = self.language.SHEIN_KEY_PC_16175
            console.log('fail afterpay.js: ', err)
          })
        this.status.checkout = 0
        if (!isValid) {
          // 13.特殊支付方式（与支付相关）afterpay支付脚本加载失败
          SIMetric.metricCount({
            metric_name: 'fe_create_order_error', // 指标名称
            tags: { // 指标维度
              SiteUID: gbCommonInfo?.SiteUID || '',
              scene: 'payment_afterpay_script_error',
              productType: 'normal',
              checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
            }
          })
          return { 
            notValid: true, 
            saParams: {
              result: '0',
              result_reason: '13',
              order_id: '',
              payment_method_id: this.caculateData.payment_id
            }
          }
        }
      }

      // console.log('secondCheck >>>>', this.isMallOrderCreateFail)

      // （与支付相关）卡分期支付前置弹窗
      // isUsePrePayForCheckout 是否开启卡分期前置 包括abt开启及选中routepay-cardinstallment
      // prePayForCheckoutReady 卡分期前置信息是否已填充完成
      // isMallOrderCreateFail 创单失败二次校验
      // opt?.skipPrePayReady 卡分期支付跳过校验 - 目前场景仅下单卡bin冻结失败，用户选择舍弃卡bin优惠继续执行下单
      // opt?.prePayCardVaildFail 卡分期支付前置卡信息格式校验失败 - 仅单前失败会走到这里
      if (
        this.isUsePrePayForCheckout && 
        (!this.prePayForCheckoutReady || this.isMallOrderCreateFail) && 
        !opt?.skipPrePayReady &&
        +this.checkout.mall_caculate_info.grandTotalPrice.amount != 0
      ) {

        // 支付相关信息格式校验失败场景
        if (opt?.prePayCardVaildFail) {
          SIMetric.metricCount({
            metric_name: 'fe_create_order_error', // 指标名称
            tags: { // 指标维度
              SiteUID: gbCommonInfo?.SiteUID || '',
              scene: 'pre_pay_card_vaild_fail',
              productType: 'normal',
              checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
            }
          })
          return { 
            notValid: true, 
            saParams: {
              result: '0',
              result_reason: '20',
              order_id: '',
              payment_method_id: this.caculateData?.payment_id
            }
          }
        }
        
        // 需要二次 check 的场景
        if (this.isMallOrderCreateFail) {
          this.assignState({ 
            isMallOrderCreateFail: false 
          })
          this.fullLoading = true
          const checkData = await this.checkPrePayParamsChange({
            currentDefaultAddress: this.checkout.default_address,
            normalGoodsList: this.checkout?.results?.carts?.carts || [],
            usedPrimeProductInfo: this.primeProductList?.[this.usedPrimeIndex] || {},
            usedXtraProductInfo: this.usedXtraCode && this.xtraProductInfo || {},
            routeCode: this.status.cardPay,
          })
          this.fullLoading = false

          if (!checkData.checkResult && this.isCheckCardinstallmentInfoStatus) {
            this.assignState({ prePayForCheckoutReady: true })
            return { 
              notValid: false, 
              saParams: {}
            }
          } else {
            this.prePayMergeState('cardBinRoutingInfo', checkData.cardBinRoutingInfo || {})
            this.prePayMergeState('prePayUnPayParams', { 
              cardBin: checkData.cardBinRoutingInfo.bin,
              routeId: checkData.cardBinRoutingInfo.routeId,
              paymentCode: checkData.cardBinRoutingInfo.payMethod,
              countryCode: checkData.cardBinRoutingInfo.orderCurrency,
              cardType: checkData.cardBinRoutingInfo.cardType
            })
            this.prePayUpdateState('installmentInfo', checkData.installments)
          }
        }
        const prePayCheckoutInfo = this.formatPrePayCheckoutInfo({
          mallCaculateInfo: this.checkout.mall_caculate_info,
          defaultAddress: this.checkout.default_address,
          normalGoodsList: this.checkout?.results?.carts?.carts || [],
          usedPrimeProductInfo: this.primeProductList?.[this.usedPrimeIndex] || {},
          usedXtraProductInfo: this.usedXtraCode && this.xtraProductInfo || {},
          routeCode: this.status.cardPay,
          bin_discount_list: this.checkout?.results?.bin_discount_list || [],
          bin_discount_info: this.checkout.results?.bin_discount_info || {},
          isAutoRenewal: this.isAutoRenewal
        })
        this.updatePrePaySetCheckoutInfo(prePayCheckoutInfo)
        if (!this.isMallOrderCreateFail) {
          this.prePayGetInstallmentInfo()
        }
        this.visiblePrePay = true
        daEventCenter.triggerNotice({
          daId: '2-41-2'
        })
        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'pre_pay_checkout_dialog_visible',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })

        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: '19',
            order_id: '',
            payment_method_id: this.caculateData?.payment_id
          }
        }
      } else {
        this.assignState({ prePayForCheckoutReady: false })
      }

      // 特殊支付方式（与支付相关）（点击成单按钮 - 调用成单接口 中途拦截流程）
      // 是否需要调起路由卡分期分期选择抽屉
      // console.log('this.selectInstallment >>>>', this.selectInstallment)
      if (this.isSelectInstallmentTokenPay && Object.keys(this.selectInstallment || {}).length === 0 && +this.checkout.mall_caculate_info.grandTotalPrice.amount != 0) {
        this.status.checkout = 0
        this.assignState({ isShowInstallmentListDialog: true })
        
        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'payment_token_installment_empty',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })
        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: '16',
            order_id: '',
            payment_method_id: this.caculateData.payment_id
          }
        }
      }

      // 16.特殊支付方式（与支付相关）token前置收集cvv弹窗）
      // 调起cvv
      if (this.isSelectTokenPay && (this.defaultSelectTokenPay?.need_cvv === 1 || this.defaultSelectTokenPay?.needCvv === 1) && (!this.tokenCvv && !this.isMAESTRO)) {
        this.status.checkout = 0
        this.assignState({ isShowCvvDialog: true })
        SIMetric.metricCount({
          metric_name: 'fe_create_order_error', // 指标名称
          tags: { // 指标维度
            SiteUID: gbCommonInfo?.SiteUID || '',
            scene: 'payment_token_cvv_empty',
            productType: 'normal',
            checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
          }
        })
        return { 
          notValid: true, 
          saParams: {
            result: '0',
            result_reason: '16',
            order_id: '',
            payment_method_id: this.caculateData.payment_id
          }
        }
      }

      return { 
        notValid: false, 
        saParams: {}
      }
    },
    /**
     * 创建订单校验
     * 此处注释 注意注意注意！！！！
     * 为方便后续维护，下单线与支付线分开维护！！！！！！（除下方1.有优先级）
     * 该方法分三个函数：
     * 1. validateWithUser 有优先级（return前有引导用户交互的流程，例如：弹窗、抽屉等，toast提示不算！！！）（该方法中包含下单、支付的拦截流程）
     * 2. validateByCheckout 下单拦截的函数
     * 3. validateByPay 支付拦截的函数
     * 
     * return 返回值：
     * 1.notValid是否校验通过（Boolean）
     * 2.saParams 成单埋点上报的参数(Object)
     */
    async validateCreateOrder(opt = {}) {
      // 存在用户交互
      const { notValid: notValidUser, saParams: saParamsUser } = this.validateWithUser(opt)
      if(notValidUser) {
        return { 
          notValid: true, 
          saParams: saParamsUser
        }
      }
      // 下单拦截函数
      const { notValid: notValidCheckout, saParams: saParamsCheckout } = this.validateByCheckout()
      if(notValidCheckout) {
        return { 
          notValid: true, 
          saParams: saParamsCheckout
        }
      }

      // 支付拦截函数
      const { notValid: notValidPay, saParams: saParamsPay } = await this.validateByPay(opt)
      if(notValidPay) {
        return { 
          notValid: true, 
          saParams: saParamsPay
        }
      }

      return { 
        notValid: false, 
        saParams: {}
      }
    },
    // 创建订单
    async createOrder(opt = {}) {
      console.log('createOrder', opt)
      SIMetric.metricCount({
        metric_name: 'fe_click_place_order', // 指标名称
        tags: { // 指标维度
          SiteUID: gbCommonInfo?.SiteUID || '',
          productType: 'normal',
          checkoutType: !!this.buyNowNo ? 'buynow' : 'normal',
        }
      })

      // 注意！！！所有成单前拦截校验逻辑请写入该函数！！！
      const { notValid = false, saParams = {} } = await this.validateCreateOrder(opt)
      // 前方请不要道路拥堵！！！禁止插入return！！！只要点击下单按钮均需要上报埋点及监控！！！
      if(notValid) {
        // 埋点待后续提需求修改，暂时注释
        // 校验未通过在此处上报埋点，校验通过在成单接口返回后上报埋点
        // this.sendBi({
        //   payment_method_id: this.caculateData.payment_id,
        //   coupon: this.caculateData.coupon,
        //   points: this.caculateData.points,
        //   ...saParams
        // })

        return
      }


      var self = this
      var _caculateData = Object.assign({}, self.caculateData)

      if (self.couponErrorShow) {
        _caculateData.coupon = ''
      }

      if (
        this.checkout.mall_caculate_info.giftcard &&
        this.checkout.mall_caculate_info.giftcard.giftcardNo
      ) {
        var giftcard = this.checkout.mall_caculate_info.giftcard
        _caculateData.card_no = giftcard.giftcardNo
        _caculateData.card_pin = giftcard.giftcardPin
      } else {
        _caculateData.card_no = ''
        _caculateData.card_pin = ''
      }

      const isShopTransit = TRANSPORT_TYPE_SHOP.includes(this.defaultShipping[0]?.shipping_method?.transport_type)
      _caculateData.is_shop_transit = isShopTransit ? 1 : 0
      _caculateData.shop_transit_country_id = isShopTransit ? this.checkout.default_address.countryId : 0


      let isAsync = true
      if (this.applePayMethod.indexOf(this.caculateData.payment_code_unique) > -1) {
        isAsync = false
      }

      //paypal-ga支付
      if (
        this.judgeNeedPayInlineChannel(this.caculateData.payment_code_unique) ==
        'paypal'
      ) {
        isAsync = false
      }

      //针对异步的paypal记录
      if (this.caculateData.payment_code_unique == 'PayPal-GApaypal') {
        apiReport.report({
          apiPath: 'paypal-async-catch',
          isAsync: isAsync,
          channel:
            this.judgeNeedPayInlineChannel(
              this.caculateData.payment_code_unique
            ) || '-',
        })
      }


      const couponAutoUseData = { 
        coupon: this.applyCouponsSuccess?.join() || _caculateData.coupon || ''
      }
      const ajaxData = Object.assign(
        {
          fullWalletNoCurr: this.fullWalletNoCurr ? 1 : 0,
          countryCode: this.checkout.default_address?.value || ''
        },
        { ..._caculateData, ...couponAutoUseData }
      )

      ajaxData.giftcard_verify = this.giftCardLimit.verify
      ajaxData.blackbox = window._fmOpt?.__blackbox || '' // 同盾
      ajaxData.risk_id = this.placeOrderRiskId || ''
      ajaxData.affId = getLocalStorage('affId')
      ajaxData.originOtherId = getLocalStorage('originOtherId')
      this.adult == 1 && (ajaxData.adult = 1)  // 刀具商品确定是否满18岁

      if (!this.isNoNeedCardBin) {
        ajaxData.selected_bin = this.isSelectInstallmentTokenPay ? this.installmentSelectToken?.card_bin : (this.handlerSelectBin || this.defaultSelectTokenPay?.card_bin) || ''
        if (this.isSelectInstallmentTokenPay) {
          ajaxData.selected_bin = this.installmentSelectBin || ''
        }
        if (this.isUsePrePayForCheckout && this.prePayUnPayParams?.cardBin) {
          ajaxData.selected_bin = this.prePayUnPayParams.cardBin
        }
        this.updateIsNoNeedCardBin && this.updateIsNoNeedCardBin(false)
      }
      
      // 当选中权益包是续费产品包（product_activity_type为1）的时候，增加vip_order_source字段
      if (this.usedPrimeCode) {
        const selectPrimeInfo = this.primeProductList?.filter(item => item?.product_code == this.usedPrimeCode)
        const vipOrderSource = selectPrimeInfo?.length && selectPrimeInfo[0].product_activity_type == 1
        
        if (vipOrderSource) {
          ajaxData.vip_order_source = 1
        }
      }

      //选中的超省卡包是自动续费包
      if (this.usedXtraCode) {
        const currentXtraIsAuto = this.xtraAutoController.currentXtraIsAuto
        if (currentXtraIsAuto) {
          ajaxData.save_card_vip_order_source = 1
        }
      }

      if(self){
        Object.assign(ajaxData, { businessModelList: cartBusinessModelList(self) })
      }

      let orderRes = null
      // 卡路由卡分期前置场景不跳转卡支付页
      const unSetLtspcJumpUrl = self.isSelectTokenPay || self.isSelectInstallmentTokenPay || self.isUsePrePayForCheckout
      typeof window?.ltspcInit === 'function' && window.ltspcInit({ payCode: ajaxData?.payment_code_unique }, { unSetLtspcJumpUrl })
      // 创建订单前的按钮loading加载
      this.status.checkout = 1

      this.assignState({
        newCheckoutLoadingType: 'create-order'
      })

      // 创建订单接口
      window._GB_DeviceFingerPrint.callFunc(() => {
        checkSchttp({
          isAsync,
          opt: {
            method: 'POST',
            url: '/api/checkout/mallOrder/create',
            schttp: {
              needLogin: true,
            },
            data: ajaxData,
          },
          successCb: (res) => {
            const { code, info } = res || {}
            const captcha = info?.risk_info?.extend_info?.actions?.[0]?.name || ''
            const { risk_decision }  = info?.risk_info || ''
            if (
              code == 0 &&
              ['routepay-card', 'routepay-cardinstallment'].includes(info?.order?.payment_method) && 
              !self.isSelectTokenPay && 
              !self.isSelectInstallmentTokenPay &&
              !self.isUsePrePayForCheckout &&
              !info?.order?.isPaid
            ) {
              checkoutMarkPoint.markDurationSeconds({
                scene: 'CheckoutCreateOrderSuccess',
                reportData: {
                  checkout_type: 'normal',
                  billno: info?.order?.billno || ''
                }
              })
            }

            if (code != 0) {
              self.status.checkout = 0
              self.assignState({ 
                selectInstallment: {},
                isShowCvvDialog: false,
                isMallOrderCreateFail: code != '302206',
                newCheckoutLoadingType: '' 
              })
              self.setTokenPayCvv()
            } else {
              self.assignState({
                isMallOrderCreateFail: false,
                newCheckoutLoadingType: 'order-to-pay'
              })
            }

            // 当创建订单成功的时候，需要触发homeCccxCouponUpdate事件，首页监听这个事件去更新劵包信息
            window.sessionStorage.setItem('homeCccxCouponUpdate', true)

            if(code == 302209 && risk_decision == 0 && (captcha == 'email_captcha' || captcha == 'phone_captcha')) {
              import('public/src/pages/common/RiskCommon/index.js').then(({ default: riskDialog }) => {
                res.source = 'checkout'
                riskDialog.inifConfig({
                  data: res,
                  confirmCb: (risk_id) => {
                    if(risk_id) {
                      this.placeOrderRiskId = risk_id
                    }
                    //paypal-ga支付 风控通过不自动调，需手动再点击下单
                    if (isAsync) {
                      this.createOrder()
                    }
                  }
                })
              })
              self.status.checkout = 0
              return false
            }
            if ((code == 300411 || code == 302209) && (captcha == 'change_pwd_leak' || captcha == 'change_pwd_blasting')){
              import('public/src/pages/common/riskControlDialog/index.js').then(({ default: riskSource })=>{
                riskSource.initConfig({
                  data: res,
                  language: this.language,
                  loginModalSucCb: () => {
                    //paypal-ga支付 风控通过不自动调，需手动再点击下单
                    if (isAsync) {
                      this.createOrder()
                    }
                  }
                })
              })
              self.status.checkout = 0
              return false
            }
            if( code == 302209 && risk_decision == 2) {
              const riskTipsMap = {
                'low_risk_copy': this.language.SHEIN_KEY_PC_29168,
                'high_risk_copy': this.language.SHEIN_KEY_PC_29169,
                'default_risk': this.language.SHEIN_KEY_PC_29170
              }
              import('public/src/pages/common/riskControlDialog/index.js').then(({ default: riskSource }) => {
                riskSource.showRiskDialogFn({
                  data: res,
                  language: this.language,
                  riskDialogTips: riskTipsMap[captcha] || riskTipsMap['default_risk']
                })
              })
              self.status.checkout = 0
              return false
            }

            if (code == 302206) {
              self.status.checkout = 0
              this.updateCreateOrderBinDialogShow(true)
              return false
            }
            // 兼容处理订单号
            if (res?.info?.order?.relation_billno) {
              res.info.order.billno = res.info.order.relation_billno
            }
            //兼容处理子订单列表
            if (res?.info?.order?.order_group) {
              res.info.order.sub_billnos = res.info.order.order_group.map(
                (item) => {
                  return { sub_billno: item.billno }
                }
              )

              self.billnoList =
                res.info.order.sub_billnos?.map((item) => item.sub_billno) || []
            }
            orderRes = { res }
            
            if (res.code == 0) {
              // checkout-btn 埋点
              this.sendBi({
                result: '1',
                result_reason: '',
                order_id: res?.info?.order?.billno || '',
                payment_method_id: _caculateData.payment_id,
                coupon: _caculateData.coupon,
                points: _caculateData.points,
              })
            } else if (
              res.code == 400319 ||
              res.code == 401959 ||
              res.code == 401960
            ) {
              // checkout-btn 埋点
              self.sendBi({
                result: '0',
                result_reason: '2',
                order_id: res?.info?.order?.billno || '',
                payment_method_id: _caculateData.payment_id,
                coupon: _caculateData.coupon,
                points: _caculateData.points,
              })
            } else {
              // checkout-btn 埋点
              self.sendBi({
                result: '0',
                result_reason: res.code,
                order_id: res?.info?.order?.billno || '',
                payment_method_id: _caculateData.payment_id,
                coupon: _caculateData.coupon,
                points: _caculateData.points,
              })
            }

            self.giftCardLimit.clickConfirm &&
              daEventCenter.triggerNotice({
                daId: '1-11-1-42',
                extraData: {
                  order_id: (res.code == 0 && res?.info?.order?.billno) || '',
                  uorder_id: (res.code == 0 && res?.info?.order?.billno) || '',
                  order_id_list: self.billnoList.join(',') || '',
                  result: res.code == 0,
                },
              })
            if (res.code == 0) {
              removeCookie({ key: 'checkoutCache' })
              this.delCacheBysetLocalStorage('checkcache')

              self.orderDate = res.info.order

              if (
                GooglePay.googlePayMethods.includes(
                  self.orderDate.payment_method
                ) &&
                !self.orderDate.isPaid
              ) {
                self.status.checkout = 0
                GooglePay.eventCenter.emit('showGooglePay', {
                  code: self.orderDate.payment_method,
                  orderInfo: {
                    ...self.orderDate,
                    totalPrice: self.orderDate?.totalPrice?.amount,
                    totalPriceWithGovTax: self.orderDate?.grandTotalWithGovTax?.amount
                  },
                })
              } else {
                self.handleCancelLink(self.orderDate)
                if (
                  this.applePayMethod.indexOf(self.orderDate.payment_method) > -1
                ) {
                  self.orderDate['countryCode'] =
                    self.checkout?.default_address?.value || 'US'
                }

                //如果是paypal-ga，则先执行sdk获得参数后再执行提交支付接口，且不是走继续签约
                if (
                  self.chosenChannel == 'paypal' &&
                  self.orderDate.payment_method == 'PayPal-GApaypal' &&
                  self.paypalGaBtn &&
                  !self.isContinueSign &&
                  !self.orderDate.isPaid
                ) {
                  return
                }

                //如果是paypal-venmo，则先执行sdk获得参数后再执行提交支付接口，且不是走继续签约
                if (
                  self.chosenChannel == 'paypal' &&
                  self.paypalVenmoBtnShow &&
                  self.orderDate.payment_method == 'PayPal-Venmo' &&
                  !self.orderDate.isPaid
                ) {
                  self.venmoPay()
                  return
                }
                if (['adyen-blikdirect', 'alipay-blik', 'routepay-blik'].includes(self.orderDate.payment_method)) {
                  self.status.checkout = 0
                  self.blikCode = Object.assign(self.blikCode, {
                    loading: false,
                    pending: false,
                    error: false,
                    show: true,
                    loaded: true
                  })
                  self.$nextTick(() => {
                    this.$refs?.blikCodeCom?.initSelectedEvt()
                  })
                  return
                }

                if (self.orderDate.payment_method == 'paidy-paylater') {
                  PaidyPay.eventCenter.emit('paidy-pay-launch', {
                    order: self.orderDate
                  })
                  return
                }

                let jump_pci_token = res.info.order.jump_pci_token

                if (self.isUsePrePayForCheckout && +self.checkout?.mall_caculate_info?.grandTotalPrice?.amount != 0) {
                  // console.log('isUsePrePayForCheckout >>>>', self.isUsePrePayForCheckout, self.prePayUnPayParams)
                  self.handlePreUnPay()
                  return
                }

                const isRoutePayCardToken = self.orderDate.payment_method === 'routepay-card' && self.isSelectTokenPay  // 卡token支付前置
                const isInstallmentToken = self.orderDate.payment_method === 'routepay-cardinstallment' && self.isSelectInstallmentTokenPay // 卡分期token支付前置

                if (!self.isUsePrePayForCheckout && (isRoutePayCardToken || isInstallmentToken)) {
                  const nowTokenData = isRoutePayCardToken ? self.defaultSelectTokenPay : self.installmentSelectToken
                  const memberPreCardTokenInfo = isRoutePayCardToken ? self.checkout?.memberPreCardTokenInfo : self.checkout?.installmentMemberPreCardTokenInfo
                  self.setTokenPayInfo({
                    selectedTokenPayInfo: nowTokenData,
                    ddcInfo: {
                      formActionUrl: memberPreCardTokenInfo?.data?.info?.formActionUrl || '',
                      jwt: memberPreCardTokenInfo?.data?.info?.jwt || ''
                    },
                    tokenList: memberPreCardTokenInfo?.data?.info?.tokenList || [],
                    necessaryOrderInfo: {
                      totalPrice: self.orderDate.totalPrice || {},
                      sub_billnos: self.orderDate.sub_billnos || [],
                      billno: self.orderDate?.billno || ''
                    }
                  })
                  const params = {
                    id: nowTokenData?.id || '',
                    card_bin: nowTokenData?.card_bin || '',
                    last_four_no: nowTokenData?.last_four_no || '',
                    countryCode: self.checkout?.default_address?.value?.toUpperCase?.() || 'US',
                    billno: self.orderDate?.billno || '',
                    payment_method: self.orderDate.payment_method
                  }

                  if (isInstallmentToken) {
                    params.installments = self.selectInstallment?.stage_num
                  }

                  const handleTokenPayFunc = (params) => {
                    self.initTransformPci({
                      SiteUID: gbCommonInfo?.SiteUID,
                      lang: gbCommonInfo?.lang,
                      AppCurrency: self.currencyCode || gbCommonInfo?.currency,
                      siteFrom: 'pc'
                    }).then(() => {
                      self.handleTokenPay({ 
                        params,
                        onPayComplete: ({ status, result }) => {
                          const { host, langPath } = gbCommonInfo || {}
                          const { sub_billnos, billno, relation_billno = '' } = self.orderDate || {}
                          if (status === 'success') {
                            self.assignState({
                              newCheckoutLoadingType: 'pay-success'
                            })
                            replacePaySuccessReferer()
                            window.location.href = `${host}${langPath}/pay/result/success?billno=${billno}&type=${self.orderDate?.payment_method || ''}&gatewayPayNo=${result?.info?.gatewayPayNo || ''}`
                          } else if (status === 'pending') {
                            self.assignState({
                              newCheckoutLoadingType: ''
                            })

                            window.location.href = `${host}${langPath}/pay/result/pending?billno=${billno}`
                          } else if (status === 'fail') {
                            self.assignState({
                              newCheckoutLoadingType: ''
                            })
                            self.fullLoading = false
                            if (self.status) self.status.checkout = 0
                            if (result?.code == '300317') {
                              replacePaySuccessReferer()
                              window.location.href = `${host}${langPath}/pay/result/success?billno=${billno}&type=${self.orderDate?.payment_method || ''}&gatewayPayNo=${result?.info?.gatewayPayNo || ''}`
                              return
                            }
                            blackMask.showMask = true
                            blackMask.type = 'payError'
                            blackMask.text = result?.info?.show_error_msg || result?.info?.error_msg || self.language.SHEIN_KEY_PC_30360
                            blackMask.hasGuide = 
                            blackMask.cb = function (isClickClose) {
                              if (isClickClose) {
                                daEventCenter.triggerNotice({
                                  daId: '1-19-1-5',
                                  extraData: {
                                    error_code: result?.info?.error_code || '',
                                    order_no: billno,
                                    order_id_list: sub_billnos?.map(v => v.sub_billno).join(','),
                                    uorder_id: relation_billno
                                  },
                                })
                              } else {
                                daEventCenter.triggerNotice({
                                  daId: '1-19-1-4',
                                })
                              }
                              routerReplace(host + '/checkout/checkout_again/' + billno)
                            }
                          }
                        },
                        unHandleResultCb: () => {
                          self.assignState({
                            newCheckoutLoadingType: ''
                          })
                        }
                      })
                    })
                  }

                  if (self.ddcInfo.formActionUrl && self.ddcInfo.jwt) {
                    self.initPayJsonWebToken(self.ddcInfo)
                    self.sendDdc({
                      cardBin: self.selectedTokenPayInfo?.card_bin || '',
                      callback: (sessionId = '') => {
                        params.sessionId = sessionId
                        handleTokenPayFunc(params)
                      }
                    })
                  } else {
                    handleTokenPayFunc(params)
                  }

                  return
                }

                window.GB_handlePayMethods?.handlePay?.(
                  self.orderDate,
                  self,
                  jump_pci_token
                )
              }
            } else {
              self.status.checkout = 0
              const addressError = [
                400319, 401959, 401960, 401970, 401971, 401974, 401986, 401914, 401984, 401977, 402000, 401997, 401998, 401999, 402002, 402006, ...ADDRESS_ERROR_CODE
              ].includes(+res.code)
              if (addressError) {
                if (res.code == 401974) {
                  self.modalTipsShow = true
                } else {
                  var address = {
                    ...self.checkout.default_address,
                    new_tel: self.checkout.default_address.tel
                  }
                  self.status.addressCompOpen = true
                  self.$refs?.addressCom?.$refs?.addressVue?.loading(false)
                  self.$nextTick(function () {
                    const typeAddress = [...ADDRESS_ERROR_CODE, 402006].includes(+res.code) ? 'supplement' : 'edit'
                    self.$refs?.addressCom?.$refs?.addressVue?.updateUI(
                      typeAddress,
                      address,
                      function () {
                        setTimeout(function () {
                          if (res.code == 401959) {
                            self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('state')
                          } else if (res.code == 401960) {
                            self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('city')
                          } else if (res.code == 401970) {
                            self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('postcode', 4)
                          } else if ([401971, 401986, 401984, 402000, 401997, 401998, 401999, 402002, ...ADDRESS_ERROR_CODE, 402006].includes(Number(res.code))) {
                            self.$refs?.addressCom?.$refs?.addressVue?.check?.addressCheckError?.({
                              code: Number(res.code),
                            })
                          } else if([401914, 401977, 402006].includes(Number(res.code))) {
                            self.$refs?.addressCom?.$refs?.addressVue?.universalToast(res.tips || '')
                          }
                        }, 300)
                      }
                    )
                    self.sendAddressAnalysis(
                      {
                        page_type: 'edit',
                        activity_from: 'checkout_passive',
                      },
                      { type: 'expose', from: 'checkout_passive' }
                    )
                  })
                }
              } else if (res.code == 300352) {
                ; /!*黑名单*!/
                blackMask.showMask = !blackMask.showMask
                blackMask.text = self.language.SHEIN_KEY_PC_14786
              } else if (res.code == 300356) {
                self.status.orderUnpaid = true
              } else if (res.code == 302300) {
                blackMask.showMask = true
                blackMask.text = res.tips || res.msg
              } else if (res.code == 301302 && res.info?.outStockCarts) {
                // 没有库存
                self.judgeStock(res.info.outStockCarts)
              } else if (res.code == 300401) {
                self.assignState({
                  showCartNullDialog: true
                })
                self.assignState({
                  nullDialogTip: res.tips
                })
                // const redirectUrl = (!!this.buyNowNo && !!this.buyNowFromUrl) ? this.buyNowFromUrl : '/cart'
                // window.location.href = redirectUrl
              } else if (res.code == 300405) {
                //闪购超过数量
                self.updateCheckoutState({ key: 'limitTip', val: {
                  show: true,
                  txt: self.language.SHEIN_KEY_PC_17483,
                  type: 'flashLimit'
                } })
                if (typeof sa === 'function') {
                  sa('send', { activity_name: 'expose_reachedflashsalelimit' })
                }
              } else if (res.code == 300412) {
                sa('send', {
                  activity_name: 'expose_checkoutpurchaselimitation',
                })
                let ids = []
                let idsArr = (res.info && res.info.limitProducts) || res.info
                if (idsArr && idsArr.length) {
                  idsArr.forEach((item) => {
                    if(item.typeId == 24) {
                      if (!ids.includes(item.id)) {
                        ids.push(item.id)
                      }
                    }
                  })
                }
                self.changeParamsStatus({
                  type: 'commonDialogInfo',
                  params: {
                    show: true,
                    tip: res.tips || self.language.SHEIN_KEY_PC_17483,
                    btnTxt: self.language.SHEIN_KEY_PC_30100,
                    closeOnClickModal: false,
                    cb: ()=>{
                      self.commonDialogInfo.show = false
                      const redirectUrl = (!!this.buyNowNo && !!this.buyNowFromUrl) ? this.buyNowFromUrl : ('/cart' + ( ids.length ? '?isDiscountLimit=' + ids.join('_') : ''))
                      window.location.href = redirectUrl
                    }
                  }
                })
              } else if (res.code == 300625) {
                // 买券失效
                self.showTip(self.language.SHEIN_KEY_PC_17392)
                self.clearCouponUpdateCalculator()
              } else if (res.code == 302446) {
                //免邮券限制存在受限商品就不可用，购物车中有受限产品
                let msg = self.template(
                  self.language.SHEIN_KEY_PC_19503,
                  self.language['302446']
                )
                self.showTip(msg)
              } else if (res.code == 302447) {
                //免邮券限制存在受限商品就不可用，购物车中有受限产品
                //报错的优惠券

                let min = res?.info?.couponMinOrder?.amountWithSymbol
                let msg = self.template(min, self.language['302447'])
                self.showTip(msg)
              } else if (res.code == 302448) {
                //免邮券排除受限产品达到门槛可用。但是购物车没有非受限产品
                self.showTip(self.language['302448'])
              } else if (res.code == 302202 && res.info) {
                //下单限制
                const { hint_type, match_carts = [] } = res.info
                //hint_type   0：不涉及商品，1：删除全部商品，2：删除部分商品, 3: [俄罗斯护照校验, 以色列117美金], 4: 南非特殊商品下单限制
                if (hint_type == 3) {
                  const { default_address = {}, AWAKEN_ADDRESS_SUPPLEMENT_COUNTRIES = {} } = self.checkout || {}
                  const countryId = +default_address?.countryId
                  const awakenSupplementInfoContryId = [] // 需要吊起弹窗的国家码
                  const storeTransitCountryId = [] // 店配的国家码
                  const haveTitleTipsCountryId = [] // 有提示的国家码

                  Object.values(AWAKEN_ADDRESS_SUPPLEMENT_COUNTRIES)?.forEach(item => {
                    if (item.countryId) {
                      awakenSupplementInfoContryId.push(item.countryId)
                      item.haveStoreTransit && storeTransitCountryId.push(item.countryId)
                      item.haveTitleTips && haveTitleTipsCountryId.push(item.countryId)
                    }
                  })

                  if (awakenSupplementInfoContryId.includes(countryId)) {
                    // 拉起店配，目前只在西班牙198的时候会拉起店配
                    if (self.isShopTransit && storeTransitCountryId.includes(countryId)) {
                      self.clickEditStoreAddress('supplement', {
                        noticeContent: res?.info?.msg || ''
                      })
                      this.sendAddressRefillPopUpAnalysis({ type: 'expose' })
                    } else {
                      // 拉起宅配
                      self.status.checkout = 0
                      self.status.addressCompOpen = true
                      if (haveTitleTipsCountryId.includes(countryId)) {
                        self.status.adrTitleTipsShow = true
                      }
                      self.$refs?.addressCom?.$refs?.addressVue?.loading(false)
                      self.$refs?.addressCom?.$refs?.addressVue?.updateUI('supplement', {
                        ...default_address,
                        noticeContent: res?.info?.msg || '',
                      })
                      this.sendAddressRefillPopUpAnalysis({ type: 'expose' })
                    }
                  }
                  
                  self.sendAddressAnalysis(
                    {
                      page_type: 'edit',
                      activity_from: 'checkout_passive',
                    },
                    { type: 'expose', from: 'checkout_passive' }
                  )
                } else if ([1, 2].includes(+hint_type)) {
                  self['productLimitCarts'] = match_carts
                  self.soldoutType = 1
                  this.updateCheckoutState({ key: 'soldOutDialog', val: {
                    show: true,
                    loaded: true,
                    productLimitTip: res.info.msg,
                    hintType: hint_type,
                  } })
                } else if (+hint_type === 4) {
                  self.productLimitCarts = match_carts
                  this.updateCheckoutState({ key: 'specialGoodsDialog', val: {
                    show: true,
                    loaded: true,
                    productLimitTip: res.info.msg,
                    hintType: hint_type,
                  } })
                } else if(+hint_type == 7) {
                  self.$message({
                    message: self.language.SHEIN_KEY_PC_31597,
                    type: 'error',
                  })
                } else if(+hint_type == 8) {
                  // 刀具商品，根据成单接口返回的信息(不全)，匹配购物车数据
                  let machedKnivesGoods = []
                  const goodsById = new Map(info?.match_carts?.map(item => [item.id, item]))
                  self.checkout?.results?.carts?.carts?.forEach(item => {
                    const matchedGoods = goodsById.get(item.id)
                    if(matchedGoods) {
                      machedKnivesGoods.push(item)
                    }
                  })
                  self.machedKnivesGoods = machedKnivesGoods || []
                  info?.match_carts?.length > 0 && self.assignState({ isShowKnivesDialog: true })
                } else {
                  blackMask.showMask = true
                  blackMask.text = res.info.msg || res.msg
                  blackMask.cb = function (opt) {
                    if (!opt) {
                      daEventCenter.triggerNotice({
                        daId: '1-11-1-10',
                      })
                    }
                  }
                  daEventCenter.triggerNotice({
                    daId: '1-11-1-9',
                  })
                }
              } else if (res.code == 301721) {
                if (
                  res.info &&
                  [29, 31, 54, 55, 21, 59, 13, 76].includes(
                    +res.info.company_id
                  )
                ) {
                  const limitLang = {
                    29: self.language.SHEIN_KEY_PC_18438,
                    31: self.language.SHEIN_KEY_PC_19452,
                    54: self.language.SHEIN_KEY_PC_19828,
                    55: self.language.SHEIN_KEY_PC_19829,
                    59: self.language.SHEIN_KEY_PC_20178,
                    21: self.language.SHEIN_KEY_PC_20179,
                    13: self.language.SHEIN_KEY_PC_21276,
                    76: self.language.SHEIN_KEY_PC_21654,
                  }
                  self.giftCardLimit.txt = limitLang['' + res.info.company_id]
                  self.giftCardLimit.show = true
                  daEventCenter.triggerNotice({
                    daId: '1-11-1-40',
                  })
                } else {
                  //如果主体不是欧盟，则改为接受再次下单
                  self.giftCardLimit.verify = 1
                  self.createOrder()
                }
              } else if (res.code == 301714) {
                blackMask.text = res.tips
                blackMask.showClose = false
                blackMask.showMask = true
                blackMask.cb = () => {
                  blackMask.showClose = true
                  daEventCenter.triggerNotice({
                    daId: '1-11-1-29',
                    extraData: {
                      error_scene: 1,
                    },
                  })
                }
                daEventCenter.triggerNotice({
                  daId: '1-11-1-28',
                  extraData: {
                    error_scene: 1,
                  },
                })
              } else if (res.code == 300407) {
                self.updateCheckoutState({ key: 'limitTip', val: {
                  show: true,
                  txt: res.tips,
                  type: 'cartLimit'
                } })
              } else {
                blackMask.text = res.tips || ''
                if (res.code == 300608) {
                  blackMask.text = blackMask.text.replace(
                    '{0}',
                    res.info.couponMinOrder.amountWithSymbol
                  )
                }
                if (res.code == 300634) {
                  const min = res?.info?.couponMinOrder
                  const max = res?.info?.couponMaxOrder
                  const cur = res?.info?.currency
                  blackMask.text = blackMask.text
                    .replace('{0}', `${min}${cur}`)
                    .replace('{1}', `${max}${cur}`)
                } else if (res.code == 301713) {
                  blackMask.text = (res.tips || '').replace(/%s/g, res?.info?.market_name || '').replace(/\{0\}/g, res?.info?.market_name || '').replace(/\{\}/g, res?.info?.market_name || '')
                } else if (res.code == 302207) {
                  blackMask.text = (res.tips || '').replace('{0}', res.info || '')
                }

                blackMask.showClose = false
                blackMask.showMask = true
              }
            }
          },
          errorCb: (res) => {
            self.assignState({ 
              selectInstallment: {},
              isShowCvvDialog: false,
              isMallOrderCreateFail: true 
            })

            self.setTokenPayCvv()

            if (res.status && res.status == 403 && res.response) {
              const { msg } = JSON.parse(res.response)
              if (msg) {
                self.$message({
                  message: msg,
                  type: 'error',
                })
              }
              self.status.checkout = 0
              schttp.updateXCsrfToken()
            }
          },
        })
      })

      if (orderRes) return orderRes
    },
    // 会出现触发地址信息的时候，未触发计价请求，传参为上一次计价请求的参数，需要手动替换
    /**
     * params入参为当前点击想要切换的运输方式
     * mall_code(必传)
     * transport_type(必传)
     */
    getMallLists (params = {}) {
      if (!this.caculateData?.mall_params?.length) {
        return []
      }
      let mallList = this.caculateData.mall_params.map(({ mall_code, transport_type }) => ({
        mallCode: mall_code,
        transportType: transport_type,
      }))
      const { mall_code: curr_mall_code, transport_type: curr_transport_type } = params
      if(!!curr_mall_code) {
        mallList = mallList.map(mall => {
          if(mall.mallCode == curr_mall_code) {
            return {
              mallCode: curr_mall_code,
              transportType: curr_transport_type,
            }
          } else {
            return mall
          }
        })
      }
      return mallList
    },
    // 获取店配地址
    getStoreAddress: function (params = {}, fn) {
      const { countryId, state, stateId, city, cityId } = this.checkout.default_address
      const mallList = this.getMallLists(params)
      new Promise((resolve) => {
        fetchGetStoreAddressApi({
          countryId,
          state,
          stateId,
          city,
          cityId,
          mallList,
          scenes: 1,
          transportCode: mallList?.[0]?.transportType
        }).then((json) => {
          let storeResData = ''
          if (json && json.code == 0 && json.info && json.info.result) {
            const result =
              Object.keys(json.info.result).length > 0 ? json.info.result : ''
            if (countryId == 209 && (!result || result.status != '1')) {

              storeResData = ''
            } else {
              storeResData = result
            }
          } else {
            storeResData = ''
          }
          this.assignState({
            storeData: storeResData
          })
          resolve()
        })
      }).then(() => {
        const result = this.storeData
        if (!result || result.status === 2) {
          const addressInfo = this.checkout?.default_address || {}
          this.assignState({
            storeData: {
              ...addressInfo,
              status: 2, // 默认填充宅配地址数据
            }
          })
          this.clickEditStoreAddress('add', {}, params)
        } else if (result.status != '1') {
          this.assignState({
            storeData: {
              ...result,
              status: 0,
            }
          })
          this.clickEditStoreAddress('add', {}, params)
        }
        fn && fn()
      })
    },
    /**
     * type为类型，值如add、edit、supplement， otherData为需要补充数据
     * 如果是supplement补充地址信息的时候，需要更换url
     * */ 
    clickEditStoreAddress: function (type, otherData = {}, params = {}) {
      const url = type === 'supplement' ? '/api/user/addressbook/extraAddress/update' : '/api/user/addressbook/storeAddress/update'
      this.$refs?.addressCom?.$refs?.storeAddress?.updateUI({
        type,
        url,
        addrData: this.storeData,
        extraData: {
          ...otherData,
          mallList: this.getMallLists(params),
          pageSource: 'checkout',
        },
        countryId: this.checkout.default_address.countryId,
        pageName: '下单页',
      })
    },
    // 保存店配地址
    saveStoreAddress(res) {
      if (res.code == 0) {
        this.assignState({
          storeData: res.info.result
        })

        // this.shippingLimitInfo?.checkUpdateShippingMethod - 存在下单限制逻辑时，店配地址更新后，更新运输方式后续逻辑
        // isAddStoreAddress - 选择店配运输方式时无店配地址，弹出新增店配地址后，需跳过下单限制逻辑走后续选中逻辑
        const { isAdd, mallCode = '', shippingMethod = {} } = this.selectStoreAddressShippingInfo || {}
        const isAddStoreAddress = !!isAdd

        if (this.shippingLimitInfo?.checkUpdateShippingMethod || isAddStoreAddress) {
          window.checkoutEventCenter.emit('update-shipping-method', {
            address: this.checkout?.default_address || {},
            isLimit: 1,
            shippingMethod: isAddStoreAddress ? shippingMethod : this.shippingLimitInfo?.shippingMethod || {},
            mallCode: isAddStoreAddress ? mallCode : this.shippingLimitInfo?.mallCode || '',
            skipShipIntercept: !!isAddStoreAddress
          })

          // 成功修改店配地址后，关掉地址限制弹窗
          this.assignState({
            showShippingLimitStore: false,
          })
        } else {
          this.updateTotal(this.caculateData)
        }
        this.updatePayment({ value: this.storeData.value })
      } else {
        this.$refs?.addressCom?.$refs?.storeAddress?.universalToast(res.tips)
      }
    },
    // 取消编辑/新增店配地址
    cancelStoreAddress () {
      if (this.selectStoreAddressShippingInfo?.isAdd) {
        this.handleShippingLimit({
          shippingMethod: this.selectStoreAddressShippingInfo?.shippingMethod,
          mallCode: this.selectStoreAddressShippingInfo?.mallCode,
          skipShipIntercept: true
        })
      }
    },
    goToOrderUnpaid: function () {
      window.location.href = '/user/orders/list'
    },
    closeOrderUnpaid: function () {
      this.status.orderUnpaid = false
    },
    paymentDisplay: function () {
      return true
    },
    confirmUse: function () {
      this.changeParamsStatus({ type: 'codConfirm', params: { currBox: false } })
      this.status.cardPay = ''
      this.caculateData.payment_id = ''
      this.caculateData.payment_code_unique = ''
      let _this = this
      if (_this.codConfirm.applyType == 'coupon') {
        this.getUpdateCoupon({
          coupon: this.codCoupon
        }, {})
      } else {
        this.updateTotal(this.caculateData, function (res) {
          let cb = _this.codConfirm.pointCb
          if (typeof cb == 'function') {
            cb(res, true)
          }
        })
      }
    },
    confirmCancel: function () {
      this.changeParamsStatus({ type: 'codConfirm', params: { currBox: false } })
      let cb =
        this.codConfirm.applyType == 'coupon'
          ? this.codConfirm.couponCb
          : this.codConfirm.pointCb
      if (typeof cb == 'function') {
        cb(this.codConfirm.res, true)
      }
    },
    sendAddressAnalysis(extraData = {}, { type = 'save', from = '' } = {}) {
      this.exposeAddressActivityFrom = type === 'expose' ? from : ''
      daEventCenter.triggerNotice({
        daId: type === 'save' ? '1-11-1-74' : '1-11-1-75',
        extraData: {
          ...extraData,
          address_type: this.checkout?.address_list?.length ? 1 : 0,
        },
      })
    },
    firstAddressAdd(data) {
      let self = this
      self.$refs.firstAddressVue.loading(true)
      schttp({
        method: 'POST',
        url: '/api/user/addressbook/list/create',
        data,
        schttp: { needLogin: true },
      }).then((res) => {
        self.$refs.firstAddressVue.loading(false)
        if (res.code == 0 && res.info.address) {
          if (self.status.hasAddress == 0) {
            self.status.hasAddress = 1
            const element = document.querySelector('.j-first-address')
            element?.parentNode.removeChild(element)
          }
          self.checkout.address_total++
          self.checkout.address_list.unshift(shallowReactive(res.info.address))
          self.clickSelectAddress(self.checkout.address_list.length - 1, true)
          self.judgePay()
        } else if (res.code == 401929) {
          self.status.addressAddTips = true
          self.status.tipsText = self.language.SHEIN_KEY_PC_15997
        } else if (res.code == 400308) {
          self.$refs.firstAddressVue.check.blacklist('postcode')
        } else if (res.code == 400325) {
          self.$refs.firstAddressVue.check.blacklist('postcode', 2)
        } else if (res.code == 401970) {
          self.$refs.firstAddressVue.check.blacklist('postcode', 4)
        } else if ([401971, 401986, 401984, 402000, 401997, 401998, 401999, 402002, 402003, 402004, 402005, 402006, ...ADDRESS_ERROR_CODE].includes(Number(res.code))) {
          self.$refs.firstAddressVue.check?.addressCheckError?.({
            code: Number(res.code),
          })
        } else if (res.code == 401902) {
          self.$refs.firstAddressVue.check.state()
          self.$refs.firstAddressVue.check.district()
        } else if (res.code == 401959) {
          self.$refs.firstAddressVue.check.blacklist('state')
        } else if (res.code == 401960) {
          self.$refs.firstAddressVue.check.blacklist('city')
        } else {
          //风控
          if (res.code == 0 && res.info && res.info.redirection) {
            res.tips = self.language.SHEIN_KEY_PC_17793
          }
          self.$refs.firstAddressVue.universalToast(res.tips)
        }
        if (typeof GB_analysis_obj != 'undefined') {
          GB_analysis_obj.saveFirstAddress(res.code)
        }
        const isSuccess = res.code == 0 && res?.info?.address
        self.sendAddressAnalysis({
          result: isSuccess ? 'success' : 'failure',
          failure_type: isSuccess ? '' : 'back',
          failure_reason: isSuccess ? '' : res.code,
          longitude: data.lat || '',
          latitude: data.lng || '',
          association_address: Number(data.tag),
          page_type: 'add',
          activity_from: 'checkout_active',
        })
      })
    },
    firstUpdateError(data = {}) {
      if (typeof GB_sa_analysis_obj != 'undefined') {
        GB_sa_analysis_obj.saveAddress(2, 'Address check fail', data)
      }
    },
    addressAdd(data) {
      let self = this
      self.$refs?.addressCom?.$refs?.addressVue?.loading(true)
      schttp({
        method: 'POST',
        url: '/api/user/addressbook/list/create',
        data,
        schttp: {
          needLogin: true,
        },
      }).then((res) => {
        self.$refs?.addressCom?.$refs?.addressVue?.loading(false)
        if (res.code == 0 && res.info.address) {
          self.checkout.address_total++
          self.checkout.address_list.unshift(res.info.address)
          self.clickSelectAddress(0)
          if (data.is_default == 1) {
            self.checkout.address_list = self.checkout.address_list.map(
              function (item) {
                item.isDefault =
                  item.addressId == res.info.address.addressId ? '1' : '0'
                return item
              }
            )
          }
          self.status.addressCompOpen = false
          self.judgePay()
        } else if (res.code == 400308) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('postcode')
        } else if (res.code == 400325) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('postcode', 2)
        } else if (res.code == 401970) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('postcode', 4)
        } else if ([401971, 401986, 401984, 402000, 401997, 401998, 401999, 402002, 402003, 402004, 402005, 402006, ...ADDRESS_ERROR_CODE].includes(Number(res.code))) {
          self.$refs?.addressCom?.$refs?.addressVue?.check?.addressCheckError?.({ code: Number(res.code) })
        } else if (res.code == 401902) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.state()
          self.$refs?.addressCom?.$refs?.addressVue?.check.district()
        } else if (res.code == 401959) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('state')
        } else if (res.code == 401960) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('city')
        } else {
          self.$refs?.addressCom?.$refs?.addressVue?.universalToast(res.tips)
        }
        if (res.code == 0) {
          GB_sa_analysis_obj.saveAddress(1, '', res.info.address)
        } else {
          GB_sa_analysis_obj.saveAddress(2, res.code)
        }
        const isSuccess = res.code == 0 && res?.info?.address
        self.sendAddressAnalysis({
          result: isSuccess ? 'success' : 'failure',
          failure_type: isSuccess ? '' : 'back',
          failure_reason: isSuccess ? '' : res.code,
          longitude: data.lat || '',
          latitude: data.lng || '',
          association_address: Number(data.tag),
          page_type: 'add',
          activity_from: this.exposeAddressActivityFrom || 'checkout_active',
        })
      })
    },
    addressEdit(data) {
      let self = this
      self.$refs?.addressCom?.$refs?.addressVue?.loading(true)
      schttp({
        method: 'POST',
        url: '/api/user/addressbook/list/update',
        params: {
          addressId: data.addressId,
        },
        data,
        schttp: { needLogin: true },
      }).then((res) => {
        self.$refs?.addressCom?.$refs?.addressVue?.loading(false)
        if (res.code == 0 && res.info.address) {
          if (self.status.editIndex == -1) {
            self.checkout.address_list[self.status.selectIndex] =
              res.info.address
          } else {
            self.checkout.address_list[self.status.editIndex] =
              res.info.address
          }
          if (data.is_default == 1) {
            self.checkout.address_list = self.checkout.address_list.map(
              function (item) {
                item.isDefault =
                  item.addressId == res.info.address.addressId ? '1' : '0'
                return item
              }
            )
            self.checkout.default_address.isDefault = 0
          }
          if (
            self.checkout.default_address.addressId ==
            res.info.address.addressId
          ) {
            self.updateDefaultAddress(res.info.address)
          }
          self.isNeedAgreenPrivacy()
          self.status.addressCompOpen = false
          self.judgePay()
        } else if (res.code == 400308) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('postcode')
        } else if (res.code == 400325) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('postcode', 2)
        } else if (res.code == 401970) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('postcode', 4)
        } else if ([401971, 401986, 401984, 402000, 401997, 401998, 401999, 402002, 402003, 402004, 402005, 402006, ...ADDRESS_ERROR_CODE].includes(Number(res.code))) {
          self.$refs?.addressCom?.$refs?.addressVue?.check?.addressCheckError?.({ code: Number(res.code) })
        } else if (res.code == 401902) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.state()
          self.$refs?.addressCom?.$refs?.addressVue?.check.district()
        } else if (res.code == 401959) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('state')
        } else if (res.code == 401960) {
          self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('city')
        } else {
          self.$refs?.addressCom?.$refs?.addressVue?.universalToast(res.tips)
        }
        if (res.code == 0) {
          if (typeof GB_analysis_obj != 'undefined') {
            GB_sa_analysis_obj.editAddress(1, '', res.info.address)
          }
        } else {
          if (typeof GB_analysis_obj != 'undefined') {
            GB_sa_analysis_obj.editAddress(2, res.code)
          }
        }
        const isSuccess = res.code == 0 && res?.info?.address
        self.sendAddressAnalysis({
          result: isSuccess ? 'success' : 'failure',
          failure_type: isSuccess ? '' : 'back',
          failure_reason: isSuccess ? '' : res.code,
          longitude: data.lat || '',
          latitude: data.lng || '',
          association_address: Number(data.tag),
          page_type: 'edit',
          activity_from: this.exposeAddressActivityFrom || 'checkout_active',
        })
      })
    },
    addressSupplementEdit(data) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-49',
      })
      let self = this
      const addressVueInstance = this.$refs?.addressCom?.$refs?.addressVue
      addressVueInstance.loading(true)
      
      fetchUpdateExtraAddressBookApi({
        address_id: data.addressId,
        data: {
          ...data,
          passportNumber: data.passport_number || '',
        }
      }).then((res) => {
        addressVueInstance.loading(false)
        const isSuccess = res.code == 0 && res?.info?.address
        self.sendAddressAnalysis({
          result: isSuccess ? 'success' : 'failure',
          failure_type: isSuccess ? '' : 'back',
          failure_reason: isSuccess ? '' : res.code,
          longitude: data.lat || '',
          latitude: data.lng || '',
          association_address: Number(data.tag),
          page_type: 'edit',
          activity_from: 'checkout_passive',
        })
        self.sendAddressRefillPopUpAnalysis({ type: 'click', click_type: 1, result: res.code })
        if (res.code == 0 && res.info.address) {
          self.status.addressCompOpen = false
          const addressInfo = convertAddress({
            from: res?.info?.address || {},
            type: 'extraToAddress',
          })
          let address = Object.assign(
            self.checkout.default_address,
            addressInfo
          )
          const index = self.checkout.address_list.findIndex(
            (item) => item.addressId == address.addressId
          )
          self.checkout.address_list[index] = address
          self.checkout.default_address = address
          const { hintType, show } = self.specialGoodsDialog
          // FR-8086 南非补充身份信息后关闭特殊商品限制弹窗
          if (hintType == 4 && show) {
            self.specialGoodsDialog.show = false
          }
          if (!self.paypalBtnShow) {
            self.createOrder()
          }
        } else {
          self.status.addressCompOpen = true
          let address = self.checkout.default_address
          address = {
            ...address,
            nationalId: data.nationalId,
          }
          self.$nextTick(() => {
            const typeAddress = [...ADDRESS_ERROR_CODE, 402006].includes(+res.code) ? 'supplement' : 'edit'
            if(typeAddress == 'supplement') { 
              const { code } = res || {}
              self.$refs?.addressCom?.$refs?.addressVue?.check?.addressCheckError?.({ code: Number(code) })
            } else {
              self.$refs?.addressCom?.$refs?.addressVue?.updateUI('edit', address, () => {
                setTimeout(() => {
                  const { code } = res || {}
                  if (code == 400308) {
                    self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('postcode')
                  } else if (code == 400325) {
                    self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('postcode', 2)
                  } else if (code == 401970) {
                    self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('postcode', 4)
                  } else if (code == 401902) {
                    self.$refs?.addressCom?.$refs?.addressVue?.check.state()
                    self.$refs?.addressCom?.$refs?.addressVue?.check.district()
                  } else if (code == 401959) {
                    self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('state')
                  } else if (code == 401960) {
                    self.$refs?.addressCom?.$refs?.addressVue?.check.blacklist('city')
                  } else if ([401971, 401986, 401984, 402000, 401997, 401998, 401999, 402002, 402003, 402004, 402005].includes(Number(code))) {
                    self.$refs?.addressCom?.$refs?.addressVue?.check?.addressCheckError?.({ code: Number(code) })
                  } else {
                    self.$refs?.addressCom?.$refs?.addressVue?.universalToast(res.tips)
                  }
                }, 300)
              })
            }

            self.sendAddressAnalysis(
              {
                page_type: 'edit',
                activity_from: 'checkout_passive',
              },
              { type: 'expose', from: 'checkout_passive' }
            )
          })
        }
      })
    },
    addressStoreSupplementEdit(data) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-49',
      })
      let self = this
      self.sendAddressRefillPopUpAnalysis({ type: 'click', click_type: 1, result: data.code }) 
      if (data.code == 0 && data.info.address) {
        this.assignState({
          storeData: data.formData
        })
        if (!self.paypalBtnShow) {
          self.createOrder()
        }
      } else {
        this.$refs?.addressCom?.$refs?.storeAddress?.universalToast(data.tips)
      }
    },
    loginModalSucCb({ scene } = {}) {
      if (scene == 'view_wallet_balance') {
        this.updateTotal(this.caculateData)
      } else if (scene == 'choose_wallet') {
        this.setShowRiskDialog(false)
        this.createOrder()
      }
    },
    handleNotSupportCodTip: function (info) {
      const { notSupportCodTips } = info
      var notSupportCodTip = ''
      const not_support_cod_reason = info.not_support_cod_reason || ''
      let valid = undefined

      //先将cod支付根据结果返回正常
      //code == 7时cod是可用
      if (
        notSupportCodTips &&
        +notSupportCodTips.code == 7 &&
        !not_support_cod_reason &&
        info.isSupportCod == 1
      ) {
        valid = 1
      } else if (
        notSupportCodTips &&
        notSupportCodTips.code &&
        not_support_cod_reason &&
        codDisabledCode.includes(+notSupportCodTips.code) &&
        info.isSupportCod != 1
      ) {
        valid = 0

        notSupportCodTip = not_support_cod_reason
      }

      if (valid !== undefined) {
        this.checkout.results.paymentMethods.forEach((item, index) => {
          if (item.code == 'cod') {
            this.checkout.results.paymentMethods[index]['enabled'] = valid
              ? item.originEnabled
              : 0
            if (window?.gbCoSsrData?.checkout) {
              window.gbCoSsrData.checkout = this.checkout
            }
            this.assignState({
              checkout: this.checkout
            })
          }
        })
      }

      this.checkout.mall_caculate_info.notSupportCodPaymentMethodReasonTip = notSupportCodTip
    },
    handleCancelLink: function (order) {
      // 支付页返回跳转优化
      if (
        history &&
        history.pushState &&
        ['adyen-alipayhk'].indexOf(order.payment_method) >= 0
      ) {
        var _backUrl = gbCommonInfo?.host + '/checkout/checkout_again/' + order.billno
        history.pushState(null, null, _backUrl)
      }
    },
    setAdrTitleTipsStatus() {
      const default_address = this.checkout.default_address
      if (default_address.countryId != 209) {
        this.checkNationalId()
      }
     
      if (default_address.countryId == 209) {
        this.checkTwName()
      } else if (default_address.countryId == 178) {
        if (
          !default_address.passportNumber ||
          !default_address.passportIssuePlace ||
          !default_address.passportIssueDate ||
          !default_address.taxNumber ||
          !default_address.birthday
        ) {
          this.status.adrTitleTipsShow = true
          typeof GB_analysis_obj != 'undefined' &&
            GB_analysis_obj.exposeConstomsinfoGuide()
        } else {
          this.status.adrTitleTipsShow = false
        }
      } else if (default_address.countryId == 43) {
        if (!default_address.taxNumber) {
          this.status.adrTitleTipsShow = true
          typeof GB_analysis_obj != 'undefined' &&
            GB_analysis_obj.exposeConstomsinfoGuide()
        } else {
          this.status.adrTitleTipsShow = false
        }
      } else if (default_address.countryId == 105) {
        this.status.adrTitleTipsShow = false
        // FR-8218 韩国
      } else if (default_address.countryId == 197) {
        this.status.adrTitleTipsShow = false
        this.krCopywriteTip = ''
        // 只存在税号，或两者都不存在的时候
        if (
          (default_address.taxNumber && !default_address.passportNumber) ||
          (!default_address.taxNumber && !default_address.passportNumber)
        ) {
          this.status.adrTitleTipsShow = true
          this.krCopywriteTip = this.language.SHEIN_KEY_PC_20710
        }
        // 只存在护照号
        if (default_address.passportNumber && !default_address.taxNumber) {
          this.status.adrTitleTipsShow = true
          this.krCopywriteTip = this.language.SHEIN_KEY_PC_20708
        }
      } else if (default_address.countryId == 165) {
        // 巴勒斯坦提示语
        if (this.checkPLNationalId()) {
          this.status.adrTitleTipsShow = true
        } else {
          this.status.adrTitleTipsShow = false
        }
      }
    },
    // 清除无货商品
    soldOutCartsDelete(data) {
      if (
        this.checkout.results.carts.carts.length ==
        this.checkout.mall_caculate_info.outStockCarts.length ||
        data?.carts?.length == 0
      ) {
        const redirectUrl = (!!this.buyNowNo && !!this.buyNowFromUrl) ? this.buyNowFromUrl : '/cart'
        window.location.href = redirectUrl
      } else {
        this.handleCartsStatus(data)
      }
    },
    productLimitHandle() {
      this.judgeStock()
      this.updateCart()
    },
    filterApplePayLogoUrl() {
      let applePayChannel = this.checkout.applePayConfig?.applePayChannel || applePayConfig.applePayChannel
      let apple_pay_map = this.checkout.applePayConfig?.apple_pay_map ?? {}
      let version = 3
      if (window.ApplePaySession) {
        version = 14
        while (!window.ApplePaySession.supportsVersion?.(version)) {
          version--
        }
      }
      this.checkout.results.paymentMethods = this.checkout.results.paymentMethods.map(item => {
        if (applePayChannel.indexOf(item.code) > -1) {
          let supportedNetworks = []
          item.card_logo_list = item.card_logo_list?.filter?.(logo => {
            const cardInfo = apple_pay_map[logo]
            // 如果版本高于当前applepay支持的版本，或者apple_pay_map中无对应的版本信息，则不展示
            if (cardInfo && cardInfo.sdk_version && Number(cardInfo.sdk_version) <= version) {
              supportedNetworks.push(cardInfo.name)
              return true
            }
            return false
          }) || []
          if (this.checkout?.checkoutBFFAbtInfo?.ApplepayDropoffOptimization?.param?.ApplepayCardlogoShow != 'Show') {
            item.card_logo_list = []
          }
          item.supportedNetworks = supportedNetworks
          item.version = version
        }
        return item
      })
    },
    initApplePay: function (paymentMethods) {
      // 'debug', 
      
      const isTest = ['localhost'].includes(gbCommonInfo?.NODE_SERVER_ENV)

      //applepay初始化-检测浏览器是否支持applepay
      if (!isTest && window.ApplePaySession?.canMakePayments?.()) {
        // let applepays = paymentMethods.filter((item) => {
        //   return applePayMethod.indexOf(item.code) > -1
        // })
        this.filterApplePayLogoUrl()
      } else {
        if (paymentMethods && paymentMethods.length) {
          for (let index = paymentMethods.length - 1; index >= 0; index--) {
            if (this.applePayMethod.indexOf(paymentMethods[index].code) > -1) {
              this.checkout.results.paymentMethods.splice(index, 1)
            }
          }
        }
      }
    },
    payLimitTipShow(code) {
      let txt = ''
      if (
        this.checkout &&
        this.checkout.mall_caculate_info &&
        this.checkout.mall_caculate_info.paymentMethodAmountLimitInfo &&
        this.checkout.mall_caculate_info.paymentMethodAmountLimitInfo[code]
      ) {
        let item =
          this.checkout.mall_caculate_info.paymentMethodAmountLimitInfo[code]
        let { maxAmount, minAmount } = item
        if (minAmount && maxAmount) {
          txt = this.template(minAmount, maxAmount, this.language.SHEIN_KEY_PC_16932)
        } else if (!minAmount && maxAmount) {
          txt = this.template(maxAmount, this.language.SHEIN_KEY_PC_16955)
        } else if (minAmount && !maxAmount) {
          txt = this.template(minAmount, this.language.SHEIN_KEY_PC_16933)
        }
      }
      txt = txt.replace(/\s/g, ' ')
      return txt
    },
    async getInstallmentInfo() {
      let amount =
        this?.checkout?.mall_caculate_info?.grandTotalPrice?.amount ||
        this?.checkout?.results?.carts?.data?.totalPrice?.amount
      let country = this?.checkout?.default_address?.value

      if (!country) {
        const countryData = await UserInfoManager.get({ key: 'Country', actionType: 'getInstallmentInfo' })
        country = countryData?.countryAbbr || ''
      }
      
      if (!(amount && country)) {
        return
      }

      let data = {
        country: country,
        amount: amount,
        currency: this.currencyCode,
      }
      let cacheKey = data.country + '_' + data.currency + '_' + data.amount
      if (this.installmentInfoCache[cacheKey]) {
        this['installmentInfo'] = this.installmentInfoCache[cacheKey]
      } else {
        schttp({
          method: 'POST',
          url: '/api/checkout/installmentDetail/get',
          data,
        })
          .then((res) => {
            if (res && res.code == 0) {
              if (
                res.info &&
                res.info.front_show_list &&
                res.info.front_show_list.length
              ) {
                let front_list_items = {}
                res.info.front_show_list.forEach((item) => {
                  front_list_items[item.payment_code] = item
                })
                res.info.front_list_items = front_list_items
              }

              this['installmentInfo'] = res.info || {}
              this.installmentInfoCache[cacheKey] = res.info || {}
            } else {
              this['installmentInfo'] = {}
            }
          })
          .catch(() => {
            this['installmentInfo'] = {}
          })
      }
    },
    getErrorGuideAbt() {
      window.blackMask && (window.blackMask.$data.isErrorGuideOn = true)
    },
    ppgvAdaFocus() {
      setTimeout(() => {
        this.$refs.ppgvTitle.focus()
      }, 600)
    },
    clickModalTipsCancel() {
      this.modalTipsShow = false
      daEventCenter.triggerNotice({
        daId: '1-11-1-3',
      })
    },
    clickModalTipsOk() {
      this.modalTipsShow = false
      if (TRANSPORT_TYPE_SHOP.includes(this.defaultShipping[0]?.shipping_method?.transport_type)) {
        this.clickEditAddress(-1, this.checkout.default_address)
      } else {
        this.clickEditStoreAddress('edit')
      }
      daEventCenter.triggerNotice({
        daId: '1-11-1-4',
      })
    },
    checkStoreAddress() {
      let result = false
      
      fetchCheckStoreAddressApi({
        data: { 
          ...(this.storeData || {}),
        },
        success: ({ code = 0, tips = '' } = {}) => {
          if (code == 0) {
            result = true
            return
          }

          if (code == 401974) {
            this.modalTipsShow = true
          } else if (code == 401987) {
            SMessage({
              message: tips,
              type: 'error'
            })
          } else {
            this.clickEditStoreAddress('edit')
          }

          this.status.checkout = 0
        },
      })
      return result
    },
    //置灰文案
    paymentDes(item) {
      let des = ''
      //如果支付可用
      if (item.show_description && item.enabled == 1 && item.description) {
        des = item.description
        des = des.replace(/\s/g, ' ')

        // 如果支付不可用，或者cod不可用时
      } else if (item.enabled != 1) {
        //按优先级展示置灰原因，越往下，优先级越高

        //业务配置置灰文案
        des = item.gray_description || des

        //金额限制
        if (
          this.checkout.mall_caculate_info.paymentMethodLimitInfo &&
          +this.checkout.mall_caculate_info.paymentMethodLimitInfo[item.code] == 0
        ) {
          des = this.payLimitTipShow(item.code) || des
        }

        //cod不可用原因
        if (item.code == 'cod') {
          des =
            this.checkout.mall_caculate_info.notSupportCodPaymentMethodReasonTip ||
            des
        }

        if(item.gray_reason == 1){
          //业务配置置灰文案
          des = item.gray_description || this.language.SHEIN_KEY_PC_25800 || ''
        }

        // 03 本地自营置灰 => wiki.pageId=1176265937
        if(item.gray_reason == 3){
          //业务配置置灰文案
          des = this.language.SHEIN_KEY_PC_27000 || 'error 03'
        }

        return des
  
      }

      //如果有店铺限制置灰，优先展示置灰文案
      if (item.isStoreDisabled) {
        des = this.language.SHEIN_KEY_PC_21123
      }

      return des
    },
    limitGoBack(type) {
      if(!!this.buyNowNo && !!this.buyNowFromUrl) {
        window.location.href = this.buyNowFromUrl
        return
      }
      const redirectUrl = type == 'flashLimit' ? '/cart?isFlashLimit=1' : type == 'discountLimit' ? ('/cart?isDiscountLimit=' + this.discountLimitIds) : '/cart'
      window.location.href = redirectUrl
    },
    changeQiwiPayPhone: function (value) {
      this.qiwiPayPhone = value
    },
    createOrderFn: function (obj) {
      this.createOrder(obj)
    },
    paypalGaVaultBi(type, extraData = {}) {
      const ids = {
        expose_paypal_vaulting: '1-11-1-46',
        click_paypalvaulting_choose: '1-11-1-47',
        click_popup_vaulting_changeconfirm: '1-11-1-48',
      }
      if (!ids[type]) return
      daEventCenter.triggerNotice({
        daId: ids[type],
        extraData,
      })
    },
    /**
     * @description 南非特殊商品弹窗，补充National ID Number
     * @date 2022-01-05 19:45
     */
    handleToSupplementAddress() {
      this.status.addressCompOpen = true
      this.$refs?.addressCom?.$refs?.addressVue?.loading(false)
      this.$refs?.addressCom?.$refs?.addressVue?.updateUI('supplement',  this.checkout?.default_address || {})

      this.sendAddressAnalysis(
        {
          page_type: 'edit',
          activity_from: 'checkout_passive',
        },
        { type: 'expose', from: 'checkout_passive' }
      )
      this.sendAddressRefillPopUpAnalysis({ type: 'expose' })
    },
    handleCartsStatus (data) {
      this.checkout.results.carts['carts'] = data?.carts || []
      this.checkout.results.carts['mall_list'] = data?.mall_list || []
      this.getCartPromotionInfo()

      this.updateTotal(this.caculateData, (res) => {
        this.productLimitHandle()
        if(res.code != 0) {
          this.resetMallCacuTotal()
        }
      }, { type: 'modifyCart', notTips: true })
    },
    /**
     * @description 南非特殊商品弹窗将特殊商品移至收藏夹后的处理
     * @date 2022-01-10 15:45
     */
    handleUpdateCheckoutStatus(data) {
      if (!data?.carts?.length) {
        const redirectUrl = (!!this.buyNowNo && !!this.buyNowFromUrl) ? this.buyNowFromUrl : '/cart'
        window.location.href = redirectUrl
      } else {
        this.handleCartsStatus(data)
      }
    },
    mbwayConfirmSa() {
      daEventCenter.triggerNotice({ daId: '1-11-1-52' })
    },
    // 初始化一站多合规信息
    getMixPrivacyAbt() {
      this.mixPrivacyAbt.loginPrivac = this.checkout.checkoutBFFAbtInfo?.SilentloginPrivacy?.param || 'off'
      this.mixPrivacyAbt.placeOrder = this.checkout.checkoutBFFAbtInfo?.PlaceOrderNationPrivacy?.param || 'no'
      this.isNeedAgreenPrivacy()
    },
    // 查询当前选择的地址id是否需要同意协议
    async isNeedAgreenPrivacy() {
      const locationId = this.checkout?.default_address?.countryId || ''
      // 没有地址
      if (!locationId) return
      const { loginPrivac, placeOrder } = this.mixPrivacyAbt
      if (!(loginPrivac == 'mix' && placeOrder == 'yes')) return
      if (newPrivacyCache.get(locationId) == 1) {
        this.mixPrivacy.needPop = false
        return
      }
      const queue = [
        checkLocationClauseSer({ locationId }),
        checkMemeberCluaseSer({ locationId }),
      ]
      const [location, member] = await Promise.all(queue)

      const clauseList = member?.info?.clause_list || []
      const privacyType = location?.info?.clause_country_type
      // 用户隐私协议未全部同意并且当前地区为强合规
      if (clauseList.some((v) => v.clause_opt == 0) && privacyType == 1) {
        this.mixPrivacy.needPop = true
        return
      }
      this.mixPrivacy.needPop = false
    },
    handleMixAgreen() {
      this.mixPrivacy.needPop = false
      this.mixPrivacy.show = false
      this.createOrder(this.mixPrivacy.tmp)
    },
    handleMixClose() {
      this.mixPrivacy.show = false
    },
    getPromotionIsFullPromotion() {
      let result = []
      Object.values(this.cartPromotionMap).forEach(
        ({ typeId, isFullPromotion }) => {
          result.push(`${typeId}\`${isFullPromotion}`)
        }
      )
      return result.join(',')
    },
    template: _commonTemplate,
    transformImg(value) {
      return typeof value === 'string'
        ? transformImg({ img: value })
        : transformImg(value)
    },
    validateGooglepay() {
      const _this = this
      if (!_this.checkout?.results?.paymentMethods?.length) return
      _this.checkout.results.paymentMethods.forEach((item, index) => {
        //谷歌pay初始化-检测浏览器是否支持谷歌pay
        if (GooglePay.googlePayMethods.includes(item.code)) {
          if (item.enabled == 1) {
            googlePayment.onGooglePayLoaded(
              { createDom: false, code: item.code },
              function (valid) {
                _this.googleValid = !!valid
                if (!valid) {
                  item.enabled = 0
                  _this.checkout.results.paymentMethods[index].enabled = 0
                  if (
                    GooglePay.googlePayMethods.includes(
                      _this.caculateData.payment_code_unique
                    )
                  ) {
                    _this.status.cardPay = ''
                    _this.caculateData.payment_id = ''
                    _this.caculateData.payment_code_unique = ''
                    _this.updateTotal(_this.caculateData, function () { })
                  }
                }
              }
            )
            googlePayment.setPaymentMethods([..._this.checkout.results.paymentMethods])
          }
        }
        return item
      })
      
    },
    googlePayInit() {
      const _this = this
      let attrs = { async: true }
      if (
        _this.checkout.results.paymentMethods.length &&
        _this.checkout.results.paymentMethods.some(
          (item) => item && GooglePay.googlePayMethods.includes(item.code)
        )
      ) {
        attrs = {}
      }
      asyncLoadFile({
        label: 'script',
        attrs: {
          ...attrs,
          src: 'https://pay.google.com/gp/p/js/pay.js',
        },
      }).then(() => {
        window.googlePayment = new GooglePay({ scene: 'Checkout' })

        _this.validateGooglepay()

        GooglePay.eventCenter.on('isGooglePayLoading', (state) => {
          this.fullLoading = !!state
        })
        GooglePay.eventCenter.on(
          'showGooglePay',
          ({ code, orderInfo = {} } = {}) => {
            googlePayment.setOrderInfo({ ...orderInfo })
            googlePayment.onGooglePayLoaded(
              { createDom: true, code },
              function () {
                googlePayment.getConfig({
                  code,
                  cb: () => {
                    _this.googlePay.show = true
                  },
                })
              }
            )
          }
        )

        GooglePay.eventCenter.on('cancelGooglePay', (options = {}) => {
          _this.googlePay.show = false
          _this.status.checkout = 0
          if (options?.manualClose) {
            routerReplace(
              gbCommonInfo?.host + '/checkout/checkout_again/' + _this.orderDate.billno
            )
          }
        })

        GooglePay.eventCenter.on('errorGooglePay', (options) => {
          const { biData, type, text } = options || {}
          _this.fullLoading = false
          _this.status.checkout = 0
          blackMask.showMask = true
          blackMask.type = type || ''
          blackMask.text = text || ''
          blackMask.hasGuide = 0
          blackMask.cb = function (isClickClose) {
            routerReplace(
              gbCommonInfo?.host + '/checkout/checkout_again/' + _this.orderDate.billno
            )
            if (isClickClose) {
              daEventCenter.triggerNotice({
                daId: '1-19-1-5',
                extraData: biData || {},
              })
            } else {
              daEventCenter.triggerNotice({
                daId: '1-19-1-4',
              })
            }
          }
          daEventCenter.triggerNotice({
            daId: '1-19-1-6',
            extraData: biData || {},
          })
        })
      })
    },
    paidyPayInit(paymentMethods) {
      if (paymentMethods && paymentMethods.length && !paymentMethods.find(v => v.code === 'paidy-paylater')) return
      window.paidyPayment = new PaidyPay({ scene: 'Checkout' })
      window.paidyPayment.asyncLoadSDK().then(() => {
        console.log('paidy sdk load success-----')
      })

      PaidyPay.eventCenter.on('paidy-pay-launch', data => {
        const { order = {} } = data
        window.paidyPayment.setOrderInfo({
          ...order,
          buyer_name: order?.shipping_firstname + ' ' + order?.shipping_lastname
        })
        window.paidyPayment.getChannelConfig({
          paymentCode: order.payment_method,
          billno: order.billno
        }).then(() => {
          window.paidyPayment.launch()
        }).catch(() => {
          PaidyPay.eventCenter.emit('paidy-pay-error')
        })
      })

      PaidyPay.eventCenter.on('paidy-pay-error', () => {
        this.status.checkout = 0
        SMessage({
          message: htmlDecode({ text: this.language.SHEIN_KEY_PC_15330 }),
          type: 'warning',
          offset: 100
        })
        if (!window.paidyPayment?.orderInfo?.billno) {
          routerReplace(gbCommonInfo?.host + '/user/orders/list')
          return
        }
        routerReplace(gbCommonInfo?.host + '/checkout/checkout_again/' + window.paidyPayment?.orderInfo?.billno)
      })

      PaidyPay.eventCenter.on('paidy-pay-complete', data => {
        console.log('paidy pay complete-----', data)
        this.status.checkout = 0
        this.fullLoading = true
        window.GB_handlePayMethods?.paidyPay?.({
          order: this.orderDate,
          vm: this,
          payload: {
            sessionId: data.id
          }
        })
      })
      PaidyPay.eventCenter.on('paidy-pay-cancel', () => {
        this.status.checkout = 0
        routerReplace(gbCommonInfo?.host + '/checkout/checkout_again/' + this.orderDate.billno)
        console.log('paidy pay cancel-----')
      })
    },
    handleCloseGooglePayDialog() {
      routerReplace(gbCommonInfo?.host + '/checkout/checkout_again/' + this.orderDate.billno)
    },
    cancelChangeShippingType() {
      const { prevShippingMethod = {}, mallCode = '' } = this.shippingTypeChangeInfo || {}
      this.clickShipping({ transport_type: prevShippingMethod.transport_type, scence: 2, mallCode })
      this.assignState({
        shippingTypeDialog: false
      })
      daEventCenter.triggerNotice({
        daId: this.isShopTransit ? '1-11-1-61' : '1-11-1-63',
      })
    },
    changeShippingType () {
      if (!this.isShopTransit) this.assignState({ changeShopType: true })
      // 埋点要在selected前上报，isShopTransit值会进行修改
      daEventCenter.triggerNotice({
        daId: this.isShopTransit ? '1-11-1-64' : '1-11-1-62',
      })
      this.assignState({
        shippingTypeDialog: false
      })
      const { shippingMethod = {}, mallCode = ''  } = this.shippingTypeChangeInfo || {}
      this.clickShipping({ transport_type: shippingMethod.transport_type, scence: 2, mallCode })
    },
    async handleShippingLimitEditStoreAddress (params = {}) {
      const { type = 'edit', otherData, params: opts } = params
      await this.clickEditStoreAddress(type, otherData, opts)
      // TODO 因店配地址组件层级太低，会被dialog弹窗给盖住，所以需要强行改变店配地址组件层级
      const hackZIndex = +window.getComputedStyle?.(document.querySelector('.shipping-address-limit__dialog'))?.zIndex
      if (hackZIndex) {
        this.forceUpdateStoreAddressModelZIndex(hackZIndex + 1)
      }
    },
    forceUpdateStoreAddressModelZIndex (zIndex) {
      const targets = document.querySelectorAll('.j-store-address-vue .c-modal-wrap') || []
      targets.forEach(target => {
        target.style.zIndex = zIndex || ''
      })
    },
    handleShippingLimitAddressClose () {
      // 如果是选中的是店配运输地址，则在关闭限制弹窗后需重置店配地址组件层级
      if (this.shippingLimitInfo?.isStoreAddress) {
        this.forceUpdateStoreAddressModelZIndex()
      }
    },
    getInterestsData({ initFirstReport = false, delay = 1200 }) {
      if(typeof window == 'undefined') return

      if ((!initFirstReport && this.isInitNotReoprt) || this.interestsDataHasReport) {
        return
      }
      this.interestsDataHasReport = true

      const Casual = this.showTogetherBuy // 是否有随手购
      const SHEIN_Club = !!this.primeProductList?.length // 是有付费会员
      const regain_coupon = (Object.values(this.integrityPromotionInfo) || [])?.some(item => item?.type_id == '21') || false // 是否有返劵
      const saver = this.showXtra // 有超省卡
      const freeshiping = this.isFreePrice // 有免邮
      const quickship = !!this.checkout?.results?.carts?.carts?.filter(item => item.real_quick_ship == 1)?.length // 有quickship时效商品
  
      const interestsData = []
      if (Casual) { interestsData.push('Casual') }
      if (SHEIN_Club) { interestsData.push('SHEIN_Club') }
      if (regain_coupon) { interestsData.push('regain_coupon') }
      if (saver) { interestsData.push('saver') }
      if (freeshiping) { interestsData.push('freeshiping') }
      if (quickship) { interestsData.push('quickship') }
        
      daEventCenter.triggerNotice({
        daId: '1-11-1-205',
        extraData: {
          interests: interestsData.join('`')
        }
      })

      setTimeout(()=>{
        this.interestsDataHasReport = false
      }, delay)
    },
    async setUserEmail() {
      this.emailTips.val = await UserInfoManager.get({ key: 'userinfo_email', actionType: '/checkout' }) || ''
    },
    handleCvvResult (cvv) {
      this.setTokenPayCvv(cvv)
      this.createOrder()
    },
    handlePreUnPay ({ skipFreezeOperate } = {}) {
      const self = this
      self.setPrePayCheckoutInfo({
        ddcInfo: {
          formActionUrl: self.ddcFormParams?.formActionUrl || '',
          jwt: self.ddcFormParams?.jwt || ''
        },
        necessaryOrderInfo: {
          totalPrice: self.orderDate.totalPrice || {},
          sub_billnos: self.orderDate.sub_billnos || [],
          billno: self.orderDate?.billno || ''
        }
      })

      const params = {
        routeId: self.prePayUnPayParams?.routeId || '',
        card_bin: self.prePayUnPayParams?.cardBin || '',
        last_four_no: self.prePayUnPayParams?.lastFourNo || '',
        expire_month: self.prePayUnPayParams?.expireMonth || '',
        expire_year: self.prePayUnPayParams?.expireYear || '',
        cardno: self.prePayUnPayParams?.cardNumber || '',
        cpfNumber: self.prePayUnPayParams?.cpfNumber || '',
        cardHolderName: self.prePayUnPayParams?.cardHolderName || '',
        rememberCard: self.prePayUnPayParams?.rememberCard || '',
        cvv: self.prePayUnPayParams?.cvv || '',
        countryCode: self.prePayUnPayParams?.countryCode || '',
        billno: self.orderDate?.billno || '',
        payment_method: self.orderDate?.payment_method,
        paymentCode: self.prePayUnPayParams?.paymentCode || '',
        installments: self.prePayUnPayParams?.installments || '',
        cardType: self.prePayUnPayParams?.cardType || '',
        autoBindCard: self.prePayUnPayParams?.autoBindCard || ''
      }
      // 二次支付卡bin冻结流程
      if (!skipFreezeOperate && self.cardBinFreezeParams && self.prePayCheckoutInfo.billno) {
        params.freezeParamsStr = JSON.stringify(self.cardBinFreezeParams || {})
      }

      self.prePayMergeState('clickContinueResultInfo', {
        failure_type: 'rear_end', // 失败类型 front_end/rear_end
        instalment_num: self.prePayUnPayParams?.installments || '',
        is_remember: self.prePayUnPayParams?.rememberCard ? '1' : '0',
        order_id_list: self.orderDate?.sub_billnos?.map?.(v => v.sub_billno).join(',') || '', // 每个子订单号之间用 隔开
        order_no: self.orderDate?.billno || '',
        payment_method: self.orderDate?.payment_method || '',
        scene: 'new',
        uorder_id: self.orderDate?.billno || '', // 关联订单号 
      })

      const handleTokenPayFunc = (params) => {
        self.prePayMergeState('clickContinueResultInfo', {
          stay_time: parseInt((Date.now() - self.pageReadyTime) / 1000)
        })
        self.handlePayForPreCheckout({ 
          params,
          onPayComplete: ({ status, result }) => {
            const { host, langPath } = gbCommonInfo || {}
            const { sub_billnos, billno, relation_billno = '' } = self.orderDate || {}

            self.prePayMergeState('clickContinueResultInfo', {
              failure_reason: JSON.stringify({
                code: result?.code == 0 && result?.info?.error_code ? result?.info?.error_code : result?.code || '',
                result: result?.info?.result || '0'
              })
            })
            self.reportClickContinueResult(self.clickContinueResultInfo)

            if (status === 'success') {
              self.assignState({
                newCheckoutLoadingType: 'pay-success'
              })
              replacePaySuccessReferer()
              window.location.href = `${host}${langPath}/pay/result/success?billno=${billno}&type=${self.orderDate?.payment_method || ''}&gatewayPayNo=${result?.info?.gatewayPayNo || ''}`
            } else if (status === 'pending') {
              self.assignState({
                newCheckoutLoadingType: ''
              })
              window.location.href = `${host}${langPath}/pay/result/pending?billno=${billno}`
            } else if (status === 'fail') {
              self.assignState({
                newCheckoutLoadingType: ''
              })
              self.fullLoading = false
              if (self.status) self.status.checkout = 0
              if (result?.code == '300317') {
                replacePaySuccessReferer()
                window.location.href = `${host}${langPath}/pay/result/success?billno=${billno}&type=${self.orderDate?.payment_method || ''}&gatewayPayNo=${result?.info?.gatewayPayNo || ''}`
                return
              }
              // 10119005 为卡bin优惠冻结操作自定义的错误码
              if (result?.code == '10119005') {
                self.prePayMergeState('prePayBinFreezeFailConfig', {
                  visible: true,
                  closeCb () {
                    self.prePayMergeState('prePayBinFreezeFailConfig', {
                      visible: false,
                      closeCb: () => {}
                    })
                    window.location.href = `${langPath}/user/orders/detail/${billno}`
                  },
                  confirmCb () {
                    self.prePayMergeState('prePayBinFreezeFailConfig', {
                      visible: false,
                      confirmCb: () => {}
                    })
                    self.prePayEventBus.createOrder?.emit?.({ skipFreezeOperate: true })
                  }
                })
                return
              }
              self.prePayMergeState('prePayFailGuide', {
                visible: true,
                failText: result?.info?.show_error_msg || result?.info?.error_msg || result?.tips || self.language.SHEIN_KEY_PC_30360,
                type: 'payError',
                hasGuide: result.info?.is_guide == 1,
                closeCb: function ({ isActivelyClose } = {}) {
                  if (isActivelyClose) {
                    daEventCenter.triggerNotice({
                      daId: '1-19-1-5',
                      extraData: {
                        error_code: result?.info?.error_code || '',
                        order_no: billno,
                        order_id_list: sub_billnos?.map(v => v.sub_billno).join(','),
                        uorder_id: relation_billno
                      },
                    })
                    routerReplace(`${host}/checkout/checkout_again/${billno}`)
                  } else {
                    daEventCenter.triggerNotice({
                      daId: '1-19-1-4',
                    })
                    routerReplace(`${host}/checkout/checkout_again/${billno}?showpaymethods=1`)
                  }
                },
                confirmCb: async function () {
                  self.prePayMergeState('prePayFailGuide', {
                    visible: false,
                    closeCb: () => {},
                    confirmCb: () => {}
                  })
                  self.prePayMergeState('prePayCheckoutInfo', { billno: self.orderDate?.billno || '' })
                  const bin = self.prePayUnPayParams?.cardNumber?.substring?.(0, 8) || ''
                  
                  const unPayRoutingInfo =  await self.handleQueryUnPayPreRoutingInfo({
                    bin,
                    routeCode: self.status.cardPay,
                    paymentMethod: self.status.cardPay,
                    orderCurrency: self.checkout.mall_caculate_info.orderCurrency.code,
                    countryCode: self.prePayUnPayParams?.countryCode,
                    billno: self.orderDate?.billno || ''
                  })

                  const currentInstallmentList = unPayRoutingInfo.installments || [
                    {
                      stage_num: 1,
                      rate: 0,
                      installment_amount: self.checkout.mall_caculate_info?.grandTotalPrice?.amountWithSymbol || '',
                      installment_fee_numeric: 0
                    }
                  ]
                  // 原选择的分期数是否在新的分期信息列表中，是的话可以保留原选中分期数，如果只有一条分期信息，则默认选中
                  const newInstallments = currentInstallmentList.find(v => v.stage_num === self.prePayUnPayParams.installments) ? 
                    self.prePayUnPayParams.installments : currentInstallmentList.length === 1 ? currentInstallmentList[1].stage_num : -1

                  // self.changeCardBinDiscountInfoData(bin, unPayRoutingInfo)
                  self.changeCardBinDiscountInfoData(unPayRoutingInfo.routingInfo?.bin || bin, unPayRoutingInfo.cardBinDiscountInfo || {})
                  self.prePayUpdateState('installmentInfo', currentInstallmentList)
                  
                  self.prePayUpdateState('cardBinRoutingInfo', unPayRoutingInfo.routingInfo || {})
                  self.prePayMergeState('prePayUnPayParams', { 
                    cardBin: unPayRoutingInfo.routingInfo?.bin,
                    routeId: unPayRoutingInfo.routingInfo?.routeId,
                    paymentCode: unPayRoutingInfo.routingInfo?.payMethod,
                    countryCode: unPayRoutingInfo.routingInfo?.orderCurrency || self.prePayCheckoutInfo.shipCountry,
                    cardType: unPayRoutingInfo.routingInfo?.cardType,
                    installments: newInstallments
                  })

                  self.visiblePrePay = true
                  daEventCenter.triggerNotice({
                    daId: '2-41-2'
                  })
                }
              })
            }
          }
        })
      }
      if (self.ddcFormParams.formActionUrl && self.ddcFormParams.jwt) {
        const startPayTime = Date.now()
        self.initPayJsonWebToken(self.ddcFormParams)
        self.sendDdc({
          cardBin: self.ddcFormParams?.bin || '',
          callback: (sessionId = '') => {
            params.sessionId = sessionId
            self.prePayMergeState('clickContinueResultInfo', {
              pay_response_time: parseInt((Date.now() - startPayTime) / 1000)
            })
            handleTokenPayFunc(params)
          }
        })
      } else {
        handleTokenPayFunc(params)
      }
    },
    handlePrePayActiveClose () {
      if (!this.prePayCheckoutInfo?.billno) return
      const { host } = gbCommonInfo || {}
      routerReplace(host + '/checkout/checkout_again/' + this.prePayCheckoutInfo?.billno)
    },
    checkResetPrePayCheckoutInfo () {
      if (!this.isUsePrePayForCheckout) {
        this.assignState({ prePayForCheckoutReady: false })
        this.resetPrePayCheckoutInfo()
      }
    }
  },
}
</script>

<style lang="less" scoped>
.c-place-order .shipping-item.check-available:hover{
  cursor: no-drop;
}
.container-1350 {
  max-width: 1370px;
}
.checkout__layout {
  display: flex;
}
.checkout__layout_left {
  max-width: 920px;
  min-width: 480px;
  flex: 1;
}

.checkout-address-shipping__wrap {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  .is-left {
    width: 50%;
    background: #fff;
  }
  .is-right {
    width: 50%;
  }
}
.c-check{
  margin-bottom: 30px;
}

.place-order__effiency {
  background-color: #f4f4f4;
  overflow: hidden;
}
.place-order__effiency .c-check-left {
  padding-right: 10px;
}
.place-order__effiency .c-check-right {
  padding-left: 10px;
  border: 0;
}
// .checkout-discount-limit-tip {
//   text-align: center;
//   font-size: 16px;
//   font-weight: bold;
//   color: #222;
// }

.c-pay-skip{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 4000;	
}
.c-pay-skip .skip-con{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 750px;
  padding: 40px;
  background: url(/she_dist/images/payment-skip-bg-f94f3de11d.jpg) no-repeat;
  background-size: cover;
}

.c-pay-skip .order-num{
  font-size: 20px;
  color: #ff0000;
  font-weight: bold;
  text-align: center;
}
.c-pay-skip .skip-loading{
  text-align: center;
  margin: 10px auto;
}
.c-pay-skip .skip-des{
  margin-top: 30px;
  border: 1px solid #ccc;
  padding: 20px 10px;	
}
.c-pay-skip .skip-des > p{
  line-height: 20px;
}
.c-pay-skip .skip-des > p.no-redirecting > a{
  color: #5EBD66;
  text-decoration: underline;
}
.c-pay-skip .skip-des .skip-ps{
  margin-top: 15px;
  padding-top:15px;
  border-top: 1px dashed #ccc;
}
.c-pay-skip .ticket-tip{
  margin-top: 20px;
}
.c-pay-skip .redirecting-icon{
  position: absolute;
  left: 0;
  top: 0;
}
.check-first-address .check-title {
  display: none;
}

/deep/.pre-pay-price {
  color: #FA6338;
  font-size: 16px;
  font-family: Arial;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}
</style>
