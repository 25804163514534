<template>
  <div>
    <ClubProductPackage
      v-expose="primeOptionAnalysisData"
      :selected-prime="props.selectedPrime"
      :not-support="props.notSupport"
      :now-payment="props.nowPayment"
      :prime-product-list="productList"
      :show-scribe-price="clubScribePriceByAbt"
      :saving-summary="primeSavingSummary"
      :discount-price="detailDrawerProps.discountPrice"
      :language="language"
      :used-prime-index="usedPrimeIndex"
      :selectd-prime-index="selectdPrimeIndex"
      :on-prime-card-click="clubChangeInfo"
      :view-more="viewMorePackage"
      :click-term="clickTerm"
      :limit-modal-props="limitModalProps"
      :detail-drawer-props="detailDrawerProps"
      :discount-dialog-props="discountDialogProps"
      :set-discount-dialog-props="value => (discountDialogProps = value)"
      :history-omit-amount-text="historyOmitAmountText"
      :auto-renewal-bubble-config="autoRenewalBubbleConfig"
      :on-bubble-close="handleBubbleClose"
      :pay-method-bubble-props="payMethodBubbleProps"
      :event-center-config="eventCenterConfig"
      :style-type="+sheinClubUIByAbt === 2 ? 'new' : 'old'"
      :style-config="checkout.productPackageInfo.clubInfo"
      :locals="locals"
    />

    <PayMethodsListDrawer
      ref="payMethodsListDialogRef"
      :checkout="checkout"
      :language="language"
      :payment-methods-props="props.paymentMethodsProps"
      :pay-methods-list-drawer-header-data="payMethodsListDrawerHeaderData"
      :choose-prime="paymentUpdataSelectPackage"
      auto-type="prime"
    />

    <ProductAutoDrawer
      ref="productAutoDrawerRef"
      :auto-produ-api-loading="autoProduApiLoading"
      :language="language"
      auto-type="prime"
      @auto-submit="autoSubmit"
    />
  </div>
</template>

<script setup>
import PayMethodsListDrawer from '../../payment_list_dialog/pay_methods_list_dialog.vue'
import ClubProductPackage from 'public/src/pages/common/product-package/club/index.vue'
import { computed, onMounted, watch } from 'vue'
import { template } from '@shein/common-function'
import { useMapState, useMapGetters, useMapMutation, useMapActions } from 'public/src/pages/store_pages/store/mapHook'
import ProductAutoDrawer from 'public/src/pages/checkout/components/product_auto_drawer/index.vue'
import { useDaEventCenter } from '../useDaEventCenter'
import { useMembership, useAutoSwitchPackage, useAutoPaymentDialog, useAutoBubble, useMorePackageDetail } from './usePackageClub'

const props = defineProps({
  // 选中的产品包信息，默认则选is_recommend(推荐产品包）
  selectedPrime: {
    type: Object,
    default: null
  },
  // 当前支付方式，是否支持产品包
  notSupport: {
    type: Boolean,
    default: false
  },
  // 当前选中的支付方式完整信息
  nowPayment: {
    type: Object,
    default: null
  },
  // 支付方式模块信息，由下单最外层组装
  paymentMethodsProps: {
    type: Object,
    default: null
  }
})

const { checkout, language, selectdPrimeIndex, usedPrimeIndex, primeSavingSummary, status, autoProduApiLoading, locals } = useMapState([ 'language', 'checkout', 'primeSavingSummary', 'usedPrimeIndex', 'selectdPrimeIndex', 'status', 'autoProduApiLoading', 'locals'])
const { clubScribePriceByAbt, isNotAddressScene, sheinClubUIByAbt } = useMapGetters(['clubScrbePriceByAbt', 'isotAddressScene', 'sheinClubUIByAbt'])
const { assignState, changeVirtualCouponInfo } = useMapMutation(['assignState', 'changeVirtualCouponInfo'])
const { getPrimeRightCouponInfo } = useMapActions(['getPrimeRightCouponInfo'])

const { clubDaEvent, primeOptionAnalysisData, eventCenterConfig } = useDaEventCenter()
const { productList, limitModalProps, discountDialogProps, handleOfferLabelClick } = useMembership({ props })
const { productAutoDrawerRef, autoSubmit, initSwitchPackageModules } = useAutoSwitchPackage({ productList })
const { autoRenewalBubbleConfig, payMethodBubbleProps, handleBubbleClose, needSelectAutoRenewalPayMethod, initAutoBubbleModules } = useAutoBubble({ props, productList })
const { handlePayMethodsListDrawerShow, payMethodsListDialogRef, payMethodsListDrawerHeaderData, paymentUpdataSelectPackage, initPaymentDialogModules } = useAutoPaymentDialog({ productList })
const { detailDrawerProps, viewMorePackage, initMorePackageModules } = useMorePackageDetail({ props, productList })

// 包体顶部标题栏,历史已省金额文本
const historyOmitAmountText = computed(() => {
  const data = checkout.value.results?.primeProductInfo?.data?.info || {}
  const { buy_when = -1, recent_order_saving_info = {} } = data
  if (!([2, 3, 4].includes(Number(buy_when)) && recent_order_saving_info?.recent_saving_amount > 0)) return ''
  const savingAmountSymbol = recent_order_saving_info?.recent_saving_amount_with_symbol || ''
  return template(`${savingAmountSymbol}`, language.value.SHEIN_KEY_PC_28813)
})

// 下面底部协议文案，跳转文章页
const clickTerm = e => {
  if (props.notSupport) {
    e.preventDefault()
  } else {
    if (e.target?.tagName == 'A') {
      clubDaEvent['go-article-page']()
    }
  }
}

// 更新选中的当前包信息
const upDatacClubProductInfo = ({ index, type } = { index: 0, type: 'default' }) => {
  /**
   * type 
   * default 不勾选，展示默认包数据
   * cancel 取消勾选
   * onlyRight 不勾选，只更新展示数据
   * selectClub 勾选选中产品包
   */
  const upDateInfoMap = {
    'default': () => {
      // 取默认逻辑 => 不更新 usedPrimeCode, usedPrimeIndex，但是需要有默认的数据展示(权益信息)
      const defaultIndex = productList.value.findIndex( item => props.selectedPrime.product_code == item.product_code)
      const defaultPrime = productList.value[defaultIndex] ?? {}
      assignState({ selectdPrimeIndex: defaultIndex, rightConfigList: defaultPrime?.right_config_list || [] })
    },
    'cancel': () => assignState({ usedPrimeIndex: -1, usedPrimeCode: '' }),
    'onlyRight': () => assignState({ selectdPrimeIndex: index, rightConfigList: productList.value[index]?.right_config_list || [] }),
    'selectClub': () => {
      assignState({
        selectdPrimeIndex: index,
        usedPrimeIndex: index,
        usedPrimeCode: productList.value[index]?.product_code,
        rightConfigList: productList.value[index]?.right_config_list || []
      })
    }
  }
  return upDateInfoMap[type]()
}

// 触发包改变
const clubChangeInfo = (index, isCancel, frLocation) => {
  //选包模块：location (1-随单购，2-弹窗，3-支付方式，4-下单页挽留弹窗)
  if (props.notSupport) return console.warn('no support club')

  changeVirtualCouponInfo({ currentClickType: 'prime' })
  // 取消勾选
  const isCancelled = isCancel || usedPrimeIndex.value == index

  const chooseClubCalcu = (type = 'cancel') => {
    const flag = type == 'selectClub' ? true : false
    upDatacClubProductInfo({ type, index })
    const selectedPrime = productList.value[index || selectdPrimeIndex.value || 0]
    clubDaEvent['choose-club-product']({ flag, selectedPrime, fromLocation: frLocation || 1 })

    window.checkoutEventCenter.emit('change-prime-calcu', {
      primeSeleted: flag
    })
  }

  if(isCancelled){
    chooseClubCalcu('cancel')
  }else{
    if(needSelectAutoRenewalPayMethod(productList.value[index])){
      handlePayMethodsListDrawerShow(productList.value[index])
    } else {
      chooseClubCalcu('selectClub')
    }
  }
}

initSwitchPackageModules({ clubChangeInfo })
initPaymentDialogModules({ clubChangeInfo })
initMorePackageModules({ clubChangeInfo, upDatacClubProductInfo, handlePayMethodsListDrawerShow, handleOfferLabelClick, needSelectAutoRenewalPayMethod })
initAutoBubbleModules({ handlePayMethodsListDrawerShow })

watch(
  () => status.value.cardPay,
  (newCardPay, oldCardPay) => {
    const currentSelectedPrime = productList.value[usedPrimeIndex.value]
    const isRenewProductBag = currentSelectedPrime?.sceneResult.isAutoRenewal

    if (!isNotAddressScene?.value && !newCardPay && oldCardPay && isRenewProductBag) {
      assignState({ usedPrimeIndex: -1, usedPrimeCode: '' })
    }
  }
)

onMounted(() => {
  // 更新当前包
  upDatacClubProductInfo()
  // 更新所有产品包的权益列表
  getPrimeRightCouponInfo()
})

</script>
