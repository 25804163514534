<template>
  <div 
    class="prime-list"
    @mouseenter="enterEvent"
    @mouseleave="leaveEvent"
  >
    <swiper-container
      init="false" 
      class="prime-list__container"
      :class="{
        'prime-list__container_single': !isMultStyle,
      }"
      :dir="GB_cssRight ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(item, index) in primeProductList"
        :key="item.product_code"
        class="prime-list__item"
        :class="{ 
          'prime-list__item_scale': isReachedEnd,
          'prime-list__item_orange': showNewStyle,
          'prime-list__item_renewal': isAutoRenewal(item)
        }"
        @click="handlePrimeSelected(index)"
      >
        <PrimeOptionsCardNew
          v-if="isShowRightsNewVersion"
          :item="item"
          :is-scale="activeIndex === index"
          :has-activity="hasActivity"
          :is-selected="usedPrimeIndex === index"
          :is-auto-renewal="isAutoRenewal(item)"
          @handle-activity-show="handleActivityShow"
        />
        <PrimeOptionsCard
          v-else
          :item="item"
          :is-scale="activeIndex === index"
          :has-activity="hasActivity"
          :is-selected="usedPrimeIndex === index"
          :is-auto-renewal="isAutoRenewal(item)"
          @handle-activity-show="handleActivityShow"
        />
        <div 
          class="prime-list__price" 
          :class="{
            'no-support': notSupport,
            'prime-list__price_scale': activeIndex === index
          }"
        >
          <s-radio 
            :model-value="usedPrimeIndex"
            :label="index"
            class="prime-list__radio"
            theme="icon"
            :disabled="notSupport"
          />
          <div
            v-if="totalBubble.length && totalBubble[index].value"
            class="prime-list__bubble bubble-wrapper"
          >
            <sui_icon_closed_filter_12px_1
              class="bubble-wrapper__close-btn"
              size="10px"
              color="#fff"
              @click.stop="handleBubbleCloseBtnClick(index)"
            />
            <renewalPayMethods 
              :banks-config="banksConfig"
              :index="index"
              :paypal-ga-vault="paypalGaVault"
              :payment-methods-props="paymentMethodsProps"
              :status="status" 
              auto-type="prime"
              @handle-bubble-click="handleBubbleClick"
            />
          </div>
          <strong class="prime-list__price_discount">
            {{ item.product_price_info.special_price_with_symbol }}
          </strong>
          <del 
            v-if="+item.product_price_info.special_price < +item.product_price_info.price_local && !clubScribePriceByAbt"
            class="prime-list__price_local"
          >
            {{ item.product_price_info.price_local_with_symbol }}
          </del>
          <div
            v-if="showDiscount(item)" 
            class="prime-list__limited"
            :class="{
              'prime-list__limited_to_coupon': sceneLogic(item).isTips,
              'prime-list__limited_only-renewal': sceneLogic(item).isOnlyAutoRenewal
            }"
            @click.stop="clickLimit(item)"
          >
            <div
              class="prime-textWhiteSpace"
            >
              <span v-if="!GB_cssRight">
                &#x202A;<sui_icon_random_discount_16px 
                  v-if="sceneLogic(item).isOnlyAutoRenewal"
                  size="12px" 
                  color="#fff"
                />
                <span>{{ discountTips(item).tips }}</span>
              </span>
              <span v-else>
                <span>{{ discountTips(item).tips }}</span>
                <sui_icon_random_discount_16px 
                  v-if="sceneLogic(item).isOnlyAutoRenewal"
                  size="12px" 
                  color="#fff"
                />
              </span>
            </div>
            <span
              v-if="GB_cssRight"
              class="prime-textWhiteSpace_pice"
            >{{ discountTips(item).price }} </span>
            <ClientOnly>
              <span 
                v-if="interactionShow(item) && isNewPrimeVersion"
                class="mr-5"
              > > </span>
            </ClientOnly>
          </div>
        </div>
      </swiper-slide>
      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
      <div slot="container-start">
        <div
          v-show="isMultStyle && showSwiperButton"
          class="swiper-button-prev new_common-swiperv2-checkout-prev"
        >
          <Icon
            name="sui_icon_more_left_18px"
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>
      <div slot="container-end">
        <div
          v-show="isMultStyle && showSwiperButton"
          class="swiper-button-next new_common-swiperv2-checkout-next"
        >
          <Icon
            name="sui_icon_more_right_18px"
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>   
      </div>
    </swiper-container>

    <ClientOnly>
      <primeAutoDrawer
        v-if="isClient"
        ref="primeAutoDrawerRef"
        :auto-produ-api-loading="autoProduApiLoading"
        :language="language"
        auto-type="prime"
        @auto-submit="autoSubmit"
      />
    </ClientOnly>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import PrimeOptionsCard from './prime_options_card.vue'
import PrimeOptionsCardNew from './prime_options_card_new.vue'
import { Icon, sui_icon_closed_filter_12px_1, sui_icon_random_discount_16px } from '@shein-aidc/icon-vue3'
import renewalPayMethods from './renewal_pay_methods.vue'
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })
typeof window !== 'undefined' && register()

export default {
  name: 'PrimeOptionsList',
  components: {
    PrimeOptionsCard,
    PrimeOptionsCardNew,
    Icon,
    sui_icon_closed_filter_12px_1,
    sui_icon_random_discount_16px,
    primeAutoDrawer: defineAsyncComponent(() => import('public/src/pages/checkout/components/product_auto_drawer/index.vue')),
    renewalPayMethods
  },
  props: {
    selectedPrime: {
      type: Object,
      default: () => {}
    },
    notSupport: {
      type: Boolean,
      default: false
    },
    paypalGaVault: {
      type: Object,
      default: () => {}
    },
    banksConfig: {
      type: Object,
      default: () => {}
    },
    paymentMethodsProps: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      primeSwiper: null,
      showSwiperButton: false,
      activeIndex: 0,
      lastActiveIndex: -1,
      isReachedEnd: false,
      hasActivity: false,
      totalBubble: [],
      nextPaymentInfo: {},
      isClient: false,
    }
  },
  computed: {
    ...mapState([
      'primeProductList',
      'usedPrimeIndex',
      'usedPrimeCode',
      'selectdPrimeIndex',
      'updatingPrice',
      'virtualCouponInfo',
      'coupon',
      'checkout',
      'status',
      'autoProduApiLoading',
      'language',
      'locals',
    ]),
    ...mapGetters([
      'isNewPrimeVersion',
      'isShowPriceReminderByAbt',
      'autoRenewalPrimeShowAbt',
      'productAutoInfo',
      'clubScribePriceByAbt',
      'sheinClubUIByAbt'
    ]),
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    isMultStyle() {
      return this.primeProductList.length > 1
    },
    selectPrimeStatus(){
      // 有码以及计价接口请求完或没请求的情况
      return this.usedPrimeCode && !this.updatingPrice
    },
    currentPrimeProd(){
      return this.virtualCouponInfo.newPrimeProductList.find(f => f.product_code === this.usedPrimeCode && !this.updatingPrice)
    },
    applyCouponsVir(){ 
      return this.coupon.applyCouponsSuccess.some(item => this.currentPrimeProd?.product_dues_coupon_rights?.coupon_code === item.couponCode)
    },
    isShowRightsNewVersion() {
      return this.checkout.checkoutBFFAbtInfo?.MembershipChangeSwitch?.param?.MembershipChangeSwitchValue === 'on'
    },
    currentCardPay() {
      return this.status?.cardPay
    },
    currentCardPayRememberRadio() {
      return this.paypalGaVault?.radioType
    },
    currentAfterpayRememberRadio() {
      return this.paymentMethodsProps?.afterpayVault?.radioType
    },
    showNewStyle() {
      return this.isShowRightsNewVersion && 
        [2].includes(+this.sheinClubUIByAbt)
    }
  },
  watch: {
    usedPrimeIndex: {
      handler(n) {
        if (typeof n === 'number' && n > -1) {
          // 滚动
          if (this.isMultStyle) {
            this.primeSwiper && this.primeSwiper?.swiper?.slideTo(n)
          } else {
            this.lastActiveIndex = this.activeIndex
            this.activeIndex = n
          }
        }
      },
      immediate: true,
    },
    activeIndex: {
      handler(index) {
        this.$emit('changeActiveIndex', index)
      },
      immediate: true,
    },
    currentCardPay: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleItemBubbleShow({ index: this.activeIndex, itemData: this.primeProductList[this.activeIndex], needChange: true, changeIndex: this.activeIndex })
        }
      }
    },
    currentCardPayRememberRadio: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && ['paypal-gapaypal'].includes(this.status.cardPay.toLowerCase())) {
          this.handleItemBubbleShow({ index: this.activeIndex, itemData: this.primeProductList[this.activeIndex], needChange: true, changeIndex: this.activeIndex })
        }
      },
    },
    currentAfterpayRememberRadio: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && ['afterpay-card'].includes(this.status.cardPay.toLowerCase())) {
          this.handleItemBubbleShow({ index: this.activeIndex, itemData: this.primeProductList[this.activeIndex], needChange: true, changeIndex: this.activeIndex })
        }
      }
    },
    notSupport: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleItemBubbleShow({ index: this.activeIndex, itemData: this.primeProductList[this.activeIndex], needChange: true, changeIndex: this.activeIndex })
        }
      },
    }
  },
  mounted() {
    this.isClient = true
    const bubbleItem = {
      type: '', // 当前的类型，值为‘’ 、‘close’，如果正常点击【气泡关闭按钮】，type为close，不会更改
      value: false, // 控制当前气泡是否显示，初始化是false，满足显示条件后为true，当点击关闭按钮的之后，这个值为false，不会更改
      isShowDrawer: false, // 当切换支付方式后，如果是在需要展示气泡的时候，即是true，主要用于点击权益的时候是否展示弹窗
    }
    this.totalBubble = this.primeProductList?.length ? new Array(this.primeProductList.length).fill().map(()=>(bubbleItem)) : []
    this.$nextTick(() => {
      this.initSwiper()
      this.initPrime()

      // 监听切换支付方式，需要自动续费包弹窗 发射的事件
      window?.checkoutEventCenter && window?.checkoutEventCenter?.on('payment-choosepayment-event', (opt)=>{
        if(opt.currentAutoProductDwType == 'prime') {
          this.bubbleEvent(opt.item)
          this.nextPaymentInfo = opt
        }
      })
    })
    // 会费券更新数据
    this.upDatePrimeSceneStatus()
  },
  methods: {
    ...mapMutations(['assignState', 'updateSelectedPrime', 'changeParamsStatus', 'changeVirtualCouponInfo', 'upDatePrimeSceneStatus']),
    ...mapActions(['getPrimeRightCouponInfo']),
    template,
    enterEvent() {
      this.showSwiperButton = true
    },
    leaveEvent() {
      this.showSwiperButton = false
    },
    initSwiper: function () {
      if (!this.isMultStyle) return
      const swiperParams = {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        slidesOffsetAfter: 12,
        normalizeSlideIndex: false,
        modules: [Navigation],
        navigation: {
          nextEl: '.new_common-swiperv2-checkout-next',
          prevEl: '.new_common-swiperv2-checkout-prev'
        },
        injectStyles: [
          `.swiper {
            overflow: visible;
          }
          `,
        ],
        on: {
          slideChange: (swiper) => {
            this.$nextTick(() => {
              if (this.isReachedEnd && swiper.isEnd) return
              const lastListLength = this.primeProductList.length - 1
              // 拖拽到最后，有可能会出现超出数组长度的 index，此时赋值数组最后一位
              const activeIndex = swiper.activeIndex > lastListLength ? lastListLength : swiper.activeIndex
              this.isReachedEnd = activeIndex === this.primeProductList.length - 1
              // 当前包切换后才进行赋值
              if (activeIndex != this.activeIndex) this.lastActiveIndex = this.activeIndex
              this.activeIndex = activeIndex
              this.handleItemBubbleShow({ index: this.activeIndex, itemData: this.primeProductList[this.activeIndex], needChange: true, changeIndex: this.lastActiveIndex })
            })
          }
        }
      }
      this.primeSwiper = document.querySelector('.prime-list__container')
      Object.assign(this.primeSwiper, swiperParams)
      this.primeSwiper.initialize()
      this.primeProductList?.length && this.handleItemBubbleShow({ index: 0, itemData: this.primeProductList[0] })
    },
    initPrime() {
      if (typeof this.usedPrimeIndex !== 'number' || this.usedPrimeIndex < 0) {
        const selectdPrimeIndex = this.primeProductList?.findIndex(
          (item) => this.selectedPrime.product_code == item.product_code
        )
        this.updateSelectedPrime({ index: selectdPrimeIndex == -1 ? 0 : selectdPrimeIndex, isUpdateRightList: true })

        this.getPrimeRightCouponInfo()
        this.primeSwiper && this.primeSwiper?.swiper?.slideTo(selectdPrimeIndex)
      } 
    },
    showDiscount(item) {
      const product_price_info = item.product_price_info
      const isProdctDuesCouponRights = item?.product_price_info?.product_dues_coupon_rights?.coupon_code
      return +product_price_info.price_local > +product_price_info.special_price || isProdctDuesCouponRights
    },
    interactionShow(item){
      const { isVisible, isTips, isLegal, isRenewalLegalDialog } = this.sceneLogic(item)
      return isVisible || isLegal || isTips || isRenewalLegalDialog ? true : false
    },
    discountTips(item) {
      const { isVisible, isTips, isLegal, isOnlyAutoRenewal } = this.sceneLogic(item)
      const product_price_info = item?.product_price_info || {}
      let resultInfo = {
        price: product_price_info?.reduce_price_with_symbol,
        tips: this.template( this.GB_cssRight ? '' : product_price_info?.reduce_price_with_symbol, this.language.SHEIN_KEY_PC_28840 )
      }
      if(isOnlyAutoRenewal) {
        resultInfo = {
          tips: `${this.template(this.GB_cssRight ? '' : resultInfo.price, this.language.SHEIN_KEY_PC_29640)}`
        }
        return resultInfo
      }
      if(isVisible || isLegal){
        const price = isLegal ? product_price_info.reduce_price_with_symbol : product_price_info?.total_discount_price_with_symbol
        resultInfo = {
          price,
          tips: `${this.template(this.GB_cssRight ? '' : price, this.language.SHEIN_KEY_PC_28840)}`
        }
        return resultInfo
      }
      if(isTips){
        daEventCenter.triggerNotice({
          daId: '1-11-1-177',
          extraData: {
            type: 'club',
            position: 'page'
          }
        })
        const price = product_price_info?.product_dues_coupon_rights?.discount_value_with_symbol
        resultInfo = {
          price,
          tips: this.template(this.GB_cssRight ? '' : price, this.language.SHEIN_KEY_PC_28841)
        }
        return resultInfo
      }
      return resultInfo
    },
    clickLimit(item) {
      const { isVisible, isTips, isLegal, iconTips, isRenewalLegalDialog } = this.sceneLogic(item)

      if(isTips){
        daEventCenter.triggerNotice({
          daId: '1-11-1-178',
          extraData: {
            type: 'club',
            position: 'page'          
          },
        })
        this.changeParamsStatus({
          type: 'coupon',
          params: {
            showCouponList: true
          }
        })
        return
      }
      if(this.clubScribePriceByAbt || isVisible){
        this.$emit('changeVirtunalDia', {
          descountDislogInfo: item || {},
          virtunalDiscountVisible: true,
          iconTips
        })
        return
      }
      if(isLegal || isRenewalLegalDialog){
        this.assignState({
          showPrimeLimitModal: true
        })
        return
      }
    },
    handlePrimeSelected(index, isCancell, frLocation) {
      if (this.notSupport) return

      // 如果当前已选中，则取消勾选
      const isCancelled = isCancell || !!this.usedPrimeCode && this.usedPrimeIndex == index 
      this.changeVirtualCouponInfo({
        currentClickType: 'prime'
      })
      
      if (!isCancelled) {
        // 勾选时才需要改变勾选的产品包数据
        this.updateSelectedPrime({ index: index == -1 ? 0 : index, isUpdateRightList: true })

        if (this.totalBubble[index]?.isShowDrawer) {
          this.handleBubbleClick(index)
          return
        }

        // 点击缩小的产品包卡片，属于边缘情况（非主屏产品包）
        const isRenewProductBag = this.checkout.checkoutBFFAbtInfo?.sheinClubRenew?.param?.sheinClubRenewStatus == 1 && this.virtualCouponInfo?.newPrimeProductList[index]?.primeProductList?.product_activity_type == 1
        if (isRenewProductBag) {
          const limitedPayTypeList = this.checkout.results?.primeProductInfo?.limitedPayTypeList?.map(item => item?.code) || []
          const isInLimitPayment = !!this.status.cardPay && limitedPayTypeList.includes(this.status.cardPay)
          const payPalNotRememberCount = isInLimitPayment && ['paypal-gapaypal'].includes(this.status.cardPay.toLowerCase()) && this.paypalGaVault?.radioType == 0
          const afterpayNotRememberCount = isInLimitPayment && ['afterpay-card'].includes(this.status.cardPay.toLowerCase()) && 
            ((this.paymentMethodsProps.afterpayVault?.radioType == 0 && !this.paymentMethodsProps?.afterpayAccountInfo?.id) ||
            !this.paymentMethodsProps.afterpayVault?.switch)

          if (!isInLimitPayment || payPalNotRememberCount || afterpayNotRememberCount) {
            this.handleBubbleClick(index)
            return
          }
        }
      }

      this.$emit('choosePrime', {
        flag: !isCancelled,
        fromLocation: frLocation || 1,  //选包模块：location (1-随单购，2-弹窗，3-支付方式，4-下单页挽留弹窗)
      })
    },
    handleActivityShow(hasActivity) {
      this.hasActivity = hasActivity
    },
    handleItemBubbleShow({ index, itemData = {}, needChange = false, changeIndex = -1 }) {
      if (needChange && !!this.totalBubble[changeIndex]?.value) {
        this.totalBubble[changeIndex] = { ...this.totalBubble[changeIndex], type: '', value: false }
      }
      
      const { product_activity_type } = itemData
      const isRenewProductBag =  this.checkout.checkoutBFFAbtInfo?.sheinClubRenew?.param?.sheinClubRenewStatus == 1 && Number(product_activity_type) === 1
      
      if (!isRenewProductBag) {
        return
      }

      const { type = '', value = false } = this.totalBubble[index] || {} // type默认是空字符串，当用户点击后变成了click，以此区分用户点击关闭气泡，还是初始展示气泡
      if (!!type && !value ) {
        return
      }

      const { paymentMethods = [], primeProductInfo = {} } = this.checkout.results
      const limitedPayTypeList = primeProductInfo?.limitedPayTypeList?.map(i => i?.code)
      const intersectionValue = paymentMethods.filter(item => limitedPayTypeList.includes(item.code) && item?.enabled)
      const isInLimitPayment = limitedPayTypeList.includes(this.status.cardPay)

      // 不走后续逻辑：当前站点的支付方式与接口返回的自动续费支付方式限制列表无交集
      if (!intersectionValue?.length) {
        return
      }

      // 1、先选择产品包后选择支付方式，或者无支付方式。
      // 2、先选择了支付方式后选择包，当前选择的支付方式如果在限制支付列表中就不展示气泡，否则，就展示气泡
      // 3、如果当前选择的支付方式是payPal，并在接口返回的自动续费支付方式限制列表中，但是没有记住当前的卡号
      const payPalNotRememberCount = isInLimitPayment && ['paypal-gapaypal'].includes(this.status.cardPay.toLowerCase()) && this.paypalGaVault?.radioType == 0
      const afterpayNotRememberCount = isInLimitPayment && ['afterpay-card'].includes(this.status.cardPay.toLowerCase()) && 
      ((this.paymentMethodsProps.afterpayVault?.radioType == 0 && !this.paymentMethodsProps?.afterpayAccountInfo?.id) ||
      !this.paymentMethodsProps.afterpayVault?.switch)

      if(this.status.cardPay === '' || !isInLimitPayment || payPalNotRememberCount || afterpayNotRememberCount) {
        this.totalBubble[index] = { ...this.totalBubble[index], value: true, isShowDrawer: true }
      } else {
        this.totalBubble[index] = { ...this.totalBubble[index], isShowDrawer: false }
      }
    },
    handleBubbleCloseBtnClick(index){
      this.totalBubble[index] = { ...this.totalBubble[index], type: 'close', value: false }
    },
    handleBubbleClick(index) {
      this.$emit('handlePayMethodsListDrawerShow', this.virtualCouponInfo?.newPrimeProductList[index]?.primeProductList)
    },
    sceneLogic(item){
      const currentPrime = this.virtualCouponInfo.newPrimeProductList.find(f => f.product_code === item.product_code)
      const currentPromotion_type = currentPrime?.primeProductList?.product_price_info?.promotion_type != 0
      const isLegaDialog = this.isShowPriceReminderByAbt && currentPromotion_type && this.isNewPrimeVersion
      const isOnlyAutoRenewal = [3, 4].includes(currentPrime?.scence) && this.isAutoRenewal(item)
      const sceneResult = {
        isVisible: [8, 10].includes(currentPrime?.scence),
        isTips: [6, 9].includes(currentPrime?.scence),
        isLegal: [3, 4].includes(currentPrime?.scence) && isLegaDialog,
        iconTips: isLegaDialog,
        isCouponRightsCode: currentPrime?.primeProductList?.product_price_info?.product_dues_coupon_rights?.coupon_code,
        // 仅存在自动续费活动
        isOnlyAutoRenewal,
        // 仅存在自动续费活动且满足法务弹窗条件
        isRenewalLegalDialog: isOnlyAutoRenewal && isLegaDialog
      }
      return sceneResult
    },
    isAutoRenewal(item) {
      return this.autoRenewalPrimeShowAbt && item.product_activity_type == 1
    },
    bubbleEvent(opt){
      this.$refs.primeAutoDrawerRef.visibleEvent( { visible: true, ...this.productAutoInfo, paymentInfo: { title: opt.title, logo: opt.logo_url, code: opt.code } })
    },
    autoSubmit({ productCode, isdont }){
      const Index = this.primeProductList.findIndex(item => item.product_code === productCode)
      if(Index !== -1) { 
        // isdont, true => 产品包不选，支付方式切换非快捷
        window.checkoutEventCenter.emit('again-choose-payment', { paymentInfo: this.nextPaymentInfo, Index, isdont, afterCallBack: ({ Index, isdont, frLocation }) => {
          this.handlePrimeSelected(Index, isdont, frLocation)
        } })
      }
    }
  },
  emits: ['choosePrime', 'changeVirtunalDia', 'changeActiveIndex', 'handlePayMethodsListDrawerShow'], 
}
</script>

<style lang="less" scoped>
.prime-list {
  width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  &__container {
    width: 100%;
    margin: 12px 0 8px;
    padding: 14px 0 0 12px;
    display: flex;
    color: @sui_color_club_rosegold_dark3;
    overflow: hidden;
  }

  &__container_single {
    padding-left: 0;
    width: 88%;

    .prime-list__item {
      width: 86.9%;
      margin-right: 0;
    }
  }

  &__item {
    cursor: pointer;
    width: 213px;
    margin-right: 44px;
    &_scale:last-child { 
      margin-left: -32px;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    height: 24px;
    margin-top: 12px;
    line-height: 1;
    
    &_scale {
      width: 115%;
    }
    &_discount {
      font-size: 16px;
      .margin-r(4px);
    }
    &_local {
      font-size: 12px;
      .margin-r(4px);
      color: @sui_color_gray_light1;
    }
  }

  &__radio {
    display: flex;
    align-items: center;
    margin: 0;
    margin-left: 2px;
  }

  &__bubble {
    position: absolute;
    left: 0;
    bottom: 29px;
  }

  .bubble-wrapper {
    max-width: 260px;
    padding: 8px;
    border-radius: 2px;
    box-sizing: border-box;
    background: var(---sui_color_black_alpha80, rgba(0, 0, 0, 0.80));
    color: #FFF;
    font-size: 12px;
    &::before {
      position: absolute;
      bottom: -5px;
      left: 7px;
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(0, 0, 0, 0.80);
    }
    &__close-btn {
      position: absolute;
      top: 0;
      right: 0;;
    }
  }
  
  &__limited {
    background: linear-gradient(98deg, #C96E3F 48.66%, #EAA27E 100%);
    border-radius: 6px 0px;
    font-size: 12px;
    color: #fff;
    font-weight: 700;
    padding: 2px 8px;
    display: flex;
    overflow: hidden;

    &_to_coupon {
      border: 1px solid rgba(218, 126, 80, 0.30);
      background: linear-gradient(0deg, #FEE6D7 0%, #FEE6D7 100%);
      color: #CA6F40;
    }

    &_only-renewal {
      background-image: none;
      background: linear-gradient(90deg, #F17E53 5.49%, #FF5656 81.87%);
      border-radius: 6px 0px;
    }
  }

  .prime-textWhiteSpace{
    font-size: 11px;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;
  }
  .prime-textWhiteSpace_pice{
    font-size: 11px;
    line-height: 1.3;
  }

  &__item_orange,
  &__item_renewal {
    .prime-list__price_discount {
      color: #FD5B46;
    }
  }

  &__item_orange .prime-list__limited {
    background: linear-gradient(270deg, #E9C997 -0.05%, #FFEFC9 100.05%);
    color: #D43E2A;
  }
}

.place-order__effiency .prime-list {
  :deep(.prime-list__radio > .sui-radio__check) {
    .margin-r(6px);
    border-width: 1.5px;
    border-color: @sui_color_club_rosegold_dark3;
  }
  :deep(.sui-radio__checked > .sui-radio__check) {
    background-color: @sui_color_club_rosegold_dark3;
  }

  &__item_orange,
  &__item_renewal {
    :deep(.prime-list__radio > .sui-radio__check) {
      border-color: #FF5925;
    }
    :deep(.sui-radio__checked > .sui-radio__check) {
      background-color: #FF5656;
    }
  }
}
.new_common-swiperv2-checkout-next, .new_common-swiperv2-checkout-prev{
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, .94);
  border-radius: 50%;
  z-index: 12;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .08);
  color: #222;
  font-size: 16px;
}

</style>
