import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { template } from '@shein/common-function'
import abtOther from './abtOther'
import benefit from './benefit'

const getters = {
  ...abtOther,
  ...benefit,
  isCouponAutoUse (state) {
    return !state.is_manual_usedCoupon && ((state.canAutoUsePrime || state.isPrimeMember) || (state.usedXtraCode || state.isCancelXtra))
  },
  first_coupon_code (state) {
    let { autoUse, couponCode = '' } = state.firstCoupon || {}
    let first_coupon_code = !!(+autoUse) && couponCode
    return first_coupon_code ? couponCode : ''
  },
  isFreePrice (state) {
    return state.checkout?.mall_caculate_info?.actual_shipping_price?.amountWithSymbol && +state.checkout?.mall_caculate_info?.actual_shipping_price?.amount == 0
  },
  showXtra(state) {
    return !!state.xtraProductInfo.product_code && !!state.xtraCouponList.length
  },
  // 当前展示的超省卡信息
  currentSaverProduct(state, getters) {
    const { sheinSaverRenewAbt, xtraProductNormal } = getters.xtraAutoController || {}
    // 不展示续费包：默认包为第一个普通包；展示续费包：默认包为接口返回的第一个包
    let defaultCode = xtraProductNormal[0]?.product_code
    if (sheinSaverRenewAbt) defaultCode = state.xtraProductBagInfo[0]?.product_code
    const code = state.usedXtraCode || defaultCode || ''
    return state.xtraAllProductBagInfo[code] || {}
  },
  // 是否展示超省卡模块
  saverModuleVisible(_, getters) {
    // 有超省卡 code 且 有超省卡券信息
    return getters.currentSaverProduct.productInfo?.product_code && 
      !!getters.currentSaverProduct.couponListInfo?.couponList?.length
  },
  isShowbrTaxhint(state){
    return state.checkout?.checkoutBFFAbtInfo?.brTaxhint?.param?.isSwitchOn == 1 ? true : false
  },
  isNewShopBagPopTypeByAbt(state) {
    if(state.locals.IS_RW ?? gbCommonInfo?.IS_RW) return false
    return state.checkout.checkoutBFFAbtInfo?.ShoppingBagPopType?.param?.ShoppingBagPopType == 'Item_Detail' ? true : false
  },
  isNewPrimeVersion(state) {
    return state.checkout?.checkoutBFFAbtInfo?.OrderEntryprimeVersion?.param?.primeVersion == 'new' ? true : false
  },
  isShowPriceReminderByAbt(state) {
    return state.checkout.checkoutBFFAbtInfo?.PriceReminder?.param?.PriceReminderswitch == 'show' ? true : false
  },
  touchRuleInfo(state) {
    // 当前不会存在配置多条的情况，默认取第一个处理
    const ruleList = state.checkout?.mall_caculate_info?.touch_rule_list?.filter(item => item.rule_id > 0 && item.hint_type == 6 && !!item.msg) || []

    return ruleList[0] || {}
  },
  taxHint(state, getters) {
    const { tax = [] } = state.checkout?.mall_caculate_info || {}
    const taxList =
      tax.filter(item => +item.taxPrice?.amount > 0 && item.taxType == 7) ||
      []
    // 当前不会存在配置多条的情况，默认取第一个处理
    const taxPrice = taxList[0]?.taxPrice?.amountWithSymbol || ''
    const taxHint = getters.touchRuleInfo.msg || ''

    return !!taxList.length && taxHint
      ? taxHint.replace('{br_tax_1}', taxPrice)
      : ''
  },
  primeRightCouponCodeList(state) {
    if(!state.rightConfigList.length) return []
    const freeRight = state.rightConfigList.filter(item => item.right_type_code == 'FREE_SHIPPING_TIMES') || []
    if(!freeRight.length) return []
    return freeRight[0]?.coupon_code_list || []
  },
  // 站点mall 多mall站样式按多mall来.
  isSiteMallList (state) { 
    return state.checkout?.results?.mallInfo?.mallInfoForSite?.length > 1
  },
  // 当前选中运输方式是否为店配
  isShopTransit(state) {
    const defaultShipping = state.defaultShipping || []
    if (!defaultShipping.length || !defaultShipping[0]?.shipping_method?.transport_type) return false
    // 店配和宅配互斥,不同mall只能选择其中一种
    const shopIndex = defaultShipping.findIndex(
      (mall) => TRANSPORT_TYPE_SHOP.includes(mall.shipping_method.transport_type)
    )
    return shopIndex == -1 ? false : true
  },
  newAddressShipByAbt(state) {
    return state.checkout.newAddressShipByAbt
  },
  // 是否展示时效百分比
  timePercentAbt(state){
    return state.checkout.checkoutBFFAbtInfo?.TimePercent?.param?.['show_shippingpercentage'] == 0 ? false : true
  },
  lowestPriceDays(state) {
    return state.checkout?.checkoutBFFAbtInfo?.LowestPriceDays?.param || {}
  },
  isNewCheckoutCouponlist(state) {
    return state.checkout?.checkoutBFFAbtInfo?.CheckoutNewCouponlist?.param?.checkout_new_couponlist == 'on'
  },
  shipTimeDesList(state, getters) {
    const shipList = state.mallShippingMethods?.mall_list || []

    return shipList.map(mall => {
      return { 
        mall_code: mall?.mall_code || '', 
        shipping_methods: mall?.shipping_methods.map(method => {
          let shipping_time_desc = 'none'
          if (getters.timePercentAbt) {
            let isShowPercentDes = method.desc_type == 1 ? !!method?.delivery_day_percent_desc : !!method.shipping_day_percent_desc
            let isShowPercentIcon = !!method?.day_percents?.length
            if (isShowPercentDes && isShowPercentIcon) {
              shipping_time_desc = 'desc_and_rule'
            } else if (isShowPercentDes && !isShowPercentIcon) {
              shipping_time_desc = 'desc_only'
            }
          }

          return { transport_type: method.transport_type, shipping_time_desc }
        }) 
      }
    })
  },
  flashSaleGroupList(state) {
    let result = {}
    const cartList = state.checkout?.results?.carts?.carts || []
    cartList.filter(item => item.promotionTypeIds?.some(i => [10, 11].includes(i))).forEach(item => {
      // 默认10普通闪购不会与11直播闪购同时存在于同一商品上
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => [10, 11].includes(promotion.type_id))
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  underPriceGroupList(state) {
    let result = {}
    const cartList = state.checkout?.results?.carts?.carts || []
    cartList.filter(item => item.promotionTypeIds?.includes(30)).forEach(item => {
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => promotion.type_id == 30)
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  discountPriceGroupList(state) {
    let result = {}
    const cartList = state.checkout?.results?.carts?.carts || []
    cartList.filter(item => item.promotionTypeIds?.includes(24)).forEach(item => {
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => promotion.type_id == 24)
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  // 当前产品包/自动用券产品包
  productAutoInfo(state){
    const { pay_member_discount_price = {}, prime_total_price = {} } = state?.checkout?.mall_caculate_info || {}
    const newPrimeProductList = state?.virtualCouponInfo?.newPrimeProductList
    const currentProduct = newPrimeProductList?.find(f => f.product_code === state.usedPrimeCode)?.primeProductList
    const currenctProductCycleDaysObj = newPrimeProductList.find(f => f?.primeProductList?.product_cycle_type === currentProduct?.product_cycle_type && f?.primeProductList?.product_activity_type != 1)
    const currentDaysPriceInfo = currenctProductCycleDaysObj?.primeProductList?.product_price_info || {}
    const discountPrice = +pay_member_discount_price?.amount <= 0 ? {
      ...prime_total_price,
      price: prime_total_price?.amount,
      price_with_symbol: prime_total_price?.amountWithSymbol
    } : {
      ...pay_member_discount_price,
      price: pay_member_discount_price?.amount,
      price_with_symbol: pay_member_discount_price?.amountWithSymbol
    }
    const limitedPayTypeList = (state?.checkout.results?.primeProductInfo?.limitedPayTypeList || []).map(m => m.code) || []
    const paypalSelect = limitedPayTypeList && limitedPayTypeList.includes('PayPal-GApaypal')
    
    const result = {
      currentProduct,
      currentProductCycleDays: currenctProductCycleDaysObj?.primeProductList,
      limitedPayTypeList,
      discountPrice,
      normalShow: +currentDaysPriceInfo?.special_price >= +discountPrice?.price,
      scence: currenctProductCycleDaysObj?.scence,
      caculateData: state.caculateData,
      paypalSelect,
    }
    return result
  },
  isHaveInitTokenPay (state) {
    return !!state.checkout?.memberPreCardTokenInfo?.isHaveInitTokenPay
  },
  isSelectTokenPay (state) {
    return state.status.cardPay === 'routepay-card' && state.isShowTokenPay && !!Object.keys(state.defaultSelectTokenPay || {}).length
  },
  quickShipCheckoutStatus(state){
    // qs => 只针对单mall场景 
    const { TradeQuickshipWord, OrderQuickshipStyle, PlaceOrderQuickshipWord } = state.checkout?.checkoutBFFAbtInfo
    const cartList = state.checkout?.results?.carts?.carts || []
    const quickShipWord = +(TradeQuickshipWord?.param?.TradeQuickshipWordStatus || 0)
    const result = {
      quickShipStyle: +(OrderQuickshipStyle?.param?.OrderQuickshipStyleStatus || 0),
      PlaceOrderQuickshipWord: +(PlaceOrderQuickshipWord?.param?.PlaceOrderQuickshipWordStatus || 0),
      isAllCartsQuickShip: cartList.every((item) => item?.quick_ship && item?.real_quick_ship == 1),
      quickShipLabelText: quickShipWord ? template(quickShipWord, state.language.SHEIN_KEY_PC_30515) : state.language.SHEIN_KEY_PC_23485,
    }
    return result
  },
  // 法国，波兰金额合规展示
  isSuggested(state) {
    return state.checkout.IS_SUGGESTED || false
  },
  // 德国金额合规展示
  isSuggestedInDe(state) {
    return state.checkout.IS_SUGGESTED === 'special_de'
  },
  lastSelectePayment(state) {
    return state.lastSelectePayment
  },
  // EVOLU_SHEIN_ID
  EVOLU_SHEIN_ID(state) {
    return state.checkout?.EVOLU_SHEIN_ID
  },
  cardTokenList(state) {
    const routerPayCardData = (state.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-card')
    return routerPayCardData?.card_token_list || []
  },
  // 超省卡自动续费产品包
  xtraAutoController(state){
    const xtraPaymentLimit = (state.checkout?.results?.xtraProductInfo?.limitedPayTypeList || []).map(m => m.code)
    const xtraAutoPayLimitInfo = state.checkout?.results?.paymentMethods.filter(item => xtraPaymentLimit?.includes(item.code) && item?.enabled != 0).map(m => m.code)
    
    const sheinSaverRenewAbt = state.checkout?.checkoutBFFAbtInfo?.SheinSaverRenew?.param?.SheinSaverRenewStatus == 1
    
    // 多包，组装后的数据
    const xtraAllProductBagInfo = state.xtraAllProductBagInfo
    const xtraAllproduct = Object.values(xtraAllProductBagInfo).map(m => m.productInfo)
    const xtraProductAuto = xtraAllproduct.filter(f => f.product_activity_type == 1)
    const xtraProductNormal = xtraAllproduct.filter(f => f.product_activity_type != 1)
    const currentPaymenthods = state.status.cardPay
    const currentProduct = xtraAllproduct.find(s => s.product_code === state.usedXtraCode)
    const currentProductCycleDays = xtraAllproduct.find(f => f?.product_cycle_type === currentProduct?.product_cycle_type && f?.product_activity_type != 1)

    const paypalSelect = xtraAutoPayLimitInfo && xtraAutoPayLimitInfo.includes('PayPal-GApaypal')
    
    const result = {
      sheinSaverRenewAbt,
      autoPaymenodsGuideVisible: sheinSaverRenewAbt && !!xtraAutoPayLimitInfo?.length && !!xtraProductAuto?.length, // 前置展示条件，abt/是否有快捷支付/是否有自动续费包
      xtraCurrentAutoPayLimit: xtraAutoPayLimitInfo?.includes(currentPaymenthods), // 当前支付方式是否在快捷支付
      xtraAutoPayLimitInfo, // 自动包能使用的快捷支付方式
      xtraAllproduct, // 所有包
      xtraProductAuto, // 所有自动续费包
      xtraProductNormal, // 所有普通包
      currentProduct, // 当前包
      currentProductCycleDays, // 选中的自动续费超省卡产品同周期的普通包
      currentXtraIsAuto: currentProduct?.product_activity_type == 1, // 当前包是不是自动续费包
      paypalSelect
    }
    return result
  },
  isSelectInstallmentTokenPay (state) {
    return state.status.cardPay === 'routepay-cardinstallment' && state.isShowTokenFrontInstallment
  },
  largeShipExtendsInfo(state) {
    return state.mallShippingMethods?.mall_list?.map(mall => {
      return {
        mall_code: mall.mall_code,
        // extend_type 时效种类：1、普通时效 2、qs时效 3、海陆运
        aging_extends_info: mall.aging_extends?.find(_ => _.extend_type == 3) || {}
      }
    }) || []
  },
  // 缓存中的本地化尺码所在地区
  cacheLocalCountry(state) {
    return state.locals?.USER_LOCAL_SIZE_COUNTRY || 
      (typeof window !== 'undefined' ? localStorage.getItem('last_select_country') : '')
  },
  // 自研服务的定制商品图片域名列表
  customServiceImgDomains(state) {
    return state.locals?.CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS?.CUSTOM_SERVICE || ['ltwebstatic.com', 'shein.com', 'romwe.com']
  },
  // 第三方服务的定制商品图片域名列表
  thirdPartyServiceImgDomains(state) {
    return state.locals?.CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS?.THIRD_PARTY_SERVICE || ['staticmeow.com/', 'customeow.io/', 'maiyuan.online/']
  },
}

export default getters
