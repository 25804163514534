<template>
  <div 
    class="xtra-item"
    :class="{
      'xtra-item_small': entryCouponStyle?.showCouponLimit
    }"
    :style="{
      ...couponConfig[couponType],
      height: entryCouponStyle?.showCouponLimit ? '61px' : '51px',
      '--coupon-circle-bg-color': entryCouponStyle?.couponCircleBgColor
    }"
  >
    <div class="xtra-item__wrapper">
      <div 
        v-if="coupon.isSelected"
        class="xtra-item__selected"
      >
        <sui_icon_selected_12px 
          size="12px" 
          color="#F82854"
        />
      </div>
      <!-- 新人券标签 -->
      <div
        v-if="coupon.newUserLabelText"
        class="xtra-item__gifts"
        :class="{
          'xtra-item__gifts_ar': GB_cssRight
        }"
      >
        <sui_icon_giftbox_12px 
          size="11px"
          color="#fff"
        />
        <div class="xtra-item__gifts-text">
          <div ref="scrollTextRef">
            {{ coupon.newUserLabelText }}
          </div>
        </div>
      </div>
      <!-- 数量角标（新人券不展示） -->
      <div
        v-else-if="(+coupon.couponQuantity > 1) && entryCouponStyle.showCouponNumber"
        class="xtra-item__quantity" 
      >
        X{{ coupon.couponQuantity }}
      </div>
      <div class="xtra-item__content">
        <!-- 券面额（TODO:后续需替换成组件库组件）-->
        <div
          ref="discountRef"
          class="xtra-item__discount"
          :class="{
            'xtra-item__discount_ar': GB_cssRight,
            'xtra-item__discount_shipping': couponType === XtraCouponType.Shipping,
            'xtra-item__discount_ellipsis': ellipsisVisible
          }"
        >
          {{ discount }}
        </div>
        <!-- 券门槛 -->
        <div 
          v-if="entryCouponStyle?.showCouponLimit"
          class="xtra-item__rule"
        >
          {{ rule }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue'

import { sui_icon_giftbox_12px, sui_icon_selected_12px } from '@shein-aidc/icon-vue3'
import { couponConfig, XtraCouponType } from './config.js'
import useXtraCoupon from './hooks/useCoupon.js'

const props = defineProps({
  coupon: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  },
  entryCouponStyle: {
    type: Object,
    default: () => {}
  }
})
const { GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const discountRef = ref(null)
const scrollTextRef = ref(null)
const ellipsisVisible = ref(false)
const { couponType, discount, rule } = useXtraCoupon(props)

const resetDiscountStyle = () => {
  if (!discountRef.value) return
  ellipsisVisible.value = false
}

const updateDiscountStyle = () => {
  if (!discountRef.value) return
  const { clientHeight } = discountRef.value || {}
  if (clientHeight > 34) {
    // 超过两行，才缩小字号
    ellipsisVisible.value = true
  }
}
const updateScrollTextStyle = () => {
  if (!scrollTextRef.value) return
  const { scrollWidth, parentElement } = scrollTextRef.value || {}
  const parentWidth = parentElement.clientWidth
  if (scrollWidth <= parentWidth) {
    scrollTextRef.value.style.animation = 'none'
  } else {
    const time = Math.floor(+scrollWidth * (3 / 100))
    scrollTextRef.value.style.animationDuration = `${time}s`
  }
}

watch(
  () => props.coupon,
  (newVal, oldVal) => {
    if(typeof window == 'undefined' || JSON.stringify(oldVal) === JSON.stringify(newVal)) return
    resetDiscountStyle()
    nextTick(() => {
      updateDiscountStyle()
      updateScrollTextStyle()
    })
  },
  {
    immediate: true,
    deep: true
  }
)
</script>

<style lang="less" scoped>
/* 定义动画 */
@keyframes scrollText {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
    transform: translateX(-100%);
  }
}
// 阿拉伯语言滚动
@keyframes scrollTextAr {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
    transform: translateX(-100%);
  }
}

.xtra-item {
  width: 100px;
  height: 61px;
  position: relative;
  border-radius: 4px;
  padding-top: 5px;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: calc(50% + 2.5px);
    left: -1px;
    width: 12px;
    height: 12px;
    background-color: var(--coupon-circle-bg-color, #fff);
    border: 1px solid var(--borderColor);
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &::after {
    left: auto;
    right: -1px;
    transform: translate(50%, -50%);
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: var(--bgColor);
    border: 1px solid var(--borderColor);
  }

  &__selected {
    position: absolute;
    left: 0;
    top: 5px;
    border-radius: 4px 0 11px;
    background-color: #FDD2DB;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 3px 1px 2px;
  }

  &__gifts {
    max-width: 73px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    line-height: 1.2;
    color: #fff;
    border-radius: 11px 4px 0 11px;
    opacity: 0.9;
    background: linear-gradient(270deg, #8F0CE8 -31.37%, #FF4EAD 34.38%);
    display: flex;
    align-items: center;
    padding: 1px 6px;
  }

  &__gifts-text {
    width: 49px;
    height: 12px;
    margin-left: 2px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    
    > div {
      font-family: "SF Pro";
      font-weight: 700;
      position: absolute;
      top: 0;
      left: 0;
      white-space: nowrap;
      animation: scrollText 5s linear infinite; 
    }
  }

  &__gifts_ar {
    .xtra-item__gifts-text > div {
      animation: scrollTextAr 5s linear infinite;
    }
  }

  &__quantity {
    position: absolute;
    right: 0;
    top: 5px;
    padding: 1px 6px;
    border-radius: 0 4px;
    color: #F82854;
    background-color: #FDD2DB;
    font-family: "SF Pro";
    font-size: 10px;
    font-weight: 510;
    line-height: 1.2;
  }

  &__content {
    max-width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--textColor);
    line-height: 1.2;
  }

  &__discount {
    font-size: 14px;
    font-weight: 800;
    color: var(--discountColor);
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 7px;

    &_ar {
      font-family: SF Pro;
    }

    &_shipping {
      font-size: 12px;
    }
    
    &_ellipsis {
      font-size: 10px;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__rule {
    width: 100%;
    font-family: "SF Pro";
    font-size: 10px;
    font-weight: 400;
    color: var(--ruleColor);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
}

.xtra-item_small {
  height: 51px;

  .xtra-item__discount {
    margin-top: 0;
  }
}
</style>
