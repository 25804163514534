import { createStore } from 'vuex'
import actions from './actions'
import getters from './getters/index'
import mutations from './mutations'


const store = createStore({
  state: {
    locals: {},
    isSSR: false,
    checkoutScene: '', // 当前下单页场景 Checkout：普通订单下单页，CheckoutAgain： 普通二次下单，OrderDetail：普通订单详情，GiftCard：礼品卡下单页，GiftCardAgain：礼品卡二次下单页，OrderList：订单列表页, BuyPrime: 付费会员购买页, Xtra: 超省卡单独购，XtraAgain: 超省卡单独购二次下单，BuyPrimeAgain: 付费会员二次下单
    // 优惠券相关
    couponData: '', // 外部优惠券输入框内容
    coupon: {
      val: '', // 当前券输入的券码
      msg: '', // 券接口错误提示语
      couponLoading: false, // 券加载
      applyCouponsSuccess: [], // 券使用成功
      applyCoupons: [], // 券输入中 结合此次和上次输入内容 不代表全部使用成功
      showCouponList: false, // 展示卷列表
      lastCoupon: '', // 最后使用成功的一张券
      lastAddCoupon: '', // 最后一次手动输入成功的券 就算删掉也需要在券列表展示
      isAutoUse: false, // 是否为自动用券
      autoAnchoredCoupon: '', // 进入券列表时自动锚定的券，目前默认为可用券
      errorCoupon: '', // 错误的券
      errorCouponList: [], // 错误的券列表
    },
    autoCouponInfo: {
      show: false,
      alreadyShowed: false,
    },
    bestCouponInfo: {
      show: false,
      alreadyShowed: false,
      alreadyExposeCouponInfo: false,
    },
    applyType: 0, // 手动触发券来源
    couponsApply: false, // 点击券按钮
    myCouponListInfo: {
      commonCouponList: [],
      bestCouponList: [],
    },
    firstCoupon: {}, // 首单券
    couponGift: '', // 券赠品
    couponGiftsAddItemShow: false, // 券赠品展示
    couponGiftInit: false, // 券赠品初始化
    isShowCouponError: false, // 券输入框 错误提示
    isShowKnivesDialog: false, // 点击place order是否弹出刀具-年龄弹框
    adult: '',  // 刀具年龄 1成年  0未成年
    confirmUseCoupon: false, // 券列表底部按钮
    usableCouponList: [], // 未用券
    disabledCouponList: [], // 已用券
    bestCoupon: null, // 算法最优券
    buyCouponActivity: '', // 买券信息
    buyCouponChecked: false, // 是否勾选买券
    showBuyCouponList: false, // 买券弹窗
    buyCouponTip: false, // 是否勾选买券 & 是否使用买券
    buyCouponApplyType: 0, // 勾选买券 未使用买券提示& 已使用展示优惠金额
    hasAvailableCoupon: false, // 价格底部券输入框 展示我的优惠券
    hasEnterCouponList: false, // 是否进入过券列表
    firstCouponHtml: '', // 价格底部展示 首单优惠券文案
    paymentCouponListTxt: '', // 当前的支付方式不匹配当前的优惠券
    paymentCouponList: [], // 当前券不支持当前支付方式 提示切换支付方式
    showPaymentCoupon: false, // 当前券不支持当前支付方式 切换支付方式弹窗
    nowPaymentCoupon: null, // 当前券不支持当前支付方式 当前选中的优惠券
    confirmUsePaymentCouponShow: false, // 当前券支持当前支付方式 是否要切换
    freeShipTip: {
      coupon: [],
      point: [],
      other: [],
      otherType: '',
    }, // 使用券 积分 导致免邮差额提示
    // 券end
    lastSelectePayment: null, //切换支付时前一次选中的支付
    status: {
      isWalletAmoutHit: true, //是否达到最小钱包可用金额
      cardPay: '', //支付方式
      cartStock: false,
      newOrder: 0,
      paypalUrl: 0,
      shipping: 1,
      checkout: 0,
      codcurrency: false,
      orderUnpaid: false,
      codcurrencyOnce: true,
      pay: {
        yandexShow: false,
        qiwiShow: false,
        qiwiPhoneShow: false,
        'ebanx-oxxo': false, //有金额限制
      },
      error: {
        nopayment: 0,
        coupon: 0,
        points: 0,
        wallet: 0,
        couponCode: 0,
        pointsCode: 0,
        walletCode: 0,
        couponCodeNum: 0,
        maxAvailablePoint: 0,
        maxAvailableWallet: 0,
        qiwiPhone: 0,
        couponMinOrder: '',
        couponMaxOrder: '',
        tips: '',
      },
      loading: {
        coupon: 0,
        points: 0,
        twice: 0, // 避免触发两次
      },
      success: {
        coupon: 0,
        points: 0,
        wallet: 0,
      },
      disabled: {},
      chooseAddress: 1,
      addressCompOpen: false,
      addressV1CompOpen: false,
      supplementAddressCompOpen: false,
      addressAddTips: false,
      tipsText: '',
      editIndex: 0,
      selectIndex: 0,
      hasAddress: 0,
      adrTitleTipsShow: false,
      adrTitleSaTipsContent: '',
      delete: false,
    },
    pointsData: '', // 积分输入框内容
    walletData: '', // 钱包输入框内容
    codCurrencyTip: '', //展示cod转换货币提示
    riskStr: 1,
    checkout: {},
    caculateData: {
      address_id: '',
      mall_params: [],
      payment_id: '',
      coupon: '',
      points: 0,
      use_wallet: 0,
      use_wallet_amount: '', //注意当use_wallet = 1, use_wallet_amount = 0 使用的是全部钱包金额
      comment: '',
      payment_code_unique: '',
      is_buy_coupon: 0, // 是否买券订单
      prime_product_code: '', // 付费会员产品包code
    },
    prevCalcuMallParams: [], // 最近一次成功使用计价的mall_params参数
    defaultShipping: [], //mall当前运输方式
    initializeDefaultShipping: {}, // 初始化记录默认运输方式, 后续不再随运输方式的选择而变化
    mallInsuranceCheck: [],
    mallShippingMethods: {},
    language: {},
    changeShopType: false,
    cartPromotionMap: {},
    freezTipsAndGoods: {
      // 钱包&礼品卡&券使用限制抽屉
      show: false,
      type: '',
      dialogTitle: ''
    },
    hasElseWallet: false, // 是否有其它钱包货币
    walletFreez: {
      showReason: false,
      restTxt: false,
      unpaidTxt: false,
      list: null,
    },
    walletLimit: [], // 限制使用钱包商品
    giftCardLimitGoods: [], // 限制使用礼品卡商品
    pointLimitGoods: [], // 限制使用积分的商品，根据不同的积分比例聚合不同的商品
    couponLimit: {
      totalPriceWithSymbol: '', // 当前适用商品的总金额，带货币符号
      needPriceWithSymbol: '', // 差额，带货币符号
      goods: [], // 适用商品
      applicationTips: '' // 适用范围提示
    },
    primeProductList: [], // 付费会员用户可购买产品信息
    lowPrimeProduct: {}, // 当前付费会员产品包中的价格最低的产品包
    selectdPrimeIndex: null, // 当前选中产品包index
    usedPrimeIndex: -1, // 最终使用的产品包index
    showCartNullDialog: false, // 购物车为空dialog
    nullDialogTip: '', // 购物车为空提示语
    usedPrimeCode: '',
    rightConfigList: [], // 当前选中产品包权益列表
    primeRightCouponList: [], // 当前选中产品包权益列表下的运费权益中的券列表信息
    isAgreePrime: false, // 用户是否同意会员权益
    showPrimeDetail: false,
    primePopFrom: '', // 记录prime弹框触发的地方  1-随单购模块、2-物流运输方式、3-下单页挽留弹窗
    showMarkedPrice: 0, // 展示闪购划线运费价
    coupon_payment_code_unique: '', // 选中过的支付方式
    isShowQuickPop: false,
    isPrimeMember: false, // 是否是付费会员
    showShipSwitch: false, // 是否展示自动使用会员券消息提醒
    is_manual_usedCoupon: 0, // 是否手动使用过券
    canAutoUsePrime: false, // 随单购场景是否可以自动用券
    autoPrimeReadCoupon: [], // 相同自动用券提示后 不在提示
    autoXtraReadCoupon: [], // 相同自动用券提示后 不在提示
    couponListNotice: '', // 券公告
    isShowCouponGoodsDrawer: false,
    goodsListData: [],
    commonDialogInfo: {
      showClose: false,
      show: false,
      tip: '',
      btnTxt: '',
      closeOnClickModal: true,
      cb: () => {}
    },
    qsFreightTips: '',
    isClickedQsFreight: false, // 是否手动点击过qs运费，供缓存使用
    qsFreightSelected: false, // 当前是否选中购买qs运费,优先于用户上次选择结果
    updatingPrice: false, // 计算价格是否在请求中
    integrityPromotionInfo: {}, // 全场活动信息
    promotionList: [],
    mallCacuTime: new Date().getTime(), // 最后一次成功请求计价接口时间
    codConfirm: {
      couponCb: '',
      pointCb: '',
      res: '',
      currBox: false,
      txt: '',
      applyType: '',
      caculate_info_coupon_code: '',
    },
    codCoupon: '', // 使用cod支付方式勾选的券码
    showShippingFeeDrawer: false,
    shippingFeeRuleInfo: {},
    showXtraCoupon: false,
    xtraDiscountInfo: {},
    xtraAllProductBagInfo: {}, // 超省卡模块的所有信息，包括卡包、劵等
    xtraProductInfo: {}, // 当前显示的超省卡信息（用户没有选，默认第一个）
    xtraCouponList: [], // 当前显示超省卡的券信息，比较纯粹的劵信息
    xtraDisplayCouponInfo: {}, // 当前显示超省卡的最优券信息
    xtraProductBagInfo: [], // 超省卡的所有产品包信息
    selectedXtraProductCode: '', // 在多个产品包，选择的超省卡code
    xtraProductCodeInDrawer: '', // 在内页存在多包的时候，选择的code，需要和外页面的usedXtraCode区分开，只有在点击底部按钮的时候才会更新为要用的usedXtraCode
    usedXtraCode: '',
    isCancelXtra: false,
    primeSavingSummary: {},
    showPrimeLimitModal: false,
    showBagTax: false,
    taxCartsInfo: {
      list: [],
      mallCode: ''
    },
    shoppingBagTaxIsLoaded: false,
    showBagTaxDialog: false,
    // 随手购
    togetherBuy: {
      togetherBuyType: 0,
      togetherShoppingNav: 0,
      isMallType: 0,
      currentCartList: [],
      mallData: [],
      quickViewCartData: {}, // 加车后的数据
      addCartProducs: [], //加车后的顺序id
      recommendProducts: [], // 推荐商品
      exposedGoodsId: '', // 已曝光的商品id
      mallLacation: [],
      newProductCard: '',
      detailgoodsCardAbt: ''
    },
    storeData: '', //店配地址
    showShippingLimitGoods: false, // 是否展示下单规则限制前置 - 商品修改弹窗
    shippingLimitGoodsIsLoaded: false,
    shippingLimitInfo: {
      hint: '',
      goodsList: [],
      shippingMethod: {},
      avaliableStoreTransportInfo: {}, // 可切换的可用店配运输方式
      mallCode: '',
      isStoreAddress: false,
      checkUpdateShippingMethod: false,
      orderLimitType: null, // 不涉及商品-0,删除全部商品-1,删除部分商品-2,需修改地址-3,店配地址修改-7
      shippingLimitModalCount: 0,
    },
    showShippingLimitAddress: false, // 是否展示下单规则限制前置 - 地址修改弹窗
    shippingLimitAddressIsLoaded: false, 
    showShippingLimitStore: false, // 是否展示下单规则限制前置 - 店配地址修改弹窗
    shippingLimitStoreIsLoaded: false,
    // 省市区第二语言
    secondLanguage: {
      state: '',
      city: '',
      district: ''
    },
    // 第二语言数据缓存
    secondLanguageCache: {},
    isLoadingSecondLanguage: false,
    shippingTypeDialog: false,
    shippingTypeDialogIsLoaded: false,
    shippingTypeChangeInfo: {
      shippingMethod: {},
      mallCode: '',
      prevShippingMethod: {}
    },
    visibleChooseAddressDialog: false,
    selectStoreAddressShippingInfo: {
      shippingMethod: {},
      mallCode: '',
      isAdd: false,
    },
    // 是否触发强制刷新运输方式且需要带即将选中的运输方式
    forceUpdateShippingMethod: {
      shippingMethod: {},
      mallCode: '',
      isUpdate: false,
    },
    mallCartsList: [],
    showShoppingBagRetain: false,
    showSureDelModel: false,
    shoppingBagRetainIsLoaded: false,
    showRecheckQuickship: false,
    recheckQuickshipIsLoaded: false,
    editCartInfo: {
      item: {}, // 当前修改的商品
      type: '', // 删除商品挽留弹窗（retain）、qs库存不足二次确认（quickship）
      isCancel: false,
      isQsConfirm: false,
      quickshipInfo: {
        type: -1, // 点击的何处qs商品（购物袋内部不拆分qs库存不足或非qs商品-1；购物袋外部拆分 点击qs足够1、 点击qs不足 0）
        qsQuantity: 0,
        noQsQuantity: 0,
      },
      isDelete: false,
      isMoveWhislist: false,
      isLargeShipGoods: false,
      currentValue: 0,
      oldValue: 0,
      delItemInfo: {
        id: '',
        index: -1,
        quick_ship: false
      },
      cartLoading: false, // 是否处于一次购物车请求过程中
      source: '', // 购物袋内外层
    },
    editCartLoading: false, // 当前是否正在修改商品
    virtualCouponInfo: {
      xtraSceneDiscount: 0, // 存储超省卡模块场景
      virtualXtraCoupon: {}, // 超省卡会费券信息
      newXtraProductInfo: {}, // 重组数据
      status: {},

      visibleMyCouponListMadal: false, // 记录是否打开了券列表
      newPrimeProductList: [],  // 重组数据
      currentClickType: '', // 超省卡 xtra 付费会员 prime
      couponVirtualCode: [] // 付费会员包会费券收集
    },
    defaultSelectTokenPay: {}, // 默认选中的 token 支付类型
    isShowTokenPay: false,  // 卡支付是否展示为 token 支付
    buyNowNo: '', // 立即购单号（为空默认走正常下单逻辑）
    buyNowFromUrl: '', // 立即购跳转回商详链接
    shippingContentInfo: {},
    shippingAddStatusInfo: { // 是否完成运输方式凑单信息
      getStatus: false, // 是否成功获取缓存状态
      addStatus: false, // 凑单完成状态
      transportType: '', // 哪种运输方式拉起的当前凑单组件
    },
    cardBinDiscountInfo: {},  // token 卡 bin 优惠信息
    createOrderBin: '', // 生成订单传入的卡 bin
    createOrderBinDialogShow: false, // 生成订单传入的卡 bin失败弹窗
    isNoNeedCardBin: false, // 生成订单不需要 cardBin
    soldOutDialog: {
      show: false,
      loaded: false,
      productLimitTip: '',
      hintType: 0, //商品限制类型hint_type   0：不涉及商品，1：删除全部商品，2：删除部分商品
    },
    specialGoodsDialog: {
      show: false,
      loaded: false,
      productLimitTip: '',
      hintType: 0, //商品限制类型hint_type   0：不涉及商品，1：删除全部商品，2：删除部分商品，4: 南非特殊商品下单限制
    },
    currTips: {
      currBox: false,
      txt: ''
    },
    //boleto邮箱检验
    emailTips: {
      val: '',
      show: false,
      err: '',
    },
    tipModal: {
      show: false,
      text: '',
      showBtn: false
    },
    limitTip: {
      show: false,
      txt: '',
      type: '' // cartLimit、discountLimit、flashLimit
    },
    checkoutPassport: {
      show: false,
      loaded: false,
      default_address: {}
    },
    handlerSelectBin: '', // 手动选中的 卡 bin
    isShowTokenBinDiscountBlock: false, // 是否展示卡bin优惠
    tokenBinDiscountDesc: '', // 卡bin优惠内容
    tokenBinDiscountType: 0, // 卡bin优惠类型
    isShowBinCouponsDialog: false, // 卡 bin 可用券抽屉是否展示
    isShowBinCouponsDialogInNormalPay: false, // 卡 bin 可用券抽屉是否展示, 非token支付时
    tokenBinList: [], // 卡 bin 列表
    lastSelectBin: '', // 上次选中的卡 bin
    isSelectCouponsBinError: false, // 选中卡 bin 是否报错
    showAddressDialog: false,
    isShowInstallmentTokenDrawer: false, // 是否展示分期支付的 token 弹窗
    isShowInstallmentListDialog: false, // 是否展示分期支付的分期列表弹窗
    installmentSelectBin: '', // 分期支付选中的卡 bin
    installmentSelectToken: {}, // 分期支付选中的卡 bin
    isShowTokenFrontInstallment: false, // 是否展示 token 分期支付
    selectInstallment: {}, // 选中的分期
    isShowCvvDialog: false, // 是否展示 cvv 弹窗
    prePayForCheckoutReady: false, // 卡前置支付是否准备好
    isMAESTRO: false, // 是否是 maestro 卡
    isMallOrderCreateFail: false, // 下单失败
    couponBinList: [], // 券支持的卡bin
  },
  mutations: {
    assignState(state, payload) {
      Object.assign(state, payload)
    },
    changeParamsStatus(state, { type, params }) {
      state[type] = { ...state[type], ...params }
    },
    updateCheckoutState(state, payload) {
      const { key, val } = payload
      if (state.hasOwnProperty(key)) {
        state[key] = val
      }
    },
    updateSelectedPrime(state, { index, isUpdateRightList }) {
      state.selectdPrimeIndex = index
      if(isUpdateRightList) {
        let rightConfigList = state.primeProductList[state.selectdPrimeIndex || 0]?.right_config_list || []
        state.rightConfigList = rightConfigList
      }
    },
    changeTogetherBuy(state, payload) {
      const key = 'togetherBuy'
      if (state.hasOwnProperty(key) && payload) {
        state[key] = { 
          ...state[key], 
          ...payload 
        }
      }
    },
    changeVirtualCouponInfo(state, payload){
      Object.keys(payload).forEach(key => {
        state.virtualCouponInfo[key] = payload[key]
      })
    },

    updateDefaultSelectTokenPay(state, defaultSelectTokenPay = {}) {
      state.defaultSelectTokenPay = Object.assign({}, defaultSelectTokenPay)
    },

    updateIsShowTokenPay(state, isShowTokenPay = false) {
      state.isShowTokenPay = isShowTokenPay
    },  
    updateShippingAddStatusInfo(state, payload) {
      Object.keys(payload)?.forEach(key => {
        state.shippingAddStatusInfo[key] = payload[key]
      })
    },
    updateCreateOrderBin(state, createOrderBin = '') {
      state.createOrderBin = createOrderBin
    },
    updateCreateOrderBinDialogShow(state, createOrderBinDialogShow = false) {
      state.createOrderBinDialogShow = createOrderBinDialogShow
    },
    updateIsNoNeedCardBin(state, isNoNeedCardBin = false) {
      state.isNoNeedCardBin = isNoNeedCardBin
    },
    showCouponListAndAnchor(state, couponCode = '') {
      state.coupon.autoAnchoredCoupon = couponCode
      state.coupon.showCouponList = true
    },
    ...mutations
  },
  actions,
  getters
})

export default store
